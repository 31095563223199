/* eslint-disable max-len */
import { useAxios } from '../../hooks';
import { ISignUpResponse } from './sign-up.interface';
import { mapToSignUpRequest } from './sign-up.mapper';
import { ISignUpSchema } from './sign-up.schema';

export const useSignUpCreate = () => {
  const { execute, loading } = useAxios<ISignUpResponse>();

  const _getDeviceId = () => {
    const deviceId =
      localStorage.getItem('deviceId') || Math.random().toString(36);

    localStorage.setItem('deviceId', deviceId);

    return deviceId;
  };

  /**
   * Validate if the email is already registered and send a code to the email
   * @param signUpSchema
   * @returns
   */
  const preCreateNewAccount = (signUpSchema: ISignUpSchema) => {
    const request = mapToSignUpRequest(signUpSchema);

    return execute({
      method: 'POST',
      url: '/signup/pre-create-new-account',
      data: {
        deviceId: _getDeviceId(),
        platform: 'web',
        createNewAccount: request,
      },
    });
  };

  /**
   * Create a new account after the email is validated
   * @param request
   * @returns
   */
  const signUpCreate = async (request: { codeAuth: string; signUpSchema: ISignUpSchema }) => {
    try {
      const response = await execute({
        method: 'POST',
        url: '/signup/create-new-account',
        data: {
          deviceId: _getDeviceId(),
          codeAuth: request.codeAuth,
          createAccount: mapToSignUpRequest(request.signUpSchema),
        },
      });
      
      console.log(response)

      return response;
    } catch (error) {
      console.log('Error al crear la cuenta', error);
    }
  };

  return {
    preCreateNewAccount,
    signUpCreate,
    loading,
  };
};
