import React, { FC } from 'react';
import { Spin, Table, TableColumnsType, Tag, Tooltip } from 'antd';
import {
  ORDER_DISPATCH_STATUS_LABEL,
  ORDER_STATUS_LABEL,
} from '../../../../../../services/order-create/order.params';
import { CALL_STATUS_LABEL } from '../../../../../../services/pre-order/pre-order.params';
import { formatDateUTCByCountry } from '../../../../../../utils/date';
import {
  IOrderDeliveryItemResponse,
} from '../../../../../../services/order-motorized/order-motorized.interface';
import { useOrderStore } from '../../../../../../services/order-list/order.store';
import { DownloadOutlined, SendOutlined } from '@ant-design/icons';
import { useModal } from '../../../../../../contexts/ModalContext';
import DeliveryActionIcons from '../../DeliveryActionIcons/DeliveryActionIcons';
import {
  Document,
  Image,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { useAuthStore } from '../../../../../../services/auth/auth.service';

interface IDeliveryTableLevel3Props {
  record: IOrderDeliveryItemResponse[];
}

const DeliveryTableLevel3: FC<IDeliveryTableLevel3Props> = ({ record }) => {
  const { setOrderDeliveryChecked, setOrderDeliveries } = useOrderStore();
  const { openModal } = useModal();
  const { authUser } = useAuthStore()
  const countryCode = authUser.user.company.countryCode || 'PER'

  const handleOrderDeliveries = (order: IOrderDeliveryItemResponse) => {
    setOrderDeliveries(order);
    openModal('ROUTE_ORDER_DELIVERIES', 'Historial de entregas');
  };

  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: IOrderDeliveryItemResponse[],
    ) => {
      setOrderDeliveryChecked(selectedRows);
    },
  };

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
    },
    row: {
      flexGrow: 1,
      fontSize: 5,
      flexDirection: 'row',
    },
    col1: {
      width: '40%',
    },
    text: {
      width: '60%',
    },
    text1: {
      width: '100%',
    },
    textRigth: {
      textAlign: 'right',
    },
    textRigth1: {
      textAlign: 'right',
      fontSize: 8,
    },
    textMedia: {
      width: '50%',
    },
    section1: {
      borderColor: '#000',
      borderWidth: '0.5px',
      borderRadius: '5px',
      borderStyle: 'solid',
      marginTop: 4,
      marginLeft: 8,
      marginRight: 8,
      padding: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section: {
      marginTop: 8,
      marginLeft: 8,
      marginRight: 8,
      paddingTop: 8,
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section2: {
      marginTop: 2,
      marginLeft: 8,
      marginRight: 8,
      padding: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section3: {
      marginTop: 0,
      marginLeft: 8,
      marginRight: 8,
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    textCenter: {
      fontSize: 10,
      textAlign: 'center',
      fontWeight: 'ultrabold',
    },
    textCenter1: {
      fontSize: 10,
      width: '100%',
      textAlign: 'center',
      fontWeight: 'ultrabold',
    },
  });

  const DocPdf = (order: IOrderDeliveryItemResponse) => {
    return (
      <Document>
        {order ? (
          <>
            (
            <Page size={[162, 162]} key={order.orderNumber}>
              <View style={styles.section}>
                <View style={styles.row}>
                  <Text style={styles.textCenter1}>
                    {order.shipping.provinceName}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.text}>{order.orderNumber}</Text>
                  <View style={styles.col1}>
                    <Text style={styles.textRigth}>
                      {order.transporter?.name}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.section1}>
                <View style={styles.row}>
                  <View style={{ width: '70%' }}>
                    <View style={styles.row}>
                      <Text style={styles.text1}>
                        Almacén: {order.warehouseName}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.textRigth}>
                        #Guía: {order.dispatchGuide?.guideNumber || '-'}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.text}>Producto(s):</Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.text1}>
                        {order.orderDetails?.map((o) => {
                          return `${o.quantity} ${o.sku.product?.name} ${o.sku.skuOptions
                            ?.map((op) => op.option?.name)
                            .join('')
                            .concat('\n')}`;
                        })}
                      </Text>
                    </View>
                  </View>
                  <View style={{ width: '30%' }}>
                    <Image
                      source={'https://aliclikapp-api.herokuapp.com/order-public/generate-qr'.concat(
                        `/${order.orderNumber}`,
                      )}
                    />
                  </View>
                </View>
              </View>
              <View style={styles.section1}>
                <View style={styles.row}>
                  <Text style={styles.text}>
                    Monto a cobrar: {order.currency.symbol}
                    {order.total}
                  </Text>
                  <Text style={styles.textRigth}>
                    Distrito: {order.shipping.districtName || '-'}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.text}>
                    NOTA: {order.note}
                    {'\n'}
                    CLIENTE: {order.customerHistory.name}{' '}
                    {order.customerHistory.lastName}
                  </Text>
                </View>
              </View>
              <View style={styles.section2}>
                <View style={styles.row}>
                  <Text style={styles.textMedia}>
                    Fecha entrega: {formatDateUTCByCountry(countryCode, order.shipping.scheduleDate)}
                  </Text>
                  <Text style={styles.textMedia}>
                    Fecha despacho: {formatDateUTCByCountry(countryCode, order.shipping.dispatchDate)}
                  </Text>
                </View>
              </View>
              <View style={styles.section3}>
                <View style={styles.row}>
                  <Text style={styles.textMedia}>Reprogramado 1:</Text>
                  <Text style={styles.textMedia}>No contesta 1:</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.textMedia}>Reprogramado 2:</Text>
                  <Text style={styles.textMedia}>No contesta 2:</Text>
                </View>
              </View>
            </Page>
            )
          </>
        ) : null}
      </Document>
    );
  };

  const columnsLevel3: TableColumnsType<IOrderDeliveryItemResponse> = [
    {
      title: 'Order',
      dataIndex: 'id',
      width: 100,
      align: 'center',
      render: (_, order) => order.orderNumber,
    },
    {
      title: 'Ubicación',
      dataIndex: 'comunication',
      width: 50,
      render: (_, order) => <DeliveryActionIcons order={order} />,
    },
    {
      title: 'Entregas',
      dataIndex: 'delivery',
      width: 80,
      align: 'center',
      render: (_, order) => (
        <>
          {order.orderDeliveries && order.orderDeliveries.length > 0 ? (
            <>
              <a onClick={() => handleOrderDeliveries(order)}>
                <SendOutlined
                  style={{
                    fontSize: '1.1rem',
                    margin: '0 .25rem',
                    cursor: 'pointer',
                  }}
                />
              </a>
            </>
          ) : (
            'No asignado'
          )}{' '}
          <>
            <Tag>{ORDER_DISPATCH_STATUS_LABEL[order.dispatchStatus].label}</Tag>
            <>
              <PDFDownloadLink
                document={DocPdf(order)}
                fileName={`${order.orderNumber}.pdf`}
              >
                {({ blob, url, loading, error }) => (
                  <Tooltip placement="bottom" title="pdf">
                    <DownloadOutlined
                      style={{
                        fontSize: '1.1rem',
                        margin: '0 .25rem',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}
              </PDFDownloadLink>
            </>
          </>
        </>
      ),
    },
    {
      title: 'Trazabilidad',
      width: 160,
      render: (
        _,
        {
          callStatus,
          isOrderAgency,
          warehouseName,
          status,
          rescheduledNumber,
          transporter,
        },
      ) => (
        <>
          <Tag color={CALL_STATUS_LABEL[callStatus]?.color}>
            {CALL_STATUS_LABEL[callStatus]?.label}
          </Tag>
          <Tag color={isOrderAgency ? '#FFB74D' : '#00796B'}>
            {isOrderAgency ? 'Agencia' : 'Contraentrega'}
          </Tag>
          <Tag color="#673AB7" hidden={warehouseName ? false : true}>
            {warehouseName}
          </Tag>
          <Tag color="#0097A7">{transporter?.name.toUpperCase()}</Tag>
          <Tag color={ORDER_STATUS_LABEL[status]?.color}>
            {ORDER_STATUS_LABEL[status]?.label}
          </Tag>
          <Tag color="#FB8C00" hidden={rescheduledNumber === 0}>
            {`#Visitas: ${rescheduledNumber}`}
          </Tag>
        </>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: 100,
      align: 'right',
      render: (_, { currency, total, totalPayment }) => (
        <>
          <b>
            {currency?.symbol} {total}
          </b>
          <br />
          {currency?.symbol} {totalPayment}
        </>
      ),
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: 'Products',
      dataIndex: 'orderDetails',
      width: 240,
      render: (_, { orderDetails }) => (
        <>
          {orderDetails?.map((order) => (
            <div key={order.id}>
              {order.quantity} {order.sku.product?.name}
              {order.sku.skuOptions
                ?.filter((e) => e.option?.name !== '_DEFAULT')
                .map((op) => (
                  <span key={op.id}>
                    <span> / </span>
                    {op.option?.name}
                  </span>
                ))}
            </div>
          ))}
        </>
      ),
    },
    {
      title: 'Nota',
      dataIndex: 'note',
      width: 140,
    },
    {
      title: 'Cliente',
      dataIndex: 'phone',
      width: 120,
      align: 'center',
      render: (_, { customerHistory }) => (
        <>
          {customerHistory?.name} {customerHistory?.lastName}
        </>
      ),
    },
    {
      title: 'Dirección',
      dataIndex: 'district',
      width: 150,
      align: 'center',
      render: (_, { shipping }) => (
        <>
          {shipping?.districtName}
          <br />
          {shipping?.provinceName}
          <br />
          {shipping?.departmentName}
        </>
      ),
    },
    {
      title: 'F. entrega',
      dataIndex: 'scheduleDate',
      align: 'center',
      width: 100,
      render: (_, { shipping, assignedMotorized }) => (
        <>
          {formatDateUTCByCountry(countryCode, shipping?.scheduleDate)}
          <br />
          {assignedMotorized ? assignedMotorized : '-'}
        </>
      ),
    },
    {
      title: 'Vendedor',
      dataIndex: 'vendor',
      align: 'center',
      width: 100,
      render: (_, { user }) => <>{user.fullname}</>,
    },
  ];

  return (
    <>
      <Spin spinning={false}>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          rowKey="id"
          columns={columnsLevel3}
          dataSource={record}
          pagination={false}
          scroll={{
            x: 2000,
          }}
        />
      </Spin>
    </>
  );
};

export default DeliveryTableLevel3;
