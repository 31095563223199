import React, { useEffect } from 'react';
import { useFrequentQuestions } from '../../../../services/frequent-question-list/frequent-question.service';
import { CollapseModules } from '../../components';
import { Empty, Spin, Typography } from 'antd';
import { useAuthStore } from '../../../../services/auth/auth.service';
import { getQuestionFiltered, questionGroupByModule } from '../../utils';

const FrequentQuestionContainer = () => {
  const { authUser } = useAuthStore();
  const { getQuestions, loading, questions } = useFrequentQuestions();

  const questionsMapAndGrouped = () => {
    const rolName = authUser ? authUser.user.role?.name : '';
    const questionFiltered = getQuestionFiltered(questions, rolName);

    return questionGroupByModule(questionFiltered);
  };

  useEffect(() => {
    getQuestions({
      countryCode: authUser ? authUser.user.company.countryCode : 'PER',
      isActive: true,
    });
  }, []);

  if (loading) {
    return <Spin style={{ display: 'block', textAlign: 'center' }} />;
  }

  return (
    <div
      style={{
        maxWidth: '680px',
        margin: '0 auto',
        padding: '0 12px',
      }}
    >
      <div style={{ textAlign: 'center', paddingBottom: 24 }}>
        <Typography.Title level={3} style={{ color: '#2A26FF' }}>
          Preguntas Frecuentes
        </Typography.Title>
        <Typography.Title level={5} style={{ fontWeight: 500 }}>
          Guía completa de Aliclik: Todo lo que necesitas saber y vender más.
        </Typography.Title>
      </div>

      {questionsMapAndGrouped()?.length === 0 && (
        <Empty description="No hay preguntas frecuentes disponibles en este momento." />
      )}

      <div>
        <CollapseModules data={questionsMapAndGrouped()} />
      </div>
    </div>
  );
};

export default FrequentQuestionContainer;
