import React from 'react';
import {
  Header,
  HeroSection,
  Features,
  Benefits,
  Testimonials,
  Questions,
  Footer,
} from '../components';

export const NewLandingView = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <Features />
      <Benefits />
      <Testimonials />
      <Questions />
      <Footer />
    </div>
  );
};
