import React from 'react';
import './Features.css';
import {
  ClikedIcon,
  LogisticsIcon,
  PresentationChartIcon,
} from '../../../../components/Icons';
import useScrollAnimation from '../../hook/useScrollAnimation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState, useEffect } from 'react';

import 'swiper/css';
import 'swiper/css/effect-cards';
import { EffectCards } from 'swiper/modules';

export const Features = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { ref, isVisible } = useScrollAnimation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const featureItems = [
    {
      icon: <ClikedIcon fill="#003a3a" />,
      title: 'A un click',
      text: 'Accede a tu información desde cualquier computadora, tablet o celular y gestiona tus pedidos fácilmente.',
    },
    {
      icon: <LogisticsIcon fill="#003a3a" />,
      title: 'Logística sin complicaciones',
      text: 'Contraentrega en más de 25 ciudades del Perú con una efectividad mayor a 75%',
    },
    {
      icon: <PresentationChartIcon fill="#003a3a" />,
      title: 'Productos y Comunidad',
      text: 'Conecta con importadores, fabricantes para vender sin stock y capacitación continua para crecer juntos.',
    }
  ];

  return (
    <div className="features" ref={ref}>
      <div className={`features__opacy ${isVisible ? 'appear' : ''}`}>
        <h2 className={`title__landing ${isVisible ? 'appear' : ''}`}>
          Aliclik, tu ecommerce a un clik
        </h2>
        <p className={`subtitle__landing features__description ${isVisible ? 'appear' : ''}`}>
          Aliclik es el ecosistema digital diseñado para potenciar tu negocio
          online. Desde dropshippers hasta marcas consolidadas, te ofrecemos
          soluciones tecnológicas y logísticas que maximizan tu rentabilidad.
        </p>
      </div>
      {isMobile ? (
        <Swiper
          effect={'cards'}
          grabCursor={true}
          modules={[EffectCards]}
          autoHeight={false}
          className={`mySwiper features__list ${isVisible ? 'appear' : ''}`}
          
        >
          {featureItems.map((feature, index) => (
            <SwiperSlide key={index} className="features__slide">
              <div className={`features__wrapper ${isVisible ? 'appear' : ''}`}>
                <div className="features__icon">{feature.icon}</div>
                <h3 className="features__subtitle">{feature.title}</h3>
                <p className="features__paragraph">{feature.text}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className={`features__list ${isVisible ? 'appear' : ''}`}>
          {featureItems.map((feature, index) => (
            <div className={`features__wrapper ${isVisible ? 'appear' : ''}`} key={index}>
              <div className="features__icon">{feature.icon}</div>
              <h3 className="features__subtitle">{feature.title}</h3>
              <p className="features__paragraph">{feature.text}</p>
            </div>
          ))}
        </div>
      )}

    </div>
  );
};
