import { IWarehouseCreateSchema } from './warehouse-create.schema'
import { IWarehouseCreateRequest } from './warehouse-create.interface'
import { IAuthResponse } from '../auth/auth.interface'
import { ICompanyItemResponse } from '../company-list/company-list.interface'
import { BANK_LIST } from '../../config/data.params'
import { formatInTimeZone } from 'date-fns-tz'

const mapToWarehouseCreateRequest = (
  warehouse: IWarehouseCreateSchema,
  authUser: IAuthResponse,
  companyGlobal: ICompanyItemResponse | null,
): IWarehouseCreateRequest => {

  const bank = BANK_LIST.find((b) => b.code === warehouse.codeBank)

  return {
    name: warehouse.name,
    companyId: companyGlobal?.id || authUser.user.company.id,
    address: warehouse.address || '',
    reference: warehouse.reference || '',
    lat: warehouse.gps?.split(',')[0] || '',
    lng: warehouse.gps?.split(',')[1] || '',
    countryName: authUser.user.company.countryCode === 'MEX' ? 'México' : 'Perú',
    countryCode: authUser.user.company.countryCode || '',
    departmentName: warehouse.departmentName || null,
    departmentCode: warehouse.department ? `${warehouse.department}` : null,
    provinceName: warehouse.provinceName || null,
    provinceCode: warehouse.province ? `${warehouse.province}` : null,
    districtName: warehouse.districtName || null,
    districtCode: warehouse.district ? `${warehouse.district}` : null,
    phone: `${warehouse.phone}`,
    typeWarehouse: warehouse.typeWarehouse,
    userId: warehouse.userId,
    codeBank: warehouse.codeBank,
    accountNumber: warehouse.accountNumber,
    cci: warehouse.cci,
    holderName: warehouse.holderName,
    emailTransfer: warehouse.emailTransfer,
    documentHolderAccount: warehouse.documentHolderAccount,
    businessName: warehouse.businessName,
    businessDocument: warehouse.businessDocument,
    nameBank: bank?.value,
    deadlineTimeDelivery: warehouse.deadlineTimeDelivery,
    deadLineTimeSecundary: warehouse.deadLineTimeSecundary,
    deadlineTimeAgency: warehouse.deadlineTimeAgency,
    formatTimeAgency: warehouse.deadlineTimeAgency ? 
      formatInTimeZone(new Date(warehouse.deadlineTimeAgency.toLocaleString()), 'America/Lima', 'HH:mm') : '',
    formatTimeDelivery: warehouse.deadlineTimeDelivery ? 
      formatInTimeZone(new Date(warehouse.deadlineTimeDelivery.toLocaleString()), 'America/Lima', 'HH:mm') : '',
    formatTimeSecundary: warehouse.deadLineTimeSecundary ? 
      formatInTimeZone(new Date(warehouse.deadLineTimeSecundary.toLocaleString()), 'America/Lima', 'HH:mm') : '',
    shalomOriginIn: warehouse.shalomOriginIn || '',
    isWarehousePublic: warehouse.isWarPublic,
    transporterIds: warehouse.transporterIds,
  }
}

export {
  mapToWarehouseCreateRequest,
}
