/* eslint-disable max-len */
import React, { useState } from 'react';
import './Benefits.css';
import { ModalVideo } from '../ModalVideo';
import alicar from '../../../../assets/images/ali-car.png';
import gestionar from '../../../../assets/images/gestionar.png';
import payment from '../../../../assets/images/ali-money.png';
import useScrollAnimation from '../../hook/useScrollAnimation';

export const Benefits = () => {
  const { ref, isVisible } = useScrollAnimation();
  const [openModal, setOpenModal] = useState(false);
  const [linkVideo, setLinkVideo] = useState('');
  const handleOpenModal = (link: string) => {
    setLinkVideo(link);
    setTimeout(() => {
      setOpenModal(true);
    }, 0);
  };

  return (
    <div className="benefits" id="benefits" ref={ref}>
      <div className={`benefits__wrapper ${isVisible ? 'appear' : ''}`}>
        <h2 className={`title__landing ${isVisible ? 'appear' : ''}`}>Tu Ecommerce en 3 simples pasos</h2>
        <div>
          <div className="benefits__section">
            <div className="col-lg-6">
              <h3 className="benefits__subtitle">
                Regístrate y accede a cientos de productos
              </h3>
              <p className="benefits__paragraph">
                Crea tu cuenta GRATIS en 3 minutos y explora nuestro catálogo con miles de productos listos para vender sin comprar stock
              </p>
            </div>
            <div className="col-lg-6 benefits__image">
              <img
                src={alicar}
                alt="control inventario"
                height={300}
                loading="lazy"
                className="images__landing"
              />
            </div>
          </div>
          <div className="benefits__section">
            <div className="col-lg-6">
              <h3 className="benefits__subtitle">
                Gestiona tus pedidos facilmente
              </h3>
              <p className="benefits__paragraph">
                Sube tus pedidos en unos clics o importalos en tiempo real desde Shopify. Podrás gestionar estados de envio facilmente para un seguimiento eficiente
              </p>
            </div>
            <div className="col-lg-6 benefits__image">
              <img
                src={gestionar}
                alt="control inventario"
                height={300}
                loading="lazy"
                className="images__landing"
              />
            </div>
          </div>
          <div className="benefits__section">
            <div className="col-lg-6">
              <h3 className="benefits__subtitle">
                Vende más y crece sin preocupaciones
              </h3>
              <p className="benefits__paragraph">
                Aliclik se encarga de la logística y el recaudo, olvidate de procesos complicados y optimiza tu negocio escalando con nuestra plataforma
              </p>
            </div>
            <div className="col-lg-6 benefits__image">
              <img
                src={payment}
                alt="control inventario"
                height={300}
                loading="lazy"
                className="images__landing"
              />
            </div>
          </div>
        </div>
        <div className="benefits__wrapper-list">
          <div className="benefits__item">
            <h5 className="benefits__item-title">
              Aliclik para Dropshippers
            </h5>
            <p className="benefits__item-paragraph">
              Accede a miles de productos y envíalos a todo el Perú sin manejar stock propio.
            </p>

            <button
              className="testimonials__box-link-video"
              onClick={() => {
                handleOpenModal(
                  'https://www.youtube.com/embed/Yh2ri72VOwg?si=A6y5_hm-NIGe1hf',
                );
              }}
            >
              Más Información
            </button>
          </div>
          <div className="benefits__item">
            <h5 className="benefits__item-title">
              Aliclik para Proveedores
            </h5>
            <p className="benefits__item-paragraph">
              Vende más y llega a miles de emprendedores sin preocuparte por la logística.
            </p>
            <button
              className="testimonials__box-link-video"
              onClick={() => {
                handleOpenModal(
                  'https://www.youtube.com/embed/2mcCN_dB94A?si=A6y5_hm-NIGe1hf',
                );
              }}
            >
              Más Información
            </button>
            {/* <button className="benefits__item-button">Más Info</button> */}
          </div>
          <div className="benefits__item">
            <h5 className="benefits__item-title">
              Aliclik para Marca propia
            </h5>
            <p className="benefits__item-paragraph">
              Optimiza tu logística y amplia tu cobertura en todo el país entregando tus propios productos
            </p>
            <button
              className="testimonials__box-link-video"
              onClick={() => {
                handleOpenModal(
                  'https://www.youtube.com/embed/wPonlj9J4E4?si=A6y5_hm-NIGe1hf',
                );
              }}
            >
              Más Información
            </button>
            {/* <button className="benefits__item-button">Más Info</button> */}
          </div>
        </div>
      </div>
      {openModal && (
        <ModalVideo link={linkVideo} onClose={() => setOpenModal(false)} />
      )}
    </div>
  );
};
