import React from 'react';
import { ConfigOrderContainer } from './containers';

export const SettingsView = () => {
  return (
    <>
      <ConfigOrderContainer />
    </>
  );
};
