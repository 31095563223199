import React, { FC, useEffect } from 'react';
import { Form, Input, Select, Spin } from 'antd';
import { useCountryPhone } from '../../../services/country-phone/country-phone.service';
import { FormInstance } from 'antd/es/form/Form';

type TCountryPhoneFormItemProps = {
  form: FormInstance;
};

export const CountryPhoneFormItem: FC<TCountryPhoneFormItemProps> = ({
  form,
}) => {
  const { countryPhoneList, loading } = useCountryPhone();

  const selectCountryPhones = (
    <Form.Item name={['countryPhoneId']} noStyle>
      <Select
        style={{ width: 100 }}
        options={countryPhoneList?.map((c) => ({
          label: (
            <span>
              <img
                width={18}
                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${c.code}.svg`}
                alt={c.code}
              />{' '}
              +<span>{c.prefixPhone}</span>
            </span>
          ),
          value: c.id,
        }))}
        onSelect={(value: number) => {
          const countryPhone = countryPhoneList?.find((c) => c.id === value);

          form.setFieldValue(['prefixPhone'], countryPhone?.prefixPhone);
        }}
      />
    </Form.Item>
  );

  /**
   * Set default country phone
   */
  useEffect(() => {
    if (countryPhoneList?.length) {
      const countryPhoneIdValue = form.getFieldValue('countryPhoneId');

      if (!countryPhoneIdValue) {
        const countryPhone = countryPhoneList.find((c) => c.code === 'PE');
        const countryPhoneId = countryPhone?.id || countryPhoneList[0].id;

        form.setFieldsValue({ countryPhoneId });
      }
    }
  }, [countryPhoneList]);

  return (
    <Spin spinning={loading}>
      <Form.Item
        
        label={<span style={{ fontSize: '13px' }}>Teléfono</span>}
        name={['phone']}
        hasFeedback
        
        rules={[
          { required: true, message: 'Obligatorio' },
          {
            pattern: /^[0-9]*$/,
            message: 'Solo se permiten números',
          },
          {
            validator: async (_, value) => {
              if (value) {
                const countryPhoneId = form.getFieldValue('countryPhoneId');

                if (!countryPhoneId) {
                  return Promise.reject('Seleccione un país');
                }

                const maxLengthRegex = countryPhoneList?.find(
                  (c) => c.id === countryPhoneId,
                )?.length;

                if (!maxLengthRegex) {
                  if (!/^\d{8,11}$/.test(value)) {
                    return Promise.reject('Debe tener entre 8 y 11 dígitos');
                  }
                }

                if (maxLengthRegex) {
                  if (!new RegExp(`^\\d{${maxLengthRegex}}$`).test(value)) {
                    return Promise.reject(
                      `Debe tener ${maxLengthRegex} dígitos`,
                    );
                  }
                }
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <Input
          addonBefore={selectCountryPhones}        
        />
      </Form.Item>
    </Spin>
  );
};
