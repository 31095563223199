import React, { FC, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Space,
  Switch,
  Typography,
  Select,
  List,
  Row,
  Col,
  Card
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  IProductCreateSchema,
  ISkuOptionCreateSchema,
} from '../../../../../services/product-create/product-create.schema';
import { useWarehouseList } from '../../../../../services/warehouse-list/warehouse-list.service';
import { useProductStore } from '../../../../../services/product/product.store';
import Title from 'antd/lib/typography/Title';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { generateCombination } from '../../../../../utils/generate-combination';
import { makeSku } from '../../../../../utils/make-sku';
import { IWarehouseResponse } from '../../../../../services/warehouse-list/warehouse-list.interface';
import Barcode from 'react-barcode';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { useCategories } from '../../../../../services/category/category.service';
import { ICategory } from '../../../../../services/category/category.interface';
import { useCatalogsByCompany } from '../../../../../services/catalog/catalog.service';
import { ICatalogStore } from '../../../../../services/catalog/catalog.interface';
import { uuid } from '../../../../../utils';

const { Text } = Typography;
const { Option } = Select;

interface IProductEditFormProps {
  onSubmit: (data: IProductCreateSchema) => void;
  loadingCreated: boolean
}

const ProductEditForm: FC<IProductEditFormProps> = ({ onSubmit, loadingCreated }) => {
  const [form] = Form.useForm<IProductCreateSchema>();
  const { productSelected } = useProductStore();
  //alamcen
  const { getWarehouses, loading, warehouses } = useWarehouseList();
  const { categories, getCategories, loadingCategory } = useCategories();
  const variants = Form.useWatch('variants', form);
  const isProductGlobal = Form.useWatch('isProductGlobal', form);
  const name = Form.useWatch('name', form);
  const { authUser } = useAuthStore();
  //catalogo de la empresa
  const { getCatalogsByCompany, loadingCatalogsStore, catalogs } = useCatalogsByCompany();

  //muestra de campos para el producto global
  const fieldNames = [
    { name: ['retailPriceSuggested'], label: 'Precio venta sugerido' },
    { name: ['priceDropDozen'], label: 'Precio drop docena' },
    { name: ['priceDropCrate'], label: 'Precio drop cajón' },
    { name: ['unitsCrate'], label: 'Unidades por cajón' }
  ];

  //para la carga de datos iniciales
  useEffect(() => {
    getWarehouses('-1');
    getCategories();
    getCatalogsByCompany();
    if (productSelected) {
      form.setFieldsValue({
        id: productSelected.id,
        name: productSelected.name,
        shortDescription: productSelected.shortDescription,
        largeDescription: productSelected.largeDescription,
        urlImage: productSelected.urlImage,
        catalogId: productSelected.catalogId,
        urlReference: productSelected.urlReference,
        retailPriceSuggested: productSelected.retailPriceSuggested,
        priceDropCrate: productSelected.priceDropCrate,
        priceDropDozen: productSelected.priceDropDozen,
        unitsCrate: productSelected.unitsCrate,
        isProductGlobal: productSelected.isProductGlobal,
        isNovelty: productSelected.isNovelty,
        isLargeVolume: productSelected.isLargeVolume,
        salePriceDrop: productSelected.salePriceDrop,
        isActive: true,
        categoryId: productSelected.categoryId,
      });
    }
  }, [productSelected]);

  useEffect(() => {
    if (!productSelected && catalogs && catalogs.length > 0) {
      form.setFieldsValue({
        catalogId: catalogs[0].id,
      })
    }
  }, [catalogs])

  //generacion de sku
  useEffect(() => {
    if (variants?.length) {
      const variantsMap = variants
        .filter((e) => e)
        .filter((e) => e.options?.length)
        .map((variant) => ({
          variantId: uuid(),
          name: variant?.variant,
          options: variant?.options
            ?.match(/[^,]+(?: y [^,]+)?/g)
            ?.map((e: string) => e.trim())
            ?.map((e: string) => ({
              optionId: uuid(),
              name: e,
            })),
        }));

        const groups = variantsMap?.map((e) =>
        e.options?.map(
          (opt: ISkuOptionCreateSchema) => `${opt.name}|${opt.optionId}`,
        ),
      );

      const combinations = generateCombination(groups as string[][]);

      const skuAuto = makeSku(3);

      const skus = combinations.map((c, index) => ({
        check: true,
        sku: `${skuAuto}${index + 1}`,
        purchasePrice: 1,
        salePriceDrop : 0,
        regularPrice: 1,
        stock: 1,
        heightProd: 0,
        widthProd: 0,
        lengthProd: 0,
        skuOptions: c.split(' ')
          .map((e) => {
            const optionId = e.split('|')[1];
            //evitar guardar objetos vacios

            return optionId ? { optionId } : null;
          })
          .filter((e) => e !== null) as { optionId: string }[],

        skuOptionsLabel: c.split(' ')
          .map((e) => e.trim().split('|')[0])
          .join(' - '),
      }));

      form.setFieldsValue({
        skus,
        variantsMap,
      });
      // console.log(skus);
    }
  }, [variants]);
  
  //
  useEffect(() => {
    if (name) {
      const skus = [
        {
          check: true,
          sku: `${makeSku(3)}1`,
          purchasePrice: 1,
          regularPrice: 1,
          skuOptions: [],
          skuOptionsLabel: name,
          
          stock: 1,
          heightProd: 0,
          widthProd: 0,
          lengthProd: 0,
        },
      ];

      form.setFieldsValue({ skus, variantsMap: [] });
    }
  }, [name]);

  //si hay almacenes disponibles 
  useEffect(() => {
    if (warehouses && warehouses.length > 0) {
      form.setFieldsValue({
        warehouseId: authUser.user.warehouse?.id,
      });
    }
  }, [warehouses]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateURL = (_: any, value: any) => {
    if (!value) {
      return Promise.reject(new Error('Por favor ingresa una URL'));
    }
    // Expresión regular para validar la URL
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    if (!urlRegex.test(value)) {
      return Promise.reject(new Error('Por favor ingresa una URL válida'));
    }

    return Promise.resolve();
  };

  const handleSubmit = (data: IProductCreateSchema) => {
    // console.log('Datos', data);
    onSubmit(data);
  };

  return (
    <>
      <Form
        layout="horizontal"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 17 }}
        form={form}
        name="UserForm"
        labelWrap
        onFinish={handleSubmit}
      >
        <Form.Item name={['id']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['variantsMap']} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label="Producto global"
          name={['isProductGlobal']}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label="Nombre producto"
          name={['name']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Input />
        </Form.Item>

        {/* En caso el producto sea global */}
        {isProductGlobal
          ?
            <Form.Item 
              label="Descripción" 
              name={['shortDescription']}
              rules={[{ required: true, message: 'Obligatorio' }]}
            >
            <Input maxLength={100} />
          </Form.Item>
         :
            <Form.Item 
              label="Descripción" 
              name={['shortDescription']}
            >
            <Input maxLength={100} />
          </Form.Item>
        }

        {isProductGlobal
          ?
            <Form.Item 
              label="Detalle" 
              name={['largeDescription']}
              rules={[{ required: true, message: 'Obligatorio' }]}
            >
              <TextArea />
            </Form.Item>
          :
            <Form.Item label="Detalle" name={['largeDescription']}>
              <TextArea />
            </Form.Item>
        }

        <Form.Item
          label="Categoría"
          name={['categoryId']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Select
            showSearch
            placeholder="Seleccione una opción"
            loading={loadingCategory}
            filterOption={(input, option) =>
              `${option?.label}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {categories?.map((r: ICategory) => (
              <Option key={r.id} value={r.id} label={r.name}>
                {r.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Catálogo"
          name={['catalogId']}
          rules={[{ required: false, message: 'Obligatorio' }]}
        >
          <Select
            showSearch
            placeholder="Seleccione una opción"
            loading={loadingCatalogsStore}
            filterOption={(input, option) =>
              `${option?.label}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {catalogs?.map((r: ICatalogStore) => (
              <Option key={r.id} value={r.id} label={r.name}>
                {r.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Link imagen"
          name={['urlImage']}
          rules={[{ required: true, validator: validateURL }]}
        >
          <Input placeholder="Ingresa una URL" />
        </Form.Item>
        
        {/* si es global se vuelve un campo requerido */}
        {isProductGlobal
          ?
            <Form.Item 
              label="Link de drive" 
              name={['urlReference']}
              rules={[{ required: true, message: 'Obligatorio' }]}
            >
              <Input />
            </Form.Item>
          :
            <Form.Item label="Link de drive" name={['urlReference']}>
              <Input />
            </Form.Item>
        }

        {!productSelected ? (
          authUser.user.warehouse ? (
            <>
              <Form.Item
                label="Almacén"
                name={['warehouseId']}
                rules={[{ required: true, message: 'Obligatorio' }]}
              >
                <Select
                  placeholder="Seleccione una opción"
                  loading={loading}
                  disabled
                >
                  {warehouses?.map((r: IWarehouseResponse) => (
                    <Option key={r.id} value={r.id} label={r.name}>
                      {r.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                label="Almacén"
                name={['warehouseId']}
                rules={[{ required: true, message: 'Obligatorio' }]}
              >
                <Select
                  showSearch
                  placeholder="Seleccione una opción"
                  loading={loading}
                  filterOption={(input, option) =>
                    `${option?.label}`
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {warehouses?.map((r: IWarehouseResponse) => (
                    <Option key={r.id} value={r.id} label={r.name}>
                      {r.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )
        ) : null}

        {authUser.user.role.name === 'ADMIN_STORE' ||
        authUser.user.role.name === 'SELLER' ? (
          <>
            <Form.Item
              label="Producto Volumen grande"
              name={['isLargeVolume']}
              hidden
            >
              <Input />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              label="Producto Volumen grande"
              name={['isLargeVolume']}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </>
        )}

        <Form.Item
          label="Nuevo producto"
          name={['isNovelty']}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        
        {/* En caso esté seleccionado como global aparecen estos campos */}
        {fieldNames.map(field => (
          <Form.Item
            key={field.name.toString()}
            label={field.label}
            name={field.name}
            style={{ display: isProductGlobal ? '' : 'none' }}
          >
            <Input />
          </Form.Item>
        ))}

        {/* Si hay un producti seleccionado, muestra estado activo */}
        {productSelected ? (
          <Form.Item label="Activo" name={['isActive']} valuePropName="checked">
            <Switch />
          </Form.Item>
        ) : null}

        <Divider />

        {!productSelected ? (
          <>
            <Title level={4}>Variantes</Title>

            <Form.List name="variants">
              {(fields, { add, remove }) => (
                <>
                  <Text
                    type="secondary"
                    style={{ display: 'block', marginBottom: '.65rem' }}
                  >
                    Ingresar opciones separado por comas
                  </Text>

                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key}>
                      <Form.Item
                        {...restField}
                        name={[name, 'variant']}
                        rules={[{ required: true, message: '' }]}
                        className="fullWidth"
                      >
                        <Input placeholder="Color" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'options']}
                        rules={[{ required: true, message: '' }]}
                        className="fullWidth"
                      >
                        <Input placeholder="Opciones" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Title level={4}>SKUs</Title>

            <Form.List name="skus">
              {(fields) => (
                <>
                  <Text
                    type="secondary"
                    style={{ display: 'block', marginBottom: '.65rem' }}
                  >
                    Sólo los SKUs marcados con check se guardan
                  </Text>

                  {fields.map(({ key, name, ...restField }) => (
                    <Card key={key} bordered={true} style={{ marginBottom: 15, borderRadius: 10 }}>
                      <Row gutter={[16, 8]}>
                        {/* Columna Izquierda */}
                        <Col span={12}>
                          <Form.Item label={<strong >Variante </strong>} name={[name, 'skuOptionsLabel']} >
                            <Input 
                            
                              readOnly 
                              style={{ 
                                backgroundColor: '#f5f5f5', 
                                borderRadius: 5, 
                                width: '100%' 
                              }} 
                              />
                          </Form.Item>
            
                          <Form.Item label={<strong style={{ fontSize: '14px' }} >SKU </strong>} name={[name, 'sku']}>
                            <Input style={{ borderRadius: 5, width: '100%' }} />
                          </Form.Item>
            
                          <Form.Item label={<strong style={{ fontSize: '11px' }} >P. Compra </strong>} name={[name, 'purchasePrice']}>
                            <InputNumber min={0} style={{ width: '100%', borderRadius: 5 }} />
                          </Form.Item>
            
                          <Form.Item label={<strong>P. Venta </strong>} name={[name, 'regularPrice']}>
                            <InputNumber min={0} style={{ width: '100%', borderRadius: 5 }} />
                          </Form.Item>
                          <Form.Item
                            label={<strong>P. Drop</strong>}
                            name={[name, 'salePriceDrop']}
                            rules={[
                              { required: true, message: 'Obligatorio' },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (value > 0) {
                                    return Promise.resolve();
                                  }
                                  
                                  return Promise.reject(new Error('Debe ser mayor a 0'));
                                },
                              }),
                            ]}
                          >
                            <InputNumber min={0.01} />
                          </Form.Item>
                        </Col>
            
                        {/* Columna Derecha */}
                        <Col span={12}>
                          <Form.Item label={<strong>Stock </strong>} name={[name, 'stock']}>
                            <InputNumber min={0} style={{ width: '100%', borderRadius: 5 }} />
                          </Form.Item>
            
                          <Form.Item label={<strong>Alto cm</strong>} name={[name, 'heightProd']}>
                            <InputNumber min={0} style={{ width: '100%', borderRadius: 5 }} />
                          </Form.Item>
            
                          <Form.Item label={<strong>Ancho cm</strong>} name={[name, 'widthProd']}>
                            <InputNumber min={0} style={{ width: '100%', borderRadius: 5 }} />
                          </Form.Item>
            
                          <Form.Item label={<strong>Largo cm</strong>} name={[name, 'lengthProd']}>
                            <InputNumber min={0} style={{ width: '100%', borderRadius: 5 }} placeholder='En cm' />
                          </Form.Item>
                        </Col>
                      </Row>
          
                      <Divider style={{ margin: '10px 0' }} />
            
                      <Row justify="center">
                        <Form.Item {...restField} valuePropName="checked" name={[name, 'check']}>
                          <Checkbox>Seleccionar</Checkbox>
                        </Form.Item>
                      </Row>
                  </Card>
                  ))}
                </>
              )}
            </Form.List>

            <Divider />
          </>
        ) : null}

        <Button type="primary" htmlType="submit" hidden={loadingCreated}>
          Guardar
        </Button>

        <Divider />

        {productSelected ? (
          <>
            <Title level={5}>{productSelected.name}</Title>

            <List
              itemLayout="vertical"
              size="small"
              pagination={false}
              dataSource={productSelected.skus}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  actions={[]}
                  extra={<Barcode value={item.ean ? item.ean : ''} />}
                >
                  <List.Item.Meta
                    title={
                      <h5>
                        EAN: {item.ean}
                        <br />
                        {item.skuOptions.length > 0 ? (
                          <>
                            {item.skuOptions
                              ?.map(
                                (o) =>
                                  `${o.option?.variant.name}: ${o.option?.name}`,
                              )
                              .join('\n')}
                          </>
                        ) : (
                          ''
                        )}
                      </h5>
                    }
                  />
                </List.Item>
              )}
            />
          </>
        ) : null}
      </Form>
    </>
  );
};

export default ProductEditForm;
