import React, { useEffect, useState } from 'react';
import { Badge, Button, DatePicker, Divider, Select, Typography } from 'antd';
import { downloadExcel } from 'react-export-table-to-excel';
import { useAuthStore } from '../../../../services/auth/auth.service';
import { useTransporterList } from '../../../../services/transporter-list/transporter-list.service';
import dayjs from 'dayjs';
import { useOrderObservationReport } from '../../../../services/order-report-created/order-report-created.service';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const ObservationReport = () => {
  const { authUser } = useAuthStore();
  const dateFormat = 'YYYY-MM-DD';
  const { getOrderObservation, loadingOrderObservations, orderObservations } = useOrderObservationReport();
  const [selectedDates, setSelectedDates] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const headerObservation = [
    'FECHA ENTREGA',
    'CASUÍSTICA',
    'COURIER',
    'NRO. PEDIDO',
    'TOTAL A COBRAR',
    'TOTAL PAGADO',
  ];
  const { transporters, loadingTransporters, getTransporters } =
    useTransporterList();
  const [transpSelected, setTranspSelected] = useState<string>('ALL');
  const [hiddenTransp, setHiddenTransp] = useState(false);

  function handleDownloadReportObservationsExcel() {
    downloadExcel({
      fileName: `report-observation-${selectedDates[0].format('YYYY-MM-DD')}-to-${selectedDates[1].format('YYYY-MM-DD')}`,
      sheet: 'observaciones',
      tablePayload: {
        header: headerObservation,
        body: orderObservations.map((p) => {
          return {
            deliveryDate: p.deliveryDate,
            casuistry: p.casuistry,
            courier: p.courier,
            orderNumber: p.orderNumber,
            total: p.total,
            totalPay: p.totalPay,
          };
        }),
      },
    });
  }

  const handleCalendarChange = (dates: any, dateStrings: any) => {
    setSelectedDates(dates);
  };

  const handleChangeTransporter = (id: string) => {

    getOrderObservation(
      selectedDates[0].format('YYYY-MM-DD'),
      selectedDates[1].format('YYYY-MM-DD'),
      id,
    );
    setTranspSelected(id);
  };

  useEffect(() => {
    if (selectedDates.length === 2 && selectedDates[0] && selectedDates[1]) {
      getOrderObservation(
        selectedDates[0].format('YYYY-MM-DD'),
        selectedDates[1].format('YYYY-MM-DD'),
        transpSelected,
      );
    }
  }, [selectedDates]);

  useEffect(() => {
    if (authUser.user.role.name === 'SUPER_MASTER') {
      getTransporters();
      setHiddenTransp(false);
    } else if (
      authUser.user.role.name === 'ADMIN' ||
      authUser.user.role.name === 'MASTER'
    ) {
      setHiddenTransp(true);
      setTranspSelected(`${authUser.user.conveyor?.id}`);
    }
  }, []);

  return (
    <>
      <div>
        <Divider />

        <Title
          level={5}
          style={{
            marginBottom: '0',
            alignContent: 'center',
            padding: '.85rem 1.5rem',
          }}
        >
          Reporte de Observaciones
        </Title>

        <div
          style={{
            padding: '.25rem 1.5rem',
            display: 'flex',
            gap: '1rem',
            alignItems: 'center',
          }}
        >
          {hiddenTransp ? (
            <></>
          ) : (
            <>
              <Title level={5} style={{ marginBottom: '0' }}>
                Transportadora
              </Title>
              <Select
                onChange={handleChangeTransporter}
                showSearch
                optionFilterProp="key"
                loading={loadingTransporters}
                value={transpSelected}
                style={{ minWidth: '120px' }}
                filterOption={(input, option) =>
                  (option?.key as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Option key="ALL" value="ALL">
                  <Badge
                    style={{ marginRight: '.5rem' }}
                    color={'#000000'}
                    text=""
                  >
                    TODOS
                  </Badge>
                </Option>
                {transporters?.map((key) => (
                  <Option key={key.name} value={key.id}>
                    {key.name}
                  </Option>
                ))}
              </Select>
            </>
          )}

          <Title level={5} style={{ marginBottom: '0' }}>
            Seleccionar fechas
          </Title>

          <RangePicker
            format={dateFormat}
            defaultValue={[dayjs(new Date()), dayjs(new Date())]}
            onCalendarChange={handleCalendarChange}
            clearIcon={false}
            disabledDate={(current) => {
              if (!selectedDates || selectedDates.length === 0) {
                return false; //si no hay fechas seleccionadas no se bloquea
              }
              //obtener la primera fecha seleccionada
              const [start] = selectedDates;

              if (!start) return false;

              //Restringir la selección de más de 15 días -- si esta fuera de rango se deshabilita
              return current.isBefore(start.subtract(15, 'days')) || current.isAfter(start.add(15, 'days'));
            }}

          />

          <Button
            type="primary"
            onClick={handleDownloadReportObservationsExcel}
            loading={loadingOrderObservations}
            disabled={orderObservations.length === 0}
          >
            Descargar reporte
          </Button>
        </div>
      </div>
    </>
  );
};

export default ObservationReport;
