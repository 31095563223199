import React, { FC, useEffect, useState } from 'react'
import { Button, Form, FormInstance, Input, Modal, Space, Typography, message, Radio } from 'antd'
import { IProductSearchSchema } from '../../../../../../services/product/product.schema'
import { ProductListSearch } from '../../../../OrdersView/components'
import { ProductOrderDeliveryForm } from '../../../../OrdersView/forms'
import { IOrderDetailsResponse } from '../../../../../../services/order-list/order.interface'
import { IOrderUpdateSchema } from '../../../../../../services/order-update/order-update.schema'

const { Title } = Typography

interface IPreOrderProductPartProps {
  form: FormInstance<IOrderUpdateSchema>
  orderDetails?: IOrderDetailsResponse[]
  disabled: boolean
  warehouseName: string
  warCompanyId: number
}

const OrderProductPart: FC<IPreOrderProductPartProps> = ({
  orderDetails,
  form,
  disabled,
  warehouseName,
  warCompanyId
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [productExistList, setProductExistList] = useState<IProductSearchSchema[]>([])
  const [total, setTotal] = useState<number>(0)
  const warehouseToday = Form.useWatch(['warehouseCurrent'], form)
  const warehouseHourDays = Form.useWatch(['wareHours'], form)
  const provinceId = Form.useWatch(['shipping', 'provinceId'], form);
  const departmentId = Form.useWatch(['shipping', 'departmentId'], form);
  const districtId = Form.useWatch(['shipping', 'districtId'], form);

  const calculateCouriers = (couriers: any) => {
    form.setFieldValue(['shipping', 'dptoJson'], couriers)
  }

  const warehouseCurrent = (warName: string) => {
    form.setFieldValue('warehouseCurrent', warName)
  }

  const setWarehouse = (war: any) => {
    form.setFieldValue('wareHours', war)
  }

  const handleSubmit = (data: IProductSearchSchema) => {
    const prod = productExistList.filter(t => t.action !== 'delete')[0]

    if (prod) {
      if (prod.warehouseId === data.warehouseId) {
        setProductExistList([...productExistList, { ...data, action: 'add' }])
        setIsModalVisible(false)
      } else {
        message.warning(`Debe seleccionar un producto del almacén ${prod.warehouseName}`)
      }
    } else {
      setProductExistList([...productExistList, { ...data, action: 'add' }])
      setIsModalVisible(false)
    }
  }

  const handleDeleteProduct = (item: IProductSearchSchema) => {
    const list: IProductSearchSchema[] = productExistList?.map(e => {
      return e.skuId === item.skuId ?
        { ...e, action: 'delete' } :
        e
    })

    setProductExistList(list)
  }

  useEffect(() => {

    if (productExistList.filter(t => t.action !== 'delete').length > 0) {
      form.setFieldValue('enableDpto', true)
    } else {
      form.setFieldValue('enableDpto', false)
      form.setFieldValue('warehouseCurrent', undefined)
      
      if (Array.isArray(orderDetails) && orderDetails.length > 0 && productExistList.length > 0) {
        form.setFieldValue(['shipping', 'scheduleDate'], undefined)
        form.setFieldValue(['shipping', 'dispatchDate'], undefined)
      }
    }

    const prodMap = productExistList.filter(t => t.action !== 'delete')?.map(p => p.subtotal)

    setTotal(prodMap?.length ? prodMap.reduce((a, b) => a + b) : 0)
    form.setFieldValue('orderDetails', productExistList)
  }, [productExistList])

  useEffect(() => {
    if (orderDetails) {
      const prodMap: IProductSearchSchema[] = orderDetails?.map(p => {
        const productName = p.sku.product?.name + ' / ' + p.sku.skuOptions
          .map(e => ({
            option: e.option?.name,
            variant: e.option?.variant.name
          }))
          .map(e => `${e.variant} ${e.option}`)
          .join(' / ')

        return {
          id: p.id,
          price: p.price,
          quantity: p.quantity,
          subtotal: p.subtotal,
          skuId: p.sku.id,
          productName,
          sku: p.sku,
          warehouseId: p.warehouseId,
          warehouseName: warehouseName,
          warCompanyId: warCompanyId,
          companyId: p.companyId,
          dropPrice: p.dropPrice,
          action: 'update'
        }
      })

      setProductExistList(prodMap)
    }
  }, [orderDetails])

  const hourWarehouse = () => {

    const hourMain = warehouseHourDays && warehouseHourDays.hourMain 
      && warehouseHourDays.hourMain.length > 0 ? warehouseHourDays.hourMain : ''

    const stringHourMain = hourMain.length > 0 ? `<br/>Contra entrega Lima: ${hourMain}h` : ''

    const hourSecundary = warehouseHourDays && warehouseHourDays.hourSecundary 
      && warehouseHourDays.hourSecundary.length > 0 ? warehouseHourDays.hourSecundary : ''

    const stringHourSecundary = hourSecundary.length > 0 ? 
      `<br/>Contra entrega provincia: ${hourSecundary}h` : ''

    const hourAgency = warehouseHourDays && warehouseHourDays.hourAgency 
      && warehouseHourDays.hourAgency.length > 0 ? warehouseHourDays.hourAgency : ''

    const stringHourAgency = hourAgency.length > 0 ? 
      `<br/>Horario de cierre Drop Shalom: ${hourAgency}h` : ''

    return 'Horario de cierre:'.concat(stringHourMain)
      .concat(stringHourSecundary)
      .concat(stringHourAgency)
  }

  return <>
    <Space style={{ marginBottom: '.5rem' }}>
      <Button type="link" onClick={() => setIsModalVisible(true)} hidden={disabled}>+ Agregar</Button>
      <Title level={5} style={{ marginBottom: 0, fontSize: '12px' }}>
        Almacén: {warehouseToday || warehouseName}<br/>
        <div dangerouslySetInnerHTML={{ __html: hourWarehouse() }} />
        Horario de entrega del pedido de 10AM a 5PM
      </Title>
      <Modal
        title="Buscar Producto"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        destroyOnClose
      >
        <ProductOrderDeliveryForm
          onSubmit={handleSubmit}
          warehouseSelected={setWarehouse}
          couriersSelected={calculateCouriers}
          departmentId={`${departmentId}`}
          provinceId={`${provinceId}`}
          districtId={`${districtId}`}
          warhSelected={warehouseCurrent}
        />
      </Modal>
    </Space>

    <ProductListSearch
      data={productExistList.filter(e => e.action !== 'delete')}
      total={total}
      onDelete={handleDeleteProduct}
      disabled={disabled}
    />

    <Form.Item name={['enableDpto']} label="Activar departamento" hidden>
      <Radio.Group
        options={[
          { label: 'No', value: false },
          { label: 'Si', value: true },
        ]}
        optionType="button"
        buttonStyle="solid"
      />
    </Form.Item>

    <Form.Item name={['addDays']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['hoursWork']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['warehouseCurrent']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['wareHours']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['typePattern']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['orderDetails']} hidden>
      <Input />
    </Form.Item>

  </>
}

export default OrderProductPart
