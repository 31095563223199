import React, { useEffect } from 'react';
import { Spin } from 'antd';
import {
  useGetSetting,
  useSaveSetting,
} from '../../../../services/setting/setting.service';
import { ConfigOrderForm } from '../../forms';
import { ISettingSchema } from '../../../../services/setting/setting.schema';
import { CompanyChangeContainer } from '../../../../containers';
import { PageHeader } from '../../../../components';
import { useAuthStore } from '../../../../services/auth/auth.service';
import { useCompanyStore } from '../../../../services/company-create/company-create.service';
// import { TutoComponent } from '../../../../components/TutoComponent/TutoComponent';

const ConfigOrderContainer = () => {
  const { authUser } = useAuthStore();
  const { getSetting, setting, loading: loadingGet } = useGetSetting();
  const { saveSetting, loading: loadingSave } = useSaveSetting();
  const { companyGlobal } = useCompanyStore();

  const handleSubmit = (data: ISettingSchema) => {
    saveSetting(data);
  };

  useEffect(() => {
    if (authUser.user.role.name === 'ADMIN_STORE') {
      getSetting(`${authUser.user.company.id}`);
    }
  }, []);

  useEffect(() => {
    if (companyGlobal) {
      getSetting(`${companyGlobal.id}`);
    }
  }, [companyGlobal]);

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', alignItems: 'center', gap : '1rem' }}>  
          {/* <TutoComponent
            link='https://www.loom.com/share/63a2f0456bcf455784a8fd5fccc960cb?sid=f4dbd6e3-e4c3-413f-8fed-7ee06bca22b1'
          /> */}
          {authUser.user.role?.name === 'SUPER_MASTER' ? (
            <CompanyChangeContainer isAll={false} />
          ) : null}
        </div>
      </PageHeader>

      <Spin spinning={loadingGet || loadingSave}>
        <div className="container" style={{ maxWidth: '720px' }}>
          <ConfigOrderForm initialValues={setting} onSubmit={handleSubmit} />
        </div>
      </Spin>
    </>
  );
};

export default ConfigOrderContainer;
