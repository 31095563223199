import React, { useEffect, useState } from 'react';
import { Button, Select } from 'antd';
import { PageHeader } from '../../../../../components';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { IOrderFilter } from '../../../../../services/order-list/order.interface';
import { useOrderStore } from '../../../../../services/order-list/order.store';
import { DeliveryTable } from '../../components';
import { DeliveryFilterForm } from '../../forms';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import {
  useConveyorStore,
  useLocationLevel1,
  useLocationLevel2,
} from '../../../../../services/location/location.service';
import { useOrderDispatchedList } from '../../../../../services/order-dispatched/order-dispatched.service';
import { useDispatchGuideStore } from '../../../../../services/dispatch-guide-create/dispatch-guide.store';
import {
  ORDER_DISPATCH_STATUS_LABEL,
  ORDER_DISPATCH_STATUS_LIST,
} from '../../../../../services/order-create/order.params';
import { useWarehouseByCountry } from '../../../../../services/warehouse-list/warehouse-list.service';
import { useTransporterList } from '../../../../../services/transporter-list/transporter-list.service';
import { useUserListTransport } from '../../../../../services/order-create/order.edit';
import OrderDeliveryGroup1 from '../../components/OrderDeliveryGroupTable/OrderDeliveryGroup1';
import { useOrderDispatchedGroupList } from '../../../../../services/order-dispatched/order-dispatched-group.service';
// import { TutoComponent } from '../../../../../components/TutoComponent/TutoComponent';

const { Option } = Select;

const OrdersAssignmentListContainer = () => {
  const { orders, loading, getOrders } = useOrderDispatchedList();
  const { ordersGroup, loadingGroup, getOrdersGroup } = useOrderDispatchedGroupList()
  const { warehouses, loadingWarehouses, getWarehouses } =
    useWarehouseByCountry();
  const { orderUpdated, orderDeliveryChecked, setOrderDeliveryChecked } =
    useOrderStore();
  const { guideAssignUpdated, setDispatchGuideSelected, dispatchGuideUpdated } =
    useDispatchGuideStore();
  const { authUser } = useAuthStore();
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } =
      useLocationLevel1();
  const { getLocationsGeoLevel2, loadingGeo2, locationsGeolevel2 } =
      useLocationLevel2();
  const [department, setDepartment] = useState<string>('ALL');
  const [filterValue, setFilterValue] = useState<IOrderFilter>();
  const { openDrawer } = useDrawer();
  const [defaultSelected, setDefaultSelected] = useState<string>('ALL');
  const [dispatchSelected, setDispatchSelected] = useState<string>('ALL');
  const [warehouseSelected, setWarehouseSelected] = useState<string>('ALL');
  const [defaultSend] = useState<string>('0');
  const { transporters, loadingTransporters, getTransporters } =
    useTransporterList();
  const [transpSelected, setTranspSelected] = useState<string>('');
  const [transpIdSelected, setTranspIdSelected] = useState<string>('');
  const [hiddenTransp, setHiddenTransp] = useState(false);
  const { setConveyorSelected } = useConveyorStore();
  const { isComboxTransp } = useUserListTransport();
  const [showDpto, setShowDpto] = useState(true)

  const initOrdersService = (filterData: any) => {
    if (authUser.user.company.countryCode === 'PER' || authUser.user.company.countryCode === 'MEX') {
      getOrdersGroup(filterData);
    } else {
      getOrders(filterData);
    }
  }

  const handleSubmit = (filter: IOrderFilter) => {
    setFilterValue(filter);

    initOrdersService({
      ...filter,
      isOrderAgency: defaultSend,
      dispatchStatus: dispatchSelected,
      conveyorId: transpIdSelected,
      warehouseName: warehouseSelected,
      departmentCode: department,
    })
    
  };

  useEffect(() => {
    if (transporters && transporters.length > 0) {
      setTranspIdSelected(`${transporters[0].id}`);
      setTranspSelected(`${transporters[0].name}`);

      setConveyorSelected({
        id: transporters[0].id,
        name: transporters[0].name,
        coverages: [],
        isConveyorPremium: false,
      });

      initOrdersService({
        ...filterValue,
        provinceCode: defaultSelected,
        isOrderAgency: defaultSend,
        dispatchStatus: dispatchSelected,
        warehouseName: warehouseSelected,
        conveyorId: `${transporters[0].id}`,
        departmentCode: department,
      });
    }
  }, [transporters]);

  const handleGuideAssignChange = () => {
    openDrawer('ASSIGN_DISPATCH_EDIT', 'Asignar Guía');
  };

  const handleCreateGuideChange = () => {
    setDispatchGuideSelected(null);
    openDrawer('DISPATCH_GUIDE_EDIT', 'Crear guía');
  };

  const handleChangeProvince = (ubigeId: string) => {
    initOrdersService({
      ...filterValue,
      provinceCode: ubigeId,
      isOrderAgency: defaultSend,
      dispatchStatus: dispatchSelected,
      warehouseName: warehouseSelected,
      conveyorId: transpIdSelected,
      departmentCode: department,
    });
    setDefaultSelected(ubigeId);
  };

  const handleChangeDepartment = (ubigeId: string) => {

    if (ubigeId === 'ALL') {
      setShowDpto(true)
    } else {
      setShowDpto(false)
      setDefaultSelected('ALL')
      getLocationsGeoLevel2({ parentId: parseInt(ubigeId) })
    }

    initOrdersService({
      ...filterValue,
      provinceCode: 'ALL',
      isOrderAgency: defaultSend,
      dispatchStatus: dispatchSelected,
      warehouseName: warehouseSelected,
      conveyorId: transpIdSelected,
      departmentCode: ubigeId,
    });
    setDepartment(ubigeId);
  };

  const handleChangeWarehouse = (value: string) => {
    initOrdersService({
      ...filterValue,
      provinceCode: defaultSelected,
      isOrderAgency: defaultSend,
      dispatchStatus: dispatchSelected,
      warehouseName: value,
      conveyorId: transpIdSelected,
      departmentCode: department,
    });
    setWarehouseSelected(value);
  };

  const handleChangeDispatch = (value: string) => {
    initOrdersService({
      ...filterValue,
      provinceCode: defaultSelected,
      isOrderAgency: defaultSend,
      dispatchStatus: value,
      warehouseName: warehouseSelected,
      conveyorId: transpIdSelected,
      departmentCode: department,
    });
    setDispatchSelected(value);
  };

  useEffect(() => {
    if (orderUpdated || guideAssignUpdated || dispatchGuideUpdated) {
      initOrdersService({
        ...filterValue,
        provinceCode: defaultSelected,
        isOrderAgency: defaultSend,
        dispatchStatus: dispatchSelected,
        warehouseName: warehouseSelected,
        conveyorId: transpIdSelected,
        departmentCode: department,
      });
    }
  }, [orderUpdated, guideAssignUpdated, dispatchGuideUpdated]);

  useEffect(() => {

    getLocationsGeoLevel1()

    if (isComboxTransp === false) {
      getTransporters();
      setHiddenTransp(false);
    } else if (isComboxTransp === true) {
      setHiddenTransp(true);
      setTranspIdSelected(`${authUser.user.conveyor?.id}`);

      if (authUser.user.conveyor) {
        setConveyorSelected({
          id: authUser.user.conveyor?.id,
          name: '',
          coverages: [],
          isConveyorPremium: false,
        });
      }

      initOrdersService({
        ...filterValue,
        provinceCode: defaultSelected,
        isOrderAgency: defaultSend,
        dispatchStatus: dispatchSelected,
        warehouseName: warehouseSelected,
        conveyorId: `${authUser.user.conveyor?.id}`,
        departmentCode: department,
      });
    }
  }, []);

  useEffect(() => {
    setOrderDeliveryChecked([]);
    getWarehouses();
  }, [orders]);

  const handleChangeTransporter = (id: string) => {
    const transp = transporters.find((t) => `${t.name}` === `${id}`);

    if (transp) {
      setConveyorSelected(transp);
      initOrdersService({
        ...filterValue,
        provinceCode: defaultSelected,
        isOrderAgency: defaultSend,
        dispatchStatus: dispatchSelected,
        warehouseName: warehouseSelected,
        conveyorId: `${transp.id}`,
        departmentCode: department,
      });

      setTranspIdSelected(`${transp.id}`);
    }

    setTranspSelected(id);
  };

  return (
    <>
      <PageHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            gap: '1rem',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
            {/* <TutoComponent
              link='https://www.loom.com/share/e1619e2654ac4ba98792fb2fc466a8c6?sid=f164df9d-45c6-47d1-b872-6025b67b1728'
            /> */}
            {hiddenTransp ? (
              <></>
            ) : (
              <>
                <h5>Transportadora</h5>
                <Select
                  onChange={handleChangeTransporter}
                  showSearch
                  optionFilterProp="key"
                  loading={loadingTransporters}
                  value={transpSelected}
                  style={{ minWidth: '120px' }}
                  filterOption={(input, option) =>
                    (option?.key as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {transporters?.map((key) => (
                    <Option key={key.name} value={key.name}>
                      {key.name}
                    </Option>
                  ))}
                </Select>
              </>
            )}

            <DeliveryFilterForm onChange={handleSubmit} />

            <Button
              type="primary"
              onClick={handleCreateGuideChange}
              disabled={!orderDeliveryChecked?.length}
            >
              Crear guía
            </Button>

            <Button
              type="primary"
              onClick={handleGuideAssignChange}
              disabled={!orderDeliveryChecked?.length}
            >
              Asignar guía
            </Button>

            {authUser.user.warehouse ? (
              <></>
            ) : (
              <>
                <h5>Almacén</h5>

                <Select
                  placeholder="Almacén"
                  showSearch
                  loading={loadingWarehouses}
                  optionFilterProp="label"
                  style={{ minWidth: '150px' }}
                  onChange={handleChangeWarehouse}
                  value={warehouseSelected}
                  filterOption={(input, option) =>
                    (option?.label as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  <Option key="ALL" value="ALL" label={'TODOS'}>
                    TODOS
                  </Option>
                  {warehouses.map((key) => (
                    <Option key={key.id} value={key.name} label={key.name}>
                      {key.name}
                    </Option>
                  ))}
                </Select>
              </>
            )}
            <h5>Estado Despacho</h5>
            <Select
              placeholder="Despacho"
              showSearch
              optionFilterProp="label"
              style={{ minWidth: '130px' }}
              onChange={handleChangeDispatch}
              value={dispatchSelected}
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              <Option key="ALL" value="ALL" label={'TODOS'}>
                TODOS
              </Option>

              <Option
                key={ORDER_DISPATCH_STATUS_LIST.TO_PREPARE}
                value={ORDER_DISPATCH_STATUS_LIST.TO_PREPARE}
                label={
                  ORDER_DISPATCH_STATUS_LABEL[
                    ORDER_DISPATCH_STATUS_LIST.TO_PREPARE
                  ].label
                }
              >
                {
                  ORDER_DISPATCH_STATUS_LABEL[
                    ORDER_DISPATCH_STATUS_LIST.TO_PREPARE
                  ].label
                }
              </Option>

              <Option
                key={ORDER_DISPATCH_STATUS_LIST.PREPARED}
                value={ORDER_DISPATCH_STATUS_LIST.PREPARED}
                label={
                  ORDER_DISPATCH_STATUS_LABEL[
                    ORDER_DISPATCH_STATUS_LIST.PREPARED
                  ].label
                }
              >
                {
                  ORDER_DISPATCH_STATUS_LABEL[
                    ORDER_DISPATCH_STATUS_LIST.PREPARED
                  ].label
                }
              </Option>

              <Option
                key={ORDER_DISPATCH_STATUS_LIST.IN_TRANSIT}
                value={ORDER_DISPATCH_STATUS_LIST.IN_TRANSIT}
                label={
                  ORDER_DISPATCH_STATUS_LABEL[
                    ORDER_DISPATCH_STATUS_LIST.IN_TRANSIT
                  ].label
                }
              >
                {
                  ORDER_DISPATCH_STATUS_LABEL[
                    ORDER_DISPATCH_STATUS_LIST.IN_TRANSIT
                  ].label
                }
              </Option>

              <Option
                key={ORDER_DISPATCH_STATUS_LIST.PICKED}
                value={ORDER_DISPATCH_STATUS_LIST.PICKED}
                label={
                  ORDER_DISPATCH_STATUS_LABEL[ORDER_DISPATCH_STATUS_LIST.PICKED]
                    .label
                }
              >
                {
                  ORDER_DISPATCH_STATUS_LABEL[ORDER_DISPATCH_STATUS_LIST.PICKED]
                    .label
                }
              </Option>
            </Select>
            <h5>{authUser.user.company.countryCode === 'MEX' ? 'Estado' : 'Departamento'}</h5>
            <Select
              placeholder={authUser.user.company.countryCode === 'MEX' ? 'Estado' : 'Departamento'}
              showSearch
              loading={loadingGeo1}
              optionFilterProp="label"
              style={{ minWidth: '130px' }}
              onChange={handleChangeDepartment}
              value={department}
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              <Option key="ALL" value="ALL" label={'TODOS'}>
                TODOS
              </Option>
              {locationsGeolevel1.filter(f => f.flagContraentrega === true).map((key) => (
                <Option
                  key={key.id}
                  value={key.id}
                  label={key.name}
                >
                  {key.name}
                </Option>
              ))}
            </Select>

            { showDpto === false ? <>
              <h5>{authUser.user.company.countryCode === 'MEX' ? 'Municipio' : 'Provincia'}</h5>
              <Select
                placeholder={authUser.user.company.countryCode === 'MEX' ? 'Municipio' : 'Provincia'}
                showSearch
                loading={loadingGeo2}
                optionFilterProp="label"
                style={{ minWidth: '130px' }}
                onChange={handleChangeProvince}
                value={defaultSelected}
                filterOption={(input, option) =>
                  (option?.label as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Option key="ALL" value="ALL" label={'TODOS'}>
                  TODOS
                </Option>
                {locationsGeolevel2.filter(f => f.flagContraentrega === true).map((key) => (
                  <Option
                    key={key.id}
                    value={key.id}
                    label={key.name}
                  >
                    {key.name}
                  </Option>
                ))}
              </Select>
            </> : null }
            
          </div>
        </div>
      </PageHeader>

      { authUser.user.company.countryCode === 'PER' || authUser.user.company.countryCode === 'MEX' ? <>

        <OrderDeliveryGroup1 data={ordersGroup} loading={loadingGroup} />
      
      </> : <>
        <DeliveryTable data={orders} loading={loading} />
      </>}

      
    </>
  );
};

export default OrdersAssignmentListContainer;
