import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Space, Divider, Select, Checkbox, Switch } from 'antd';
import { ITransporterCreateSchema } from '../../../../../services/transporter-create/transporter-create.schema';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const { Option } = Select;

interface TransporterEditFormProps {
  onSubmit: (data: ITransporterCreateSchema) => void;
  isEditMode: boolean;
  onCancel: () => void;
  initialValues?: any; // Nuevo: para pasar los valores iniciales de la transportadora
}

const TransporterEditForm: React.FC<TransporterEditFormProps> = ({ onSubmit, isEditMode, onCancel, initialValues }) => {
  const [form] = Form.useForm();
  const [isMotorizedSupportEnabled, setIsMotorizedSupportEnabled] = useState(false);

  useEffect(() => {
    if (isEditMode && initialValues) {
      form.setFieldsValue(initialValues);
      setIsMotorizedSupportEnabled(initialValues.flagMotorizedSupportAttention || false);
    } else {
      form.resetFields();
    }
  }, [isEditMode, form, initialValues]);

  const handleFinish = (values: ITransporterCreateSchema) => {
    const urlPattern = /^(https?:\/\/)/;

    if (!urlPattern.test(values.urlImage)) {
      message.error('Por favor, ingrese una URL válida que comience con http:// o https://');

      return;
    }

    onSubmit(values);
    form.resetFields();
  };

  return (
    <Form form={form} onFinish={handleFinish} initialValues={{ urlImage: '', isActive: true }}>
      {!isEditMode && (
        <Form.Item
          label="Nombre"
          name="name"
          rules={[{ required: true, message: 'Por favor ingrese el nombre de la transportadora' }]}
        >
          <Input placeholder="Nombre de la transportadora" />
        </Form.Item>
      )}

      <Form.Item
        label="URL de Imagen"
        name="urlImage"
        rules={[
          { required: true, message: 'Por favor ingrese la URL de la imagen' },
          { pattern: /^(https?:\/\/)/, message: 'La URL debe comenzar con http:// o https://' },
        ]}
      >
        <Input placeholder="https://ejemplo.com/imagen.jpg" />
      </Form.Item>
      
      {/* Cuando se va a agregar no se muestra - prede. Activo */}
      <Form.Item
        label="Estado"
        name="isActive"
      >
        <Select placeholder="Seleccione el estado" disabled={initialValues === null}>
          <Option value={true}>Activo</Option>
          <Option value={false}>Inactivo</Option>
        </Select>
      </Form.Item>
      
      {/* Tipo */}
      <Form.Item
        label="Tipo"
        name="type"
        rules={[{ required: true, message: 'Por favor seleccione el tipo' }]}
      >
        <Select placeholder="Seleccione el tipo">
          <Option value="PUBLIC">Público</Option>
          <Option value="PRIVATE">Privado</Option>
        </Select>
      </Form.Item>
      
      {/* Soporte */}
      <Form.Item name="flagMotorizedSupportAttention" valuePropName="checked">
        <Checkbox
          checked={isMotorizedSupportEnabled}
          onChange={(e) => setIsMotorizedSupportEnabled(e.target.checked)}
        >
          Habilitar soporte motorizado
        </Checkbox>
      </Form.Item>
      
      {isMotorizedSupportEnabled && (
        <Form.Item
          name="supportCentralNumber"
          label="Número de Soporte"
          rules={[
            { required: true, message: 'Debe ingresar un número de soporte' },
            { pattern: /^[0-9]{11}$/, message: 'Debe tener 11 dígitos numéricos' },
          ]}
        >
          <Input maxLength={11} />
        </Form.Item>
      )}

      <Form.Item
        label="Comisión motorizado"
        name={'showCommission'}
        valuePropName="checked"
      >
        <Switch 
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked
        />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button onClick={onCancel}>Cancelar</Button>
          <Divider type="vertical" />
          <Button type="primary" htmlType="submit">
            {isEditMode ? 'Actualizar' : 'Agregar'} Transportadora
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default TransporterEditForm;
