import React, { FC, useEffect } from 'react'
import { Form, FormInstance, Input, Select } from 'antd'
import { 
  useLocationLevel1, useLocationLevel2, useLocationLevel3 
} from '../../../../../../services/location/location.service'
import { IWarehouseCreateSchema } from '../../../../../../services/warehouse-create/warehouse-create.schema'
import { IWarehouseResponse } from '../../../../../../services/warehouse-list/warehouse-list.interface'
import { useAuthStore } from '../../../../../../services/auth/auth.service'

interface IWarehouseLocationPartProps {
  form: FormInstance<IWarehouseCreateSchema>
  warSelect: IWarehouseResponse | null
}

const WarehouseLocationPart: FC<IWarehouseLocationPartProps> = ({ form, warSelect }) => {
  const province = Form.useWatch(['province'], form)
  const department = Form.useWatch(['department'], form)
  const { authUser } = useAuthStore()
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } =
    useLocationLevel1();
  const { getLocationsGeoLevel2, loadingGeo2, locationsGeolevel2 } =
    useLocationLevel2();
  const { getLocationsGeoLevel3, loadingGeo3, locationsGeolevel3 } =
    useLocationLevel3();

  const handleChangeDepartment = (ubigeoId: string) => {
    getLocationsGeoLevel2({ parentId: +ubigeoId })

    if (locationsGeolevel1 && locationsGeolevel1.length > 0) {
      const ubigeo1 = locationsGeolevel1.find(l => `${l.id}` === `${ubigeoId}`)

      if (ubigeo1) {
        form.setFieldValue(['departmentName'], ubigeo1.name)
      }

    }

  }

  const handleChangeProvince = (ubigeoId: string) => {
    getLocationsGeoLevel3({ parentId: +ubigeoId })

    if (locationsGeolevel2 && locationsGeolevel2.length > 0) {
      const ubigeo2 = locationsGeolevel2.find(l => `${l.id}` === `${ubigeoId}`)

      if (ubigeo2) {
        form.setFieldValue(['provinceName'], ubigeo2.name)
      }

    }

  }

  const handleChangeDistrict = (ubigeoId: string) => {
    if (locationsGeolevel3 && locationsGeolevel3.length > 0) {
      const ubigeo3 = locationsGeolevel3.find(l => `${l.id}` === `${ubigeoId}`)

      if (ubigeo3) {
        form.setFieldValue(['districtName'], ubigeo3.name)
      }

    }
  }

  useEffect(() => {
    getLocationsGeoLevel1()
  }, [])

  useEffect(() => {
    if (province) {
      handleChangeProvince(province)
    }
  }, [province])

  useEffect(() => {
    if (department) {
      handleChangeDepartment(department)
    }
  }, [department])

  useEffect(() => {

    if (warSelect) {
      form.setFieldValue(['address'], warSelect.address)
      form.setFieldValue(['gps'], `${warSelect.lat || 0},${warSelect.lng || 0}`)
      form.setFieldValue(['department'], warSelect.departmentCode)
      form.setFieldValue(['province'], warSelect.provinceCode)
      form.setFieldValue(['district'], warSelect.districtCode)
    }

  }, [warSelect])

  return <>

    <Form.Item
      label={<span style={{ fontSize: '13px' }}>
      Dirección
      </span>}
      name={['address']} rules={[{ required: false, message: '' }]}>
      <Input style={{ display: 'inline-block', width: '100%', borderRadius: '8px' }}/>
    </Form.Item>

    <Form.Item
      label={<span style={{ fontSize: '13px' }}>
      GPS
      </span>}
      name={['gps']}
      rules={[{
        message: '',
        pattern: /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/
      }]}>
      <Input
        placeholder='Latitud,Longitud'
        style={{ display: 'inline-block', width: '100%', borderRadius: '8px' }}
      />
    </Form.Item>

    <Form.Item name={['provinceName']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['districtName']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['departmentName']} hidden>
      <Input />
    </Form.Item>

    <Form.Item
      label={<span style={{ fontSize: '13px' }}>
        {authUser.user.company.countryCode === 'MEX' ? 'Estado' : 'Departamento'}
      </span>}
      name={['department']}>
      <Select
        onChange={handleChangeDepartment}
        showSearch
        loading={loadingGeo1}
        optionFilterProp="label"
        filterOption={(input, option) =>
          (option?.label as string).toLowerCase().includes(input.toLowerCase())
        }
        options={locationsGeolevel1?.map(e => ({
          label: e.name,
          value: `${e.id}`,
        }))}
      />
    </Form.Item>

    <Form.Item
      label={<span style={{ fontSize: '13px' }}>
        {authUser.user.company.countryCode === 'MEX' ? 'Municipio' : 'Provincia'}
      </span>}
      name={['province']}>
      <Select
        onChange={handleChangeProvince}
        showSearch
        loading={loadingGeo2}
        optionFilterProp="label"
        filterOption={(input, option) =>
          (option?.label as string).toLowerCase().includes(input.toLowerCase())
        }
        options={locationsGeolevel2?.map(e => ({
          label: e.name,
          value: `${e.id}`,
        }))}
      />
    </Form.Item>

    <Form.Item
      label={<span style={{ fontSize: '13px' }}>
       {authUser.user.company.countryCode === 'MEX' ? 'Código postal / Colonia' : 'Distrito'}
      </span>}
      name={['district']}>
      <Select
        showSearch
        onChange={handleChangeDistrict}
        loading={loadingGeo3}
        optionFilterProp="label"
        filterOption={(input, option) =>
          (option?.label as string).toLowerCase().includes(input.toLowerCase())
        }
        options={locationsGeolevel3?.map(e => ({
          label: e.name,
          value: `${e.id}`,
        }))}
      />
    </Form.Item>

  </>
}

export default WarehouseLocationPart
