
import React, { FC, useEffect, useState } from 'react'
import {
  Button, Form, FormInstance, Input, DatePicker, Select, Upload, message, Radio, RadioChangeEvent, InputNumber
} from 'antd'
import { IPaymentCreateSchema } from '../../../../../services/payment-create/payment-create.schema'
import { ENTITY_LABEL, PAYMENT_METHOD_LABEL } from '../../../../../services/payment-create/payment.params'
import { UploadOutlined } from '@ant-design/icons'
import { storage } from '../../../../../libs/firebase'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { useAuthStore } from '../../../../../services/auth/auth.service'

const { Option } = Select

interface IPaymentEditFormProps {
  onSubmit: (data: IPaymentCreateSchema) => void
  orderId: number
  isOrderAgency: boolean
  amount: number
  form: FormInstance<IPaymentCreateSchema>
  currencySymbol: string
}

const PaymentEditForm: FC<IPaymentEditFormProps> = ({
  onSubmit, orderId, form, currencySymbol, isOrderAgency, amount
}) => {
  const urlPayment = Form.useWatch(['paymentDocument'], form)
  const [percent, setPercent] = useState(0)
  const [radioPay, setRadioPay] = useState(true)
  const [file, setFile] = useState<any>()
  const [url, setUrl] = useState('')
  const [savePay, setSavePay] = useState(true)
  const fAmount = Form.useWatch(['amount'], form)
  const { authUser } = useAuthStore()

  const onChange = (e: RadioChangeEvent) => {
    form.setFieldValue(['isPayMain'], e.target.value)
    setRadioPay(e.target.value)
    
    if (e.target.value === true) {
      if (authUser.user.role.name === 'MASTER') {
        setSavePay(false)
      } else {
        if (fAmount >= amount) {
          setSavePay(false)
        } else {
          setSavePay(true)
        }
      }
    } else {
      setSavePay(false)
    }
  }

  function handleBeforeUpload(file: any) {
    setFile(file)
    if (!file) {
      message.success('Debe seleccionar un archivo')

      return
    }

    const storageRef = ref(storage, `/files_payment/${file?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setPercent(percent)
      }, (_) => {
        //console.log('error', error)
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrl(url)
        })
      }
    )

    return false
  }

  useEffect(() => {
    form.setFieldsValue({
      paymentDocument: '',
      paymentMethod: isOrderAgency ? 'T' : '',
      entity: '',
      paymentDate: '',
      amount: undefined,
      orderId: orderId,
      isPayMain: true,
    })
  }, [])

  useEffect(() => {
    form.setFieldValue(['paymentDocument'], url)
  }, [url])

  return (
    <>
      <Form
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        form={form}
        name="payment"
        labelWrap
        onFinish={onSubmit}
      >
      
        <Form.Item hidden={true} name={['orderId']}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Form.Item
            label='Método pago'
            name={['paymentMethod']}
            rules={[{ required: true, message: 'Seleccione un método de pago' }]}
            style={{ display: 'inline-block', width: 'calc(40% + 8px)' }}
          >
            <Select placeholder="Método de pago">
              {isOrderAgency ? <>
                <Option key={'T'} value={'T'} >{'Transferencia'}</Option>
              </> : <>
                {Object.keys(PAYMENT_METHOD_LABEL).map(key => (
                  <Option key={key} value={key} >{PAYMENT_METHOD_LABEL[key].label}</Option>
                ))}
              </>}
            </Select>
          </Form.Item>
          <Form.Item
            label='Entidad'
            name={['entity']}
            rules={[{ required: isOrderAgency, message: 'Seleccione una entidad' }]}
            style={{ display: 'inline-block', width: 'calc(40%)' }}
          >
            <Select
              placeholder="Entidad">
              {Object.keys(ENTITY_LABEL).map(key => (
                <Option key={key} value={key} >{ENTITY_LABEL[key].label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Form.Item label="Fecha de pago"
            name={['paymentDate']}
            rules={[{ required: true, message: 'Seleccionar una fecha de pago' }]}
            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}>
            <DatePicker
              format={'DD/MM/YYYY'}/>
          </Form.Item>
          <Form.Item name={['paymentDocument']}
            label="Seleccione un archivo:"
            rules={[{ required: isOrderAgency, message: 'Adjuntar documento de pago' }]}
            style={{ display: 'inline-block', width: 'calc(40%)' }}
          >
            <Upload 
              name='file'
              beforeUpload={handleBeforeUpload}
              maxCount={1}
              fileList={[]}
            >
              <Button>
                <UploadOutlined /> {file ? file.name : ''} {percent} %
              </Button>
            </Upload>
            { urlPayment?.length !== 0 ? <>
              <a 
                href={url} 
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'underline' }}
              >Visualizar archivo adjunto</a>
            </> : null }
          </Form.Item>
          
        </Form.Item>

        <Form.Item>
          <Form.Item 
            label={`Monto ${currencySymbol}`}
            name={['amount']}
            rules={[{ required: true, message: 'Ingresar monto menor o igual al total' },
              {
                //validar el valor ingresado
                validator: (_, value) => {
                  if (authUser.user.role.name === 'SUPER_MASTER') {
                    return Promise.resolve();
                  }
                  if (!Number.isInteger(value)) {
                    return Promise.reject('Solo se permiten números enteros');
                  }

                  return Promise.resolve();
                },
              },
            ]}
            style={{ display: 'inline-block', width: 'calc(30%)' }}>
            <InputNumber 
              min={0}
              step={authUser.user.role.name === 'SUPER_MASTER' ? 0.01 : 1}
              //se ejecuta cuando esta activo el campo en tiempo real
              onKeyPress={(event) => {
                const isSuperMaster = authUser.user.role.name === 'SUPER_MASTER';
                const regex = isSuperMaster ? /[0-9.]*/ : /[0-9]*/; //super master decimal
                
                if (!regex.test(event.key)) {//activa el errir
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>

          <Form.Item name={['isPayMain']} hidden={true}></Form.Item>
          <Form.Item label="Abonado a"
            style={{ display: 'inline-block', width: 'calc(50%)' }}>
            <Radio.Group onChange={onChange} value={radioPay}>
              <Radio value={true}>Courier</Radio>
              <Radio value={false}>Ecommerce</Radio>
            </Radio.Group>
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="middle" hidden={savePay}>
            Guardar Pago
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default PaymentEditForm
