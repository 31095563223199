import React from 'react'
import { Form, Input, Typography, InputNumber } from 'antd'

const { Title } = Typography

const OrderCustomerPart = (
  { disabled,
    companyUser,
    isField 
  }: { disabled: boolean, companyUser: number, isField: boolean } 
) => {

  return <>
    <Title level={4}>Cliente</Title>
    <Form.Item name={['customer', 'companyId']} hidden={true}>
      <Input />
    </Form.Item>
    <Form.Item
      label="Nombres y Apellidos"
      name={['customer', 'name']}
      rules={[{ required: true, message: 'Obligatorio' }]}
    >
      <Input disabled={disabled}/>
    </Form.Item>
    <Form.Item
      label="Apellidos"
      hidden
      name={['customer', 'lastName']}
    >
      <Input disabled={disabled}/>
    </Form.Item>
    <Form.Item
      label="Móvil"
      name={['customer', 'phone']}
      rules={[{ required: true, message: 'Obligatorio' }]}
    >
      <InputNumber
        disabled={companyUser == 2 ? isField : disabled}
        controls={false}
        maxLength={11}
        style={{ display: 'inline-block', width: '100%' }}
      />
    </Form.Item>
  </>
}

export default OrderCustomerPart
