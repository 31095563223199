import { Badge, DatePicker, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../../../../components';
import { OrdersMotorizedSummary, OrdersTable } from '../../components';
import { useMotorizedToConveyorList } from '../../../../../services/user-motorized/user-motorized.service';
import { subDays } from 'date-fns';
import { useOrdersToReturn } from '../../../../../services/order-return/order-return.service';
import { useLocationLevel1 } from '../../../../../services/location/location.service';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { CompanyChangeContainer } from '../../../../../containers';
import { useCompanyStore } from '../../../../../services/company-create/company-create.service';
import { useTransporterList } from '../../../../../services/transporter-list/transporter-list.service';
import { useUserListTransport } from '../../../../../services/order-create/order.edit';
import {
  ORDER_DISPATCH_STATUS_LABEL,
  ORDER_DISPATCH_STATUS_LIST,
} from '../../../../../services/order-create/order.params';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { Option } = Select;

const OrdersToReturnContainer = () => {
  const dateFormat = 'YYYY-MM-DD';
  const { authUser } = useAuthStore();
  const { companyGlobal } = useCompanyStore();
  const { getMotorizeds, loadingMt, motorizeds } = useMotorizedToConveyorList();
  const [motoSelected, setMotoSelected] = useState<string>('ALL');
  const [dptoSelected, setDptoSelected] = useState<string>('ALL');
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } =
    useLocationLevel1();
  const { getOrdersToReturn, orders, ordersMotorized, loading } =
    useOrdersToReturn();
  const [selectedDatesOrder, setSelectedDatesOrder] = useState([
    dayjs(subDays(new Date(), 30)),
    dayjs(new Date()),
  ]);
  const { transporters, loadingTransporters, getTransporters } =
    useTransporterList();
  const [transpSelected, setTranspSelected] = useState<string>('ALL');
  const [hiddenTransp, setHiddenTransp] = useState(false);
  const [dispatchSelected, setDispatchSelected] = useState<string>('ALL');
  const { isComboxTransp } = useUserListTransport();

  useEffect(() => {
    if (isComboxTransp === false) {
      getTransporters();
      getMotorizeds(transpSelected);
      setHiddenTransp(false);
    } else if (isComboxTransp === true) {
      setHiddenTransp(true);
      getMotorizeds(`${authUser.user.conveyor?.id}`);
      setTranspSelected(`${authUser.user.conveyor?.id}`);
    }
    getLocationsGeoLevel1();
  }, []);

  useEffect(() => {
    if (
      (companyGlobal && transpSelected !== 'ALL' && isComboxTransp === true) ||
      (isComboxTransp === false && companyGlobal)
    ) {
      getOrdersToReturn(
        selectedDatesOrder[0].format('YYYY-MM-DD'),
        selectedDatesOrder[1].format('YYYY-MM-DD'),
        dptoSelected,
        motoSelected,
        `${companyGlobal.id}`,
        transpSelected,
        dispatchSelected,
      );
    }
  }, [companyGlobal]);

  const handleChangeMotorized = (motoId: string) => {
    if (
      (companyGlobal && transpSelected !== 'ALL' && isComboxTransp === true) ||
      (isComboxTransp === false && companyGlobal)
    ) {
      getOrdersToReturn(
        selectedDatesOrder[0].format('YYYY-MM-DD'),
        selectedDatesOrder[1].format('YYYY-MM-DD'),
        dptoSelected,
        motoId,
        `${companyGlobal.id}`,
        transpSelected,
        dispatchSelected,
      );
    }

    setMotoSelected(motoId);
  };

  const handleChangeDepartment = (name: string) => {
    if (
      (companyGlobal && transpSelected !== 'ALL' && isComboxTransp === true) ||
      (isComboxTransp === false && companyGlobal)
    ) {
      getOrdersToReturn(
        selectedDatesOrder[0].format('YYYY-MM-DD'),
        selectedDatesOrder[1].format('YYYY-MM-DD'),
        name,
        motoSelected,
        `${companyGlobal.id}`,
        transpSelected,
        dispatchSelected,
      );
    }

    setDptoSelected(name);
  };

  const handleCalendarChangeOrder = (dates: any, dateStrings: any) => {
    if (
      (companyGlobal && transpSelected !== 'ALL' && isComboxTransp === true) ||
      (isComboxTransp === false && companyGlobal)
    ) {
      getOrdersToReturn(
        dates[0].format('YYYY-MM-DD'),
        dates[1].format('YYYY-MM-DD'),
        dptoSelected,
        motoSelected,
        `${companyGlobal.id}`,
        transpSelected,
        dispatchSelected,
      );
    }

    setSelectedDatesOrder(dates);
  };

  const handleChangeTransporter = (id: string) => {
    if (companyGlobal) {
      getOrdersToReturn(
        selectedDatesOrder[0].format('YYYY-MM-DD'),
        selectedDatesOrder[1].format('YYYY-MM-DD'),
        dptoSelected,
        motoSelected,
        `${companyGlobal.id}`,
        id,
        dispatchSelected,
      );
    }

    setTranspSelected(id);
  };

  const handleStatusDispatchChange = (dispatchStatus: string) => {
    if (
      (companyGlobal && transpSelected !== 'ALL' && isComboxTransp === true) ||
      (isComboxTransp === false && companyGlobal)
    ) {
      getOrdersToReturn(
        selectedDatesOrder[0].format('YYYY-MM-DD'),
        selectedDatesOrder[1].format('YYYY-MM-DD'),
        dptoSelected,
        motoSelected,
        `${companyGlobal.id}`,
        transpSelected,
        dispatchStatus,
      );
    }

    setDispatchSelected(dispatchStatus);
  };

  return (
    <>
      <PageHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            gap: '.5rem',
            alignItems: 'center',
          }}
        >
          <h5 style={{ alignContent: 'center' }}>Tiendas</h5>

          {authUser.user.role?.permissions?.order_company_change ? (
            <CompanyChangeContainer isAll={true} />
          ) : null}

          {hiddenTransp ? (
            <></>
          ) : (
            <>
              <h5>Transportadora</h5>
              <Select
                onChange={handleChangeTransporter}
                showSearch
                optionFilterProp="key"
                loading={loadingTransporters}
                value={transpSelected}
                style={{ minWidth: '120px' }}
                filterOption={(input, option) =>
                  (option?.key as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Option key="ALL" value="ALL">
                  <Badge
                    style={{ marginRight: '.5rem' }}
                    color={'#000000'}
                    text=""
                  >
                    TODOS
                  </Badge>
                </Option>
                {transporters?.map((key) => (
                  <Option key={key.name} value={key.id}>
                    {key.name}
                  </Option>
                ))}
              </Select>
            </>
          )}

          <h5 style={{ alignContent: 'center' }}>Fechas</h5>

          <RangePicker
            format={dateFormat}
            defaultValue={[dayjs(subDays(new Date(), 30)), dayjs(new Date())]}
            onCalendarChange={handleCalendarChangeOrder}
            clearIcon={false}
          />

          <h5 style={{ alignContent: 'center' }}>Motorizado</h5>

          <Select
            placeholder="Motorizado"
            value={motoSelected}
            onChange={handleChangeMotorized}
            loading={loadingMt}
            style={{ display: 'flex', width: '200px' }}
            showSearch
            optionFilterProp="key"
            filterOption={(input, option) =>
              (option?.key as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Option key={'ALL'} value={'ALL'}>
              TODOS
            </Option>
            {motorizeds.map((c) => (
              <Option key={c.email} value={`${c.email}`} label={c.email}>
                {c.email}
              </Option>
            ))}
          </Select>

          <h5>Estado despacho</h5>

          <Select
            placeholder="Estado de despacho"
            style={{ minWidth: '150px' }}
            onChange={handleStatusDispatchChange}
            value={dispatchSelected}
          >
            <Option key="ALL" value="ALL">
              TODOS
            </Option>

            <Option
              key={ORDER_DISPATCH_STATUS_LIST.PICKED}
              value={ORDER_DISPATCH_STATUS_LIST.PICKED}
            >
              {
                ORDER_DISPATCH_STATUS_LABEL[ORDER_DISPATCH_STATUS_LIST.PICKED]
                  .label
              }
            </Option>

            <Option
              key={ORDER_DISPATCH_STATUS_LIST.STORE_CENTRAL}
              value={ORDER_DISPATCH_STATUS_LIST.STORE_CENTRAL}
            >
              {
                ORDER_DISPATCH_STATUS_LABEL[
                  ORDER_DISPATCH_STATUS_LIST.STORE_CENTRAL
                ].label
              }
            </Option>

            <Option
              key={ORDER_DISPATCH_STATUS_LIST.TO_RETURN}
              value={ORDER_DISPATCH_STATUS_LIST.TO_RETURN}
            >
              {
                ORDER_DISPATCH_STATUS_LABEL[
                  ORDER_DISPATCH_STATUS_LIST.TO_RETURN
                ].label
              }
            </Option>

            <Option
              key={ORDER_DISPATCH_STATUS_LIST.AVAILABLE}
              value={ORDER_DISPATCH_STATUS_LIST.AVAILABLE}
            >
              {
                ORDER_DISPATCH_STATUS_LABEL[
                  ORDER_DISPATCH_STATUS_LIST.AVAILABLE
                ].label
              }
            </Option>
          </Select>

          <h5 style={{ alignContent: 'center' }}>
            {authUser.user.company.countryCode === 'MEX' ? 'Estado' : 'Departamento'}
          </h5>

          <Select
            onChange={handleChangeDepartment}
            showSearch
            optionFilterProp="key"
            loading={loadingGeo1}
            value={dptoSelected}
            style={{ minWidth: '120px' }}
            filterOption={(input, option) =>
              (option?.key as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Option key="ALL" value="ALL">
              TODOS
            </Option>
            {locationsGeolevel1?.map((key) => (
              <Option key={key.name} value={key.name}>
                {key.name}
              </Option>
            ))}
          </Select>
        </div>
      </PageHeader>

      <OrdersMotorizedSummary ordersMotorized={ordersMotorized} />

      <OrdersTable orders={orders} loading={loading} />
    </>
  );
};

export default OrdersToReturnContainer;
