import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Popover,
  Select,
  TimePicker,
  Typography,
} from 'antd';
import { IWarehouseCreateSchema } from '../../../../../services/warehouse-create/warehouse-create.schema';
import { useWarehouseStore } from '../../../../../services/warehouse-create/warehouse.store';
import { WarehouseLocationPart } from './parts';
import { useUsersList } from '../../../../../services/user-list/user-list.service';
import { useCompanyStore } from '../../../../../services/company-create/company-create.service';
import TextArea from 'antd/lib/input/TextArea';
import { BANK_LIST } from '../../../../../config/data.params';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { useTransporterList } from '../../../../../services/transporter-list/transporter-list.service';
import dayjs, { Dayjs } from 'dayjs';

interface IWarehouseEditFormProps {
  onSubmit: (data: IWarehouseCreateSchema) => void;
}

const { Title } = Typography;
const { Option } = Select;

const WarehouseEditForm: FC<IWarehouseEditFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<IWarehouseCreateSchema>();
  const { warehouseSelected } = useWarehouseStore();
  const { getStores } = useUsersList();
  const { companyGlobal } = useCompanyStore();
  const { getTransporters, loadingTransporters, transporters } =
    useTransporterList();
  const { authUser } = useAuthStore();
  const [enableForm, setEnableForm] = useState<boolean>(false);
  const [value, setValue] = useState<Dayjs | null>(dayjs(new Date()));

  useEffect(() => {
    getTransporters();

    if (warehouseSelected) {
      if (authUser.user.role.name === 'STORE') {
        setEnableForm(true);
      } else {
        setEnableForm(false);
      }

      form.setFieldsValue({
        id: warehouseSelected.id,
        name: warehouseSelected.name,
        userId: warehouseSelected.userId,
        reference: warehouseSelected.reference,
        phone: warehouseSelected.phone,
        cci: warehouseSelected.cci,
        accountNumber: warehouseSelected.accountNumber,
        codeBank: warehouseSelected.codeBank,
        holderName: warehouseSelected.holderName,
        typeWarehouse: warehouseSelected.typeWarehouse,
        businessName: warehouseSelected.businessName,
        businessDocument: warehouseSelected.businessDocument,
        emailTransfer: warehouseSelected.emailTransfer,
        documentHolderAccount: warehouseSelected.documentHolderAccount,
        deadlineTimeDelivery: warehouseSelected.deadlineTimeDelivery
          ? dayjs(warehouseSelected.deadlineTimeDelivery)
          : '',
        deadLineTimeSecundary: warehouseSelected.deadLineTimeSecundary
          ? dayjs(warehouseSelected.deadLineTimeSecundary)
          : '',
        deadlineTimeAgency: warehouseSelected.deadlineTimeAgency
          ? dayjs(warehouseSelected.deadlineTimeAgency)
          : '',
        shalomOriginIn: warehouseSelected.shalomOriginIn,
        isWarPublic: warehouseSelected.isPublic,
        transporterIds:
          warehouseSelected.warehouseTransporters &&
          warehouseSelected.warehouseTransporters.length > 0
            ? warehouseSelected.warehouseTransporters.map(
                (wt) => wt.transporterId,
              )
            : [],
      });
    }
  }, [warehouseSelected]);

  const content = (
    <div>
      <h4>Cuenta soles</h4>
      <p>N° de cuenta: 47505215317055</p>
      <p>CCI: 00247510521531705520</p>
    </div>
  );

  useEffect(() => {
    if (companyGlobal) {
      getStores(companyGlobal ? `${companyGlobal.id}` : '');
    }
  }, [companyGlobal]);

  const onChange = (time: any) => {
    setValue(time);
  };

  return (
    <>
      <Form
        layout="horizontal"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 17 }}
        form={form}
        name="UserForm"
        labelWrap
        onFinish={onSubmit}
      >
        <Form.Item hidden name={['id']}>
          <Input />
        </Form.Item>

        <Title level={5}>Datos de almacén</Title>

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>Nombre Almacén</span>}
          name={['name']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Input
            style={{
              display: 'inline-block',
              width: '100%',
              borderRadius: '8px',
            }}
            disabled={enableForm}
          />
        </Form.Item>

        <Form.Item
          label={
            <span style={{ fontSize: '13px' }}>Telf. Soporte Almacén</span>
          }
          name={['phone']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <InputNumber
            controls={false}
            maxLength={authUser.user.company.countryCode === 'MEX' ? 13 : 11}
            style={{
              display: 'inline-block',
              width: '100%',
              borderRadius: '8px',
            }}
          />
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>¿Es público?</span>}
          name={['isWarPublic']}
          valuePropName="checked"
        >
          <Checkbox disabled={enableForm} />
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>Tipo almacén</span>}
          name={['typeWarehouse']}
          rules={[{ required: false, message: 'Obligatorio' }]}
        >
          <Select placeholder="Seleccione una opción" disabled={enableForm}>
            <Option value="NORMAL">NORMAL</Option>
            <Option value="PRINCIPAL">PRINCIPAL</Option>
          </Select>
        </Form.Item>

        <Title level={5}>Contraentrega</Title>

        <Form.Item
          label={
            <span style={{ fontSize: '13px' }}>
              Hora de cierre principal (24h)
            </span>
          }
          name={['deadlineTimeDelivery']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <TimePicker
            disabled={enableForm}
            format={'HH:mm'}
            style={{
              display: 'inline-block',
              width: '100%',
              borderRadius: '8px',
            }}
          />
        </Form.Item>

        <Form.Item
          label={
            <span style={{ fontSize: '13px' }}>
              Hora de cierre secundario (24h)
            </span>
          }
          name={['deadLineTimeSecundary']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <TimePicker
            disabled={enableForm}
            format={'HH:mm'}
            style={{
              display: 'inline-block',
              width: '100%',
              borderRadius: '8px',
            }}
          />
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>Contraentrega en</span>}
          name={['reference']}
        >
          <TextArea
            disabled={enableForm}
            style={{
              display: 'inline-block',
              width: '100%',
              borderRadius: '8px',
            }}
          />
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>Transportadoras</span>}
          name={['transporterIds']}
        >
          <Select
            placeholder="Seleccione una(s) transportadora(s)"
            style={{ minWidth: '250px' }}
            mode="multiple"
            allowClear
            disabled={enableForm}
            optionFilterProp="label"
            filterOption={(input, option) =>
              (option?.label as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            loading={loadingTransporters}
            maxTagCount="responsive"
          >
            {transporters?.map((key) => (
              <Option key={key.id} value={key.id} label={key.name}>
                {key.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Title level={5}>Agencia</Title>

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>Hora de cierre (24h)</span>}
          name={['deadlineTimeAgency']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <TimePicker
            value={value}
            onChange={onChange}
            disabled={enableForm}
            format={'HH:mm'}
            style={{
              display: 'inline-block',
              width: '100%',
              borderRadius: '8px',
            }}
          />
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>Shalom en</span>}
          name={['shalomOriginIn']}
        >
          <TextArea
            disabled={enableForm}
            style={{
              display: 'inline-block',
              width: '100%',
              borderRadius: '8px',
            }}
          />
        </Form.Item>

        <Title level={5}>Datos bancarios</Title>

        <Form.Item label="Banco" name={['codeBank']} required={true}>
          <Select
            placeholder="Seleccione una entidad bancaria"
            allowClear
            options={BANK_LIST.map((e) => ({
              label: `${e.value} / ${e.code}`,
              value: e.code,
            }))}
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              <div>
                Número de cuenta{' '}
                <Popover content={content} title="Cuentas">
                  <QuestionCircleOutlined />
                </Popover>
              </div>
            </span>
          }
          name={['accountNumber']}
          required={true}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label={
            <span>
              <div>
                Número de cuenta interbancaria (CCI){' '}
                <Popover content={content} title="Cuentas">
                  <QuestionCircleOutlined />
                </Popover>
              </div>
            </span>
          }
          name={['cci']}
          required={true}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="Nombre completo del titular"
          name={['holderName']}
          required={true}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="Correo para constancia transferencia"
          name={['emailTransfer']}
          required={true}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="RUC/DNI titular de la cuenta"
          name={['documentHolderAccount']}
          required={true}
        >
          <Input type="text" />
        </Form.Item>

        <Title level={5}>Datos para facturación</Title>

        <Form.Item
          label="RUC (DNI si no cuentas RUC)"
          name={['businessDocument']}
          required={true}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item label="Razón social" name={['businessName']} required={true}>
          <Input type="text" />
        </Form.Item>
          
        <Title level={5}>Datos ubicación</Title>
        <WarehouseLocationPart form={form} warSelect={warehouseSelected}/>
          
        <Divider />

        <Button type="primary" htmlType="submit">
          Guardar
        </Button>
      </Form>
    </>
  );
};

export default WarehouseEditForm;
