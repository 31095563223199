import React, { FC } from 'react';
import { Spin } from 'antd';
import { useValidateStoreAccess } from '../hooks/use-validate-store-access';
import { ICompanyItemResponse } from '../../../services/company-list/company-list.interface';
import { useUpdateCompany } from '../../company/hooks';
import { PerflayIntegrationForm } from '../forms/perflay-integration-form';
import { useTokenPerflay } from '../../../services/company-create/company-create.service';

type PerflayConnectContainerProps = {
  company: ICompanyItemResponse;
};

export const PerflayConnectContainer: FC<PerflayConnectContainerProps> = ({ company }) => {
  const { loading: isLoadingValidateStoreAccess } = useValidateStoreAccess();
  const { loading: isLoadingUpdateCompany } = useUpdateCompany();

  // Obtener el token de integración
  const { tokenData, loading: isLoadingToken } = useTokenPerflay(company.id?.toString());

  const isLoading = isLoadingValidateStoreAccess || isLoadingUpdateCompany || isLoadingToken;

  // crear objeto con los valores iniciales del formulario
  const initialFormValues = {
    ...company, // se mantiene values de otro form
    tokenIntegration: tokenData?.tokenIntegration || '', // pasar token si está disponible
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <PerflayIntegrationForm defaultValues={initialFormValues} />
      )}
    </>
  );
};
