import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { DashboardWelcome } from '../../components'
import { PageHeader } from '../../../../components'
import { CompanyChangeContainer } from '../../../../containers'
import { useAuthStore } from '../../../../services/auth/auth.service'
import { useCompanyStore } from '../../../../services/company-create/company-create.service'
import { IDashboardUrlResponse } from '../../../../services/company-list/company-list.interface'
import { useCompanyHasOrder } from '../../../../services/company-has-order-delivery/company-order.service'

const DashboardContainer = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { getCompanyHasOrder, hasOrder } = useCompanyHasOrder()
  const [dashboardUrls, setDashboardUrls] = useState<IDashboardUrlResponse[]>()
  const [tabs, setTabs] = useState<TabsProps['items']>()
  const url = 'https://lookerstudio.google.com/embed/reporting/711a60d5-3aaf-489f-a0a6-b97c82d44bb7/'
    .concat('page/p_j8zthbh45c?params=%7B%22df29%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580')

  const urlMyComunity = 'https://lookerstudio.google.com/embed/reporting/515e7d3f-14a0-4c14-b426-ae01fdfe645c/'
    .concat('page/59fJD?params=%7B%22df17%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580')
  /*
  const urlSales = 'https://lookerstudio.google.com/embed/reporting/47691dfc-72c6-42eb-a634-48cec0200a83/'
    .concat('page/59fJD?params=%7B"df5":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580')
  */
  /*
  const urlDropshipping = 'https://lookerstudio.google.com/embed/reporting/ad014b68-86ad-4ec9-9506-a4d371ed7bc9/'
    .concat('page/p_5cmhcdl95c?params={"df97":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580')
  const urlDropPart = '","df70":"ORexclude%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%258020230831%25EE%2580%2580'
    .concat('20230830%25EE%2580%258020230829%25EE%2580%2581include%25EE%2580%25803%25EE%2580%2580NU%25EE%2580%2582"}')
  */
  const onChange = (_: string) => {
    //console.log(key)
  }

  useEffect(() => {
    getCompanyHasOrder(companyGlobal ? companyGlobal.id : authUser.user.company.id)
  }, [companyGlobal])

  useEffect(() => {
    if (hasOrder) {
      if (companyGlobal) {
        const urls = [{
          id: 0,
          title: 'AliNews',
          description: 'string',
          position: 0,
          url: '',
        }, ...(companyGlobal.setting.dashboardUrls || [])]

        if (hasOrder.hasOrderDelivery === true) {
          urls.push({
            id: 0,
            title: 'AliWallet',
            description: 'string',
            position: 10,
            url: url.concat(companyGlobal.name).concat('%22%7D'),
          })

          /*
          urls.push({
            id: 1,
            title: 'Mis Ventas',
            description: 'string',
            position: 1,
            url: urlSales.concat(companyGlobal.name).concat('%22%7D'),
          })
          */
        }

        if (companyGlobal.totalAffiliates > 0) {
          urls.push({
            id: 0,
            title: 'Mi Comunidad',
            description: 'string',
            position: 11,
            url: urlMyComunity.concat(companyGlobal.name).concat('%22%7D'),
          })
        }

        /*if (hasOrder.hasDrop === true) {
          urls.push({
            id: 1,
            title: 'Dropshipping',
            position: 11,
            url: urlDropshipping.concat(companyGlobal.name).concat(urlDropPart),
          })
        }*/

        setDashboardUrls(urls)

      } else {
        const urls = [{
          id: 0,
          title: 'AliNews',
          description: 'string',
          position: 0,
          url: '',
        }, ...authUser.user.company.setting.dashboardUrls]

        if (hasOrder.hasOrderDelivery === true) {
          urls.push({
            id: 0,
            title: 'AliWallet',
            description: 'string',
            position: 10,
            url: url.concat(authUser.user.company.name).concat('%22%7D'),
          })

          /*
          urls.push({
            id: 1,
            title: 'Mis Ventas',
            description: 'string',
            position: 1,
            url: urlSales.concat(authUser.user.company.name).concat('%22%7D'),
          })
          */
        }

        if (authUser.user.company.totalAffiliates > 0) {
          urls.push({
            id: 0,
            title: 'Mi Comunidad',
            description: 'string',
            position: 11,
            url: urlMyComunity.concat(authUser.user.company.name).concat('%22%7D'),
          })
        }

        /*if (hasOrder.hasDrop === true) {
          urls.push({
            id: 1,
            title: 'Dropshipping',
            position: 11,
            url: urlDropshipping.concat(authUser.user.company.name).concat(urlDropPart),
          })
        }*/

        setDashboardUrls(urls)
      }
    } else {
      setDashboardUrls([])
    }
  }, [hasOrder])

  useEffect(() => {
    const newTabs = dashboardUrls?.map(d => {
      const tab: TabsProps = {
        key: d.position,
        label: d.title,
        children: <>{ d.position === 0 ?
          <>
            <iframe loading="lazy" width="100%" height="580"
              src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFs-3tNQm0&#x2F;view?embed" allow="fullscreen">
            </iframe>
          </> : <>
            <div>
              <iframe width="100%" height="580" style={{ border:0 }}
                src={d.url} 
              ></iframe>
            </div>
          </>
        }
        </>
      } as TabsProps

      return tab
    }) as TabsProps['items']
    
    setTabs(newTabs)
  }, [dashboardUrls])

  return <>
    <PageHeader>
      <div
        style={{
          display: 'flex', justifyContent: 'space-between', gap: '.5rem', alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
          {authUser.user.role?.permissions?.order_company_change && authUser.user.company.countryCode === 'PER' ? (
            <CompanyChangeContainer isAll={false} />
          ) : null}
        </div>
      </div> 
    </PageHeader>

    { dashboardUrls && dashboardUrls?.length > 0 ? <>
      <div>
        <Tabs
          style={{ marginInlineStart: 8, marginInlineEnd: 8 }}
          defaultActiveKey="1" 
          items={tabs} onChange={onChange}/>
      </div>
    </> : <>
      <DashboardWelcome/>
    </> }
  </>
}

export default DashboardContainer
