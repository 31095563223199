import { useDispatch, useSelector } from 'react-redux';
import { useAxios } from '../../hooks';
import { IRootState } from '../../state';
import { companyActions } from '../../state/company/company.actions';
import { ICompanyState } from '../../state/company/company.reducer';
import { ICompanyItemResponse } from '../company-list/company-list.interface';
import {
  ICompanyCreateRequest,
  ICompanyCreateResponse,
  ITokenIntegrationResponse,
} from './company-create.interface';
import { useAuthStore } from '../auth/auth.service';
import { useEffect } from 'react';

export const useCompanyCreate = () => {
  const { authUser } = useAuthStore();
  const { execute, response, loading } = useAxios<ICompanyCreateResponse>();

  const saveCompany = (data: ICompanyCreateRequest) => {
    const method = data.id ? 'PATCH' : 'POST';
    const url = data.id
      ? `/maintenance/company/${data.id}`
      : '/maintenance/company';
    const temp = Object.assign({}, data);

    (temp.countryCode = authUser.user.company.countryCode),
      (temp.parentId =
        temp.type === 'NORMAL' ? authUser.user.company.parentId : undefined);

    execute({
      method,
      url,
      data: temp,
    });
  };

  return {
    saveCompany,
    loading,
    company: response,
  };
};

export const useCompanyDelete = () => {
  const { execute, response, loading } = useAxios<ICompanyCreateResponse>();
  const deleteCompany = (companyId: number) => {
    const method = 'PATCH';
    const url = `/maintenance/company/delete/${companyId}`;

    execute({
      method,
      url,
    });
  };

  return {
    deleteCompany,
    loading,
    companyDeleted: response,
  };
};

export const useCompanyDetail = () => {
  const { execute, response, loading } = useAxios<ICompanyItemResponse>();
  const getCompany = (companyId: number) => {
    const method = 'GET';
    const url = `/maintenance/company/${companyId}`;

    execute({
      method,
      url,
    });
  };

  return {
    getCompany,
    loading,
    companyDetail: response,
  };
};


export const useTokenPerflay = (companyId?: string) => {
  const { execute, response, loading } = useAxios<ITokenIntegrationResponse>();

  useEffect(() => {
    if (companyId) {
      execute({
        method: 'GET',
        url: `/auth/token-integration/${companyId}`,
      });
    }
  }, [companyId]);
  
  return {
    tokenData: response,
    loading,
  };
};

export const useCompanyStore = () => {
  const dispatch = useDispatch();
  const { companySelected, companyUpdated, companyGlobal } = useSelector(
    (state: IRootState) => state.company as ICompanyState,
  );

  const setCompanySelected = (data: ICompanyItemResponse | null) => {
    dispatch(companyActions.setCompanySelected(data));
  };

  const setCompanyUpdated = (data: ICompanyCreateResponse) => {
    dispatch(companyActions.setCompanyUpdated(data));
  };

  const setCompanyGlobal = (company: ICompanyItemResponse | null) => {
    dispatch(companyActions.setCompanyGlobal(company));
  };

  return {
    setCompanySelected,
    setCompanyUpdated,
    setCompanyGlobal,
    companySelected,
    companyUpdated,
    companyGlobal,
  };
};
