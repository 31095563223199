import { useAxios } from '../../../hooks';
import { TShopifyShop } from '../types';

export const useValidateStoreAccess = () => {
  const { execute, response, loading } = useAxios<TShopifyShop>();

  const validateStoreAccess = (args?: {
    storeName: string;
    accessToken: string;
  }) => {
    execute({
      method: 'POST',
      url: '/shopify-api/validate-store-access',
      data: args,
    });
  };

  return {
    validateStoreAccess,
    loading,
    shop: response,
  };
};
