import React, { FC, useEffect } from 'react';
import { Button, Flex, Form, Input, Select } from 'antd';
import { ICompanyItemResponse } from '../../../services/company-list/company-list.interface';

export interface IShopifyConnectSchema {
  typeImport: string;
  storeShopify: string;
  accessTokenShopify: string;
}

interface IShopifyIntegrationFormProps {
  defaultValues?: ICompanyItemResponse;
  onSubmit: (data: IShopifyConnectSchema) => void;
  onTestConection: (data: IShopifyConnectSchema) => void;
}

export const ShopifyIntegrationForm: FC<IShopifyIntegrationFormProps> = ({
  defaultValues, onSubmit, onTestConection }) => {
    
  const [form] = Form.useForm<IShopifyConnectSchema>();

  const handleTestConection = () => {
    form.validateFields().then(() => {
      onTestConection(form.getFieldsValue());
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      typeImport: 'WEBHOOK',
      accessTokenShopify: defaultValues?.accessTokenShopify,
      storeShopify: defaultValues?.storeShopify
    });
  }, []);

  return (
    <>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item
          name={['typeImport']}
          label="Tipo de importación"
          rules={[{ required: true, message: '' }]}
        >
          <Select disabled>
            <Select.Option value="WEBHOOK">WEBHOOK</Select.Option>
            {/*<Select.Option value="FIREBASE">FIREBASE</Select.Option>
            <Select.Option value="MYSQL_BD">MYSQL_BD</Select.Option>*/}
          </Select>
        </Form.Item>

        <Form.Item
          name={['storeShopify']}
          label="Dominio de la tienda"
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name={['accessTokenShopify']} label="Access Token" rules={[{ message: '' }]}>
          <Input />
        </Form.Item>

        <Flex align="center" justify="end" gap={10}>
          <Button htmlType="button" onClick={handleTestConection}>
            Probar conexión
          </Button>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Flex>
      </Form>
    </>
  );
};
