/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import './Testimonials.css';
import { ModalVideo } from '../ModalVideo';
// import { CheckIcon } from '../../../../components/Icons';
import useScrollAnimation from '../../hook/useScrollAnimation';
import shopedi from '../../../../assets/images/shpedi.png'
import stokealo from '../../../../assets/images/stockealo.jpeg'
import alfalion from '../../../../assets/images/alfalion.png'
import alilogistic from '../../../../assets/images/alilogistic.png'

export const Testimonials = () => {
  const [openModal, setOpenModal] = useState(false);
  const [linkVideo, setLinkVideo] = useState('');
  const { ref, isVisible } = useScrollAnimation();

  const handleOpenModal = (link: string) => {
    setLinkVideo(link);
    setTimeout(() => {
      setOpenModal(true);
    }, 0);
  };

  return (
    <>
      <div className="testimonials" id="testimonials" ref={ref}>
        <div className={`testimonials__wrapper ${isVisible ? 'appear' : ''}`}>
          <h2 className={`title__landing ${isVisible ? 'appear' : ''} `}>Partners y Casos de éxito</h2>
          <div className="testimonials__cards">
              <div className={`testimonials__box ${isVisible ? 'appear' : ''}`}>
                <p className="testimonials__paragraph">
                  “Gracias a esta alianza hemos pasado de procesos manuales a un sistema más automatizado y eficiente”
                </p>
                <div className="testimonials__box-avatar">
                  <img
                    src={alilogistic}
                    alt="testimonio"
                    loading="lazy"
                    height={70}
                  />
                  <div className="testimonials__box-video">
                    <h5 className="testimonials__box-title-video">
                      Alilogistic Perú
                    </h5>
                    <button
                      className="testimonials__box-link-video"
                      onClick={() => {
                        handleOpenModal(
                          'https://www.youtube.com/embed/xeFA23Br6P4?si=A6y5_hm-NIGe1hf',
                        );
                      }}
                    >
                      Ver video
                    </button>
                  </div>
                </div>
              </div>
              <div className={`testimonials__box ${isVisible ? 'appear' : ''}`}>
                <p className="testimonials__paragraph">
                  “Simplificar las operaciones logísticas de las tiendas Ecommerce, A través del servicio de almacenamiento, fullfilment y despacho”
                </p>
                <div className="testimonials__box-avatar">
                  <img
                    src={stokealo}
                    alt="testimonio"
                    loading="lazy"
                    height={70}
                  />
                  <div className="testimonials__box-video">
                    <h5 className="testimonials__box-title-video">
                      Stockealo Logistica
                    </h5>
                    <button
                      className="testimonials__box-link-video"
                      onClick={() => {
                        handleOpenModal(
                          'https://www.youtube.com/embed/91ETQ-fIL3w?si=A6y5_hm-NIGe1hf',
                        );
                      }}
                    >
                      Ver video
                    </button>
                  </div>
                </div>
            </div>
              <div className={`testimonials__box ${isVisible ? 'appear' : ''}`}>
                <p className="testimonials__paragraph">
                  “Plataforma confiable que maneja la logística de nuestros productos, facilitando cada paso de Ecommerce desde selecciones hasta la entrega final”
                </p>
                <div className="testimonials__box-avatar">
                  <img
                    src={alfalion}
                    alt="testimonio"
                    loading="lazy"
                    height={70}
                  />
                  <div className="testimonials__box-video">
                    <h5 className="testimonials__box-title-video">
                      Alfalion Investment
                    </h5>
                    <button
                      className="testimonials__box-link-video"
                      onClick={() => {
                        handleOpenModal(
                          'https://www.youtube.com/embed/2TWZwKt2HrQ?si=A6y5_hm-NIGe1hf',
                        );
                      }}
                    >
                      Ver video
                    </button>
                  </div>
                </div>
              </div>
              <div className={`testimonials__box ${isVisible ? 'appear' : ''}`}>
                <p className="testimonials__paragraph">
                  “Vimos la gran oportunidad de conectar nuestro almacén con toda la logística que necesitas para crecer tu Ecommerce y nos dieron la confianza de poder ayudar a otras personas”
                </p>
                <div className="testimonials__box-avatar">
                  <img
                    src={shopedi}
                    alt="testimonio"
                    loading="lazy"
                    height={70}
                  />
                  <div className="testimonials__box-video">
                    <h5 className="testimonials__box-title-video">
                      Shoppedi2
                    </h5>
                    <button
                      className="testimonials__box-link-video"
                      onClick={() => {
                        handleOpenModal(
                          'https://www.youtube.com/embed/Oltf3JaQ9Nw?si=A6y5_hm-NIGe1hf',
                        );
                      }}
                    >
                      Ver video
                    </button>
                  </div>
                </div>
            </div>
          </div>
        </div>
        {/* <div className={`testimonials__wrapper ${isVisible ? 'appear' : ''}`} ref={ref}>
          <h2 className={`title__landing ${isVisible ? 'appear' : ''} `}>
            ¿Por qué Aliclik es tu mejor opción?
          </h2>
          <div className="testimonials__wrapper-comparative">
            <div className="testimonials__comparative">
              <div className="testimonials__title-comparative">Aliclik</div>
              <ul className="testimonials__title-list">
                <li className="testimonials__title-item">
                  <CheckIcon fill="#8e8edc" />
                  <span>Menor costo</span>
                </li>
                <li className="testimonials__title-item">
                  <CheckIcon fill="#8e8edc" />
                  <span>Mejor cobertura</span>
                </li>
                <li className="testimonials__title-item">
                  <CheckIcon fill="#8e8edc" />
                  <span>Mayor soporte</span>
                </li>
                <li className="testimonials__title-item">
                  <CheckIcon fill="#8e8edc" />
                  <span>Mayor integración</span>
                </li>
              </ul>
            </div>
            <div className="testimonials__comparative  testimonials__comparative--other">
              <div className="testimonials__title-comparative">
                Otras Alternativas
              </div>
              <ul className="testimonials__title-list">
                <li className="testimonials__title-item">
                  <CheckIcon fill="#ff6161"/>
                  <span>Mayor costo</span>
                </li>
                <li className="testimonials__title-item">
                  <CheckIcon fill="#ff6161"/>
                  <span>Menor cobertura</span>
                </li>
                <li className="testimonials__title-item">
                  <CheckIcon fill="#ff6161"/>
                  <span>Menor soporte</span>
                </li>
                <li className="testimonials__title-item">
                  <CheckIcon fill="#ff6161"/>
                  <span>Menor integración</span>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
      {openModal && (
        <ModalVideo link={linkVideo} onClose={() => setOpenModal(false)} />
      )}
    </>
  );
};
