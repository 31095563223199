/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Badge, Button, DatePicker, Divider, Select, Typography } from 'antd';
import { downloadExcel } from 'react-export-table-to-excel';
import { useOrderListPayment } from '../../../../services/order-payment/order-payment.service';
import { useAuthStore } from '../../../../services/auth/auth.service';
import {
  PAYMENT_METHOD,
  PAYMENT_METHOD_LABEL,
} from '../../../../services/payment-create/payment.params';
import {
  CurrentStockReport,
  DispatchReport,
  DropShalomReport,
  MovementsReport,
  ObservationReport,
  OrderReport,
  ProductRemainingReport,
  ProductReport,
  StockReport,
} from '../../form';
import { useTransporterList } from '../../../../services/transporter-list/transporter-list.service';
import dayjs from 'dayjs';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const ReportContainer = () => {
  const { authUser } = useAuthStore();
  const dateFormat = 'YYYY-MM-DD';
  const { getOrdersPayment, loadingPay, orders } = useOrderListPayment();
  const [paySelected, setPaySelected] = useState<string>(PAYMENT_METHOD.E);
  const [selectedDates, setSelectedDates] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const { transporters, loadingTransporters, getTransporters } =
    useTransporterList();
  const [transpSelected, setTranspSelected] = useState<string>('ALL');
  const [hiddenTransp, setHiddenTransp] = useState(false);

  const header = [
    'FECHA ENTREGA',
    'NRO. PEDIDO',
    'MOTORIZADO',
    'TRANSPORTADORA',
    'MÉTODO PAGO',
    'TOTAL',
    'TOTAL PAGADO',
    'LINK PAGO',
    'FECHA PAGO',
  ];

  function handleDownloadExcel() {
    downloadExcel({
      fileName: `order-pay-${selectedDates[0].format('YYYY-MM-DD')}-to-${selectedDates[1].format('YYYY-MM-DD')}`,
      sheet: 'react-export-table-to-excel',
      tablePayload: {
        header,
        // accept two different data structures
        body: orders.map((o) => {
          return {
            delivaryDate: o.deliveryDate,
            orderNumber: o.orderNumber,
            assignedMotorized: o.assignedMotorized,
            transporterName: o.transporterName,
            paymentMethod: o.paymentMethod,
            total: o.total,
            totalPay: o.totalPay,
            payLink: o.payLink,
            paymentDate: o.paymentDate,
          };
        }),
      },
    });
  }

  const handleCalendarChange = (dates: any, dateStrings: any) => {
    setSelectedDates(dates);
  };

  useEffect(() => {
    if (
      selectedDates.length === 2 &&
      selectedDates[0] &&
      selectedDates[1] &&
      (authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'SUPER_MASTER' ||
        authUser.user.role.name === 'MASTER')
    ) {
      getOrdersPayment(
        selectedDates[0].format('YYYY-MM-DD'),
        selectedDates[1].format('YYYY-MM-DD'),
        paySelected,
        authUser.user.company.countryCode,
        transpSelected,
      );
    }
  }, [selectedDates]);

  const handleChangePay = (pay: string) => {
    if (
      authUser.user.role.name === 'ADMIN' ||
      authUser.user.role.name === 'SUPER_MASTER' ||
      authUser.user.role.name === 'MASTER'
    ) {
      getOrdersPayment(
        selectedDates[0].format('YYYY-MM-DD'),
        selectedDates[1].format('YYYY-MM-DD'),
        pay,
        authUser.user.company.countryCode,
        transpSelected,
      );
    }

    setPaySelected(pay);
  };

  useEffect(() => {
    if (authUser.user.role.name === 'SUPER_MASTER') {
      getTransporters();
      setHiddenTransp(false);
    } else if (
      authUser.user.role.name === 'ADMIN' ||
      authUser.user.role.name === 'MASTER'
    ) {
      setHiddenTransp(true);
      setTranspSelected(`${authUser.user.conveyor?.id}`);
    }
  }, []);

  const handleChangeTransporter = (id: string) => {
    if (
      authUser.user.role.name === 'ADMIN' ||
      authUser.user.role.name === 'SUPER_MASTER' ||
      authUser.user.role.name === 'MASTER'
    ) {
      getOrdersPayment(
        selectedDates[0].format('YYYY-MM-DD'),
        selectedDates[1].format('YYYY-MM-DD'),
        paySelected,
        authUser.user.company.countryCode,
        id,
      );
    }

    setTranspSelected(id);
  };

  return (
    <>
      <div>
        {authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'SUPER_MASTER' ||
        authUser.user.role.name === 'MASTER' ? (
          <>
            <Divider />

            <Title
              level={5}
              style={{
                marginBottom: '0',
                alignContent: 'center',
                padding: '.0rem 1.5rem',
              }}
            >
              Reporte de pedidos por método de pago
            </Title>

            <div
              style={{
                padding: '.85rem 1.5rem',
                display: 'flex',
                gap: '1rem',
                alignItems: 'center',
              }}
            >
              {hiddenTransp ? (
                <></>
              ) : (
                <>
                  <Title level={5} style={{ marginBottom: '0' }}>
                    Transportadora
                  </Title>
                  <Select
                    onChange={handleChangeTransporter}
                    showSearch
                    optionFilterProp="key"
                    loading={loadingTransporters}
                    value={transpSelected}
                    style={{ minWidth: '120px' }}
                    filterOption={(input, option) =>
                      (option?.key as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Option key="ALL" value="ALL">
                      <Badge
                        style={{ marginRight: '.5rem' }}
                        color={'#000000'}
                        text=""
                      >
                        TODOS
                      </Badge>
                    </Option>
                    {transporters?.map((key) => (
                      <Option key={key.name} value={key.id}>
                        {key.name}
                      </Option>
                    ))}
                  </Select>
                </>
              )}

              <Title level={5} style={{ marginBottom: '0' }}>
                Seleccionar fecha
              </Title>

              <RangePicker
                format={dateFormat}
                defaultValue={[dayjs(new Date()), dayjs(new Date())]}
                onCalendarChange={handleCalendarChange}
                clearIcon={false}
                //habilita solo fechas de calendario
                disabledDate={(current) => {
                  if (!selectedDates || selectedDates.length === 0) {
                    return false; //si no hay fechas seleccionadas no se bloquea
                  }
                  //obtener la primera fecha seleccionada
                  const [start] = selectedDates;
                  
                  if (!start) return false;

                  //Restringir la selección de más de 15 días -- si esta fuera de rango se deshabilita
                  return current.isBefore(start.subtract(15, 'days')) || current.isAfter(start.add(15, 'days'));
                }}
              />

              <Title level={5} style={{ marginBottom: '0' }}>
                Método pago
              </Title>

              <Select
                placeholder="Método de pago"
                value={paySelected}
                onChange={handleChangePay}
              >
                <Option key={'ALL'} value={'ALL'}>
                  TODOS
                </Option>
                {Object.keys(PAYMENT_METHOD_LABEL).map((key) => (
                  <Option key={key} value={key}>
                    {PAYMENT_METHOD_LABEL[key].label}
                  </Option>
                ))}
              </Select>

              <Button
                type="primary"
                onClick={handleDownloadExcel}
                loading={loadingPay}
                disabled={orders.length === 0}
              >
                Descargar reporte
              </Button>
            </div>

            <ObservationReport />
          </>
        ) : null}

        {authUser.user.role.name === 'ADMIN_STORE' ||
        authUser.user.role.name === 'SUPER_MASTER' ? (
          <>
            <DropShalomReport />
          </>
        ) : null}

        {(authUser.user.role.name === 'ADMIN_STORE' &&
          authUser.user.company.isOwnMerchandise === true) ||
        authUser.user.role.name === 'SUPER_MASTER' ? (
          <>
            <ProductReport />
          </>
        ) : null}

        {authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'ADMIN_STORE' ||
        authUser.user.role.name === 'MASTER' ||
        authUser.user.role.name === 'SUPER_MASTER' ? (
          <>
            <OrderReport />
          </>
        ) : null}

        {authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER' ||
        authUser.user.role.name === 'STORE' ||
        authUser.user.role.name === 'SUPER_MASTER' ? (
          <>
            <ProductRemainingReport />
          </>
        ) : null}

        {authUser.user.role.name === 'SUPER_MASTER' ||
        authUser.user.role.name === 'STORE' ? (
          <>
            <DispatchReport />
          </>
        ) : null}

        {authUser.user.role.name === 'SUPER_MASTER' ||
        authUser.user.role.name === 'STORE' ||
        (authUser.user.role.name === 'ADMIN_STORE' &&
          authUser.user.company.isOwnMerchandise === true) ? (
          <>
            <MovementsReport />

            <StockReport />

            <CurrentStockReport />

            <Divider />
          </>
        ) : null}
      </div>
    </>
  );
};

export default ReportContainer;
