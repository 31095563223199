import React from 'react';
import './Footer.css';
import {
  InstagramIcon,
  MailIcon,
  TikTokIcon,
} from '../../../../components/Icons';

import logo from '../../../../assets/images/logo-blue.svg';
import googlePlay from '../../../../assets/images/google.png'
export const Footer = () => {
  return (
    <footer className="footer" id="footer">
      <div className="footer-wrapper">
        <div className="footer-wrapper-logo">
          <div className="footer-logo">
            <a href="http://localhost:3000/landing">
              <img src={logo} alt="logo" height={40} />
            </a>
          </div>
          <div>
            <h6 className="footer-text">Aliclik: Tu Ecommerce a un clik</h6>
          </div>
        </div>
        <div className="footer-wrapper-social-network">
        <div className='footer-contain-social'>
          {/* Redes Sociales */}
          <div className='footer-wrapper-social'>
            <a
              href="https://www.instagram.com/aliclikapp"
              className="footer-social-network"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.tiktok.com/discover/aliclik-peru?lang=es"
              className="footer-social-network"
              target="_blank"
              rel="noreferrer"
            >
              <TikTokIcon />
            </a>
          </div>

          {/* Descarga la app */}
          <div className='footer-wrapper-app'>
            <p className="footer-app-title">Descarga nuestra APP</p>
            <a
              href="https://play.google.com/store/search?q=aliclik&c=apps&hl=es_419"
              className="footer-social-network-app"
              target="_blank"
              rel="noreferrer"
            >
              <img src={googlePlay} alt="app.png" className="footer-app-image" />
            </a>
          </div>
        </div>


          <div className="footer-wrapper-link">
            <div className='footer-wrapper-admin'>
              <MailIcon />
              administrativo@aliclik.app
            </div>
            <div className="footer-wrapper-resources">
              <p>Recursos</p>
              <ul>
                <li>
                  <a href="/terminos_y_condiciones" target="_blank" rel="noreferrer">
                    Términos y condiciones
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
