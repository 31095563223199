import { useEffect, useState } from 'react';
import { useAxios } from '../../hooks';
import { useAuthStore } from '../auth/auth.service';
import {
  IProductGlobalResponse,
  TProductCatalogItemResponse,
  TProductGlobalFiltersArgs,
} from './product-global.interface';

const useProductBestSellers = () => {
  const { authUser } = useAuthStore();
  const { execute, response, loading } = useAxios<IProductGlobalResponse>();
  const getProductsBestSellers = (args: {
    warehouseId: string;
    catalogId: string;
    companyId: string;
  }) => {
    const params = {
      countryCode: authUser.user.company.countryCode,
      warehouseId: args.warehouseId,
      parentId: authUser.user.company.parentId,
      catalogId: args.catalogId,
      companyId: args.companyId,
    };

    execute({
      method: 'GET',
      url: '/product/best-sellers',
      params: params,
    });
  };

  return {
    getProductsBestSellers,
    loadingBest: loading,
    bestSellers: response?.data || [],
  };
};

const useProductsGlobal = () => {
  const { authUser } = useAuthStore();
  const { execute, response, loading } = useAxios<IProductGlobalResponse>();
  const [products, setProducts] = useState<TProductCatalogItemResponse[]>([]);

  const getProductsGlobal = (query: TProductGlobalFiltersArgs) => {
    const params = {
      countryCode: authUser.user.company.countryCode,
      search: query.search,
      order: query.orderBy,
      priceMin: query.priceMin,
      priceMax: query.priceMax,
      categoryId: query.categoryId,
      warehouseId: query.warehouseId,
      parentId: authUser.user.company.parentId,
      catalogId: query.catalogId,
      companyId: query.companyId,
      page: query.page,
      pageSize: query.pageSize || 100,
      stock : query.stock
    };

    execute({
      method: 'GET',
      url: '/product/global',
      params,
    });
  };

  useEffect(() => {
    if (response) {
      if (response.currentPage > 1) {
        setProducts((prev) => [...prev, ...response.data]);
      } else {
        setProducts(response.data);
      }
    }
  }, [response]);

  return {
    getProductsGlobal,
    loading,
    products,
    currentPage: response?.currentPage || 1,
    totalPages: response?.totalPages || 1,
  };
};


export { useProductsGlobal, useProductBestSellers };
