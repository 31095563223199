import React from 'react';
import CountUp from 'react-countup';

export const HeroCounter = () => {
  return (
    <div className="hero-section__counter">
      <div className="hero-section__counter-wrapper">
        <CountUp start={0} end={20000} prefix="+" duration={5} className='hero-section__counter-title'/>
        <p className="hero-section__counter-description">Usuarios registrados</p>
      </div>
      <div className="hero-section__counter-wrapper">
        <CountUp start={0} end={25} prefix="+" duration={5} className='hero-section__counter-title'/>
        <p className="hero-section__counter-description">Almacenes aliados</p>
      </div>
      <div className="hero-section__counter-wrapper">
        <CountUp start={0} end={3000} prefix="+" duration={5} className='hero-section__counter-title'/>
        <p className="hero-section__counter-description">Productos</p>
      </div>
      <div className="hero-section__counter-wrapper">
        <CountUp start={0} end={25} prefix="+" duration={5} className='hero-section__counter-title'/>
        <p className="hero-section__counter-description">
          Ciudades contraentrega
        </p>
      </div>
    </div>
  );
};

export default HeroCounter;
