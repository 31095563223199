import React, { useEffect, useState } from 'react';
import { Button, Table, Tag, Tooltip } from 'antd';
import { IAuthUserResponse } from '../../../../../services/auth/auth.interface';
import { ColumnsType } from 'antd/lib/table';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { useUserStore } from '../../../../../services/user/user.service';
import useMeasureY from '../../../../../hooks/use-measurey';
import {
  AndroidFilled,
  DeleteOutlined,
  EditOutlined,
  KeyOutlined,
  WindowsFilled,
} from '@ant-design/icons';
import { useModal } from '../../../../../contexts/ModalContext';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { useDeleteUser } from '../../../../../services/user-create/user-create.service';
import ModalConfirmDelete from '../../../../../components/ComfirnModal/ModalConfirmDelete';

interface IUsersListTableProps {
  users: IAuthUserResponse[];
  loading: boolean;
  handlePagination: (page: number) => void;
  count: number;
  currentPage: number;
}

const UsersListTable: React.FC<IUsersListTableProps> = ({
  users,
  loading,
  handlePagination,
  count,
  currentPage,
}) => {
  const { windowSize } = useMeasureY();
  const { openDrawer } = useDrawer();
  const { setUserSelected, setUserUpdated } = useUserStore();
  const { openModal } = useModal();
  const { authUser, roleUser } = useAuthStore();
  const { deleteUser, userDeleted } = useDeleteUser();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<IAuthUserResponse | null>(null);

  const handleSelected = (
    code: string,
    title: string,
    data: IAuthUserResponse,
  ) => {
    setUserSelected(data);
    openDrawer(code, title);
  };

  const openModalLogout = (data: IAuthUserResponse, platform: string) => {
    data.platform = platform;
    setUserSelected(data);
    openModal('USER_LOGOUT', 'Cerrar Sesión');
  };

  const handleDeleteUser = (data: IAuthUserResponse) => {
    setUserToDelete(data);
    setModalVisible(true);
  };

  const confirmDeleteUser = () => {
    if (userToDelete) {
      deleteUser(userToDelete.id);
      setModalVisible(false);
      setUserToDelete(null);
    }
  };

  const cancelDeleteUser = () => {
    setModalVisible(false);
    setUserToDelete(null);
  };

  useEffect(() => {
    if (userDeleted) {
      setUserUpdated(userDeleted);
    }
  }, [userDeleted]);

  const verifyShowLogout = (user: IAuthUserResponse) => {
    return (
      user?.session?.length > 0 &&
      user.id !== authUser.user.id &&
      (roleUser?.name === 'ADMIN' ||
        roleUser?.name === 'MASTER' ||
        roleUser?.name === 'ADMIN_STORE' ||
        roleUser?.name === 'SUPER_MASTER' ||
        roleUser?.name === 'STORE')
    );
  };

  const verifyShowEditPassword = () => {
    return (
      roleUser?.name === 'SUPER_MASTER'
    );
  };

  const columns: ColumnsType<IAuthUserResponse> = [
    {
      title: 'Usuario',
      dataIndex: 'email',
      width: '11%',
      align: 'center',
    },
    {
      title: 'Nombres',
      dataIndex: 'fullname',
      width: 150,
      align: 'center',
    },
    {
      title: 'Telefono',
      dataIndex: 'phone',
      width: '11%',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 80,
      align: 'center',
      render: (_, user) => (
        <>
          <Tag color={user.status === 'INACTIVE' ? 'red' : 'geekblue'}>
            {user.status}
          </Tag>
        </>
      ),
    },
    {
      title: 'Rol',
      dataIndex: 'rolId',
      width: 100,
      align: 'center',
      render: (_, user) => <>{user.role.description}</>,
    },
    {
      title: 'Compañía',
      dataIndex: 'companyId',
      width: 150,
      align: 'center',
      render: (_, user) => (
        <>
          {user.company.name} ({user.company.type})
        </>
      ),
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      width: 90,
      align: 'center',
      render: (_, user) => (
        <>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Tooltip title="Editar">
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() =>
                  handleSelected('USER_EDIT', 'Editar usuario', user)
                }
              />
            </Tooltip>

            {authUser.user.role.name === 'ADMIN' ||
            authUser.user.role.name === 'MASTER' ||
            authUser.user.role.name === 'SUPER_MASTER' ? (
              <>
                <Tooltip title="Eliminar">
                  <Button
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteUser(user)}
                  />
                </Tooltip>
              </>
            ) : null}

            {verifyShowEditPassword() && (
              <Tooltip title="Cambiar Contraseña">
                <Button
                  shape="circle"
                  icon={<KeyOutlined />}
                  onClick={() =>
                    handleSelected(
                      'USER_EDIT_PASSWORD',
                      'Cambiar contraseña',
                      user,
                    )
                  }
                />
              </Tooltip>
            )}
            {verifyShowLogout(user) ? (
              <>
                {' '}
                {user.session.length &&
                user.session.find(
                  (it) => it.isSession && it.platform === 'web',
                ) ? (
                  <>
                    <Tooltip title="Cerrar Sesión Web">
                      <Button
                        shape="circle"
                        icon={<WindowsFilled />}
                        onClick={() => openModalLogout(user, 'web')}
                      />
                    </Tooltip>
                  </>
                ) : (
                  <></>
                )}
                {user.session.length &&
                user.session.find(
                  (it) => it.isSession && it.platform === 'android',
                ) ? (
                  <>
                    <Tooltip title="Cerrar Sesión Android">
                      <Button
                        shape="circle"
                        icon={<AndroidFilled />}
                        onClick={() => openModalLogout(user, 'android')}
                      />
                    </Tooltip>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : null}
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        bordered
        dataSource={users}
        columns={columns}
        rowClassName="editable-row"
        loading={loading}
        pagination={{
          pageSize: 15,
          current: currentPage,
          total: count,
          simple: true,
          onChange(page, _) {
            handlePagination(page);
          },
        }}
        scroll={{
          x: 2000,
          y: windowSize.height - 200,
        }}
        size="small"
      />

      <ModalConfirmDelete
        visible={modalVisible}
        onConfirm={confirmDeleteUser}
        onCancel={cancelDeleteUser}
        title="Eliminar usuario"
        content="¿Está seguro que desea eliminar este usuario?"      
      />
    </>
  );
};

export default UsersListTable;
