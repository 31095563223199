import React, { useEffect, useState } from 'react';
import { ActionIntersectionObserver } from '../../../../../components';
import {
  useProductBestSellers,
  useProductsGlobal,
} from '../../../../../services/product-global/product-global.service';
import { Spin, Typography } from 'antd';
import {
  TBestSellersFiltersArgs,
  TProductCatalogItemResponse,
  TProductGlobalFiltersArgs,
} from '../../../../../services/product-global/product-global.interface';
import { ProductGrid } from '../../components/ProductGrid/ProductGrid';
import { ProductDetailModal } from '../../components/ProductDetailModal/ProductDetailModal';
import { GeneralCatalogFilters } from '../GeneralCatalogFilters/GeneralCatalogFilters';

import { useVerifiedsuppliers } from '../../../../../services/order-report-created/order-report-created.service';
import { VerifiedSuppliersGrid } from '../../components/VerifiedSuppliersGrid/VerifiedSuppliersGrid';

const { Title } = Typography;

export const GeneralCatalogContainer = () => {
  const { getProductsBestSellers } =
    useProductBestSellers();
  const { getProductsGlobal, products, loading, currentPage, totalPages } =
    useProductsGlobal();
   
  const [companyDropId, setCompanyDropId] = useState<string | number>('ALL');
  const [product, setProduct] = useState<TProductCatalogItemResponse>();
  const [catalogFilters, setCatalogFilters] = useState<TProductGlobalFiltersArgs>();
  const [bestSellersFilters, setBestSellersFilters] =
    useState<TBestSellersFiltersArgs>();
  const { loadingVerifiedSuppliers, verifiedSuppliers, getVerifiedSuppliers } = useVerifiedsuppliers();
  const [selectedSupplierId, setSelectedSupplierId] = useState<string | null>(null);
  const [isLoadingSupplierProducts, setIsLoadingSupplierProducts] = useState(false);

  const handleStockChange = (stock: string | number) => {
    setCatalogFilters((prevState) => ({
      ...prevState,
      stock: stock === 'ALL' ? '' : `${stock}`,
    }));
  };
  
  useEffect(() => {
    if (catalogFilters) {
      setIsLoadingSupplierProducts(true);
      getProductsGlobal(catalogFilters);
    }
  }, [catalogFilters]);

  useEffect(() => {
    // Stop loading spinner when products are loaded
    if (products) {
      setIsLoadingSupplierProducts(false);
    }
  }, [products]);

  const handleGetSuppliers = () => {
    getVerifiedSuppliers();
  };
  
  // Llamar la función al montar el componente
  useEffect(() => {
    handleGetSuppliers();
  }, []);

  useEffect(() => {
    if (bestSellersFilters) {
      getProductsBestSellers(bestSellersFilters);
    }
  }, [bestSellersFilters]);

  useEffect(() => {
    if (selectedSupplierId) {
      setCatalogFilters((prevState) => ({
        ...prevState,
        companyId: selectedSupplierId
      }));
    }
  }, [selectedSupplierId]);

  const handleCatalogPagination = () => {
    if (catalogFilters && !loading && currentPage < totalPages) {
      getProductsGlobal({
        ...catalogFilters,
        page: currentPage + 1,
      });
    }
  };

  return (
    <>
      <GeneralCatalogFilters
        onProductGlobalFilterChange={setCatalogFilters}
        onBestSellersFilterChange={setBestSellersFilters}
        onCompanyDropChange={setCompanyDropId}
        onStockChange={handleStockChange}
      />

      <div style={{ background: '#f9f9f9' }}>
        {!!verifiedSuppliers?.length && companyDropId === 'ALL' && (
          <>
            <Title
              level={4}
              style={{
                textAlign: 'center',
                color: '#006494',
                fontWeight: 'bold',
                marginTop: 20,
                padding: '10px 20px',
                borderRadius: 8,
              }}
            >
              🌟 Proveedores Premium 🌟
            </Title>

            <VerifiedSuppliersGrid
              isLoading={loadingVerifiedSuppliers}
              products={verifiedSuppliers}
              onSupplierSelect={setSelectedSupplierId}
            />
          </>
        )}

        <Title level={4} style={{ marginLeft: 24 }}>
          Productos
        </Title>

        {isLoadingSupplierProducts ? (
          <div style={{ 
            display: 'none', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '200px' 
          }}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <ProductGrid
              isLoading={loading}
              products={products}
              onSelected={setProduct}
            />
          </>
        )}

        <ActionIntersectionObserver
          loading={loading}
          onVisible={handleCatalogPagination}
          onAction={handleCatalogPagination}
        />

        {product && (
          <ProductDetailModal
            open
            product={product}
            onAccept={() => setProduct(undefined)}
            onCancel={() => setProduct(undefined)}
          />
        )}
      </div>
    </>
  );
};
