import React, { useState } from 'react';
import './Header.css';
import { ComunityIcon, HeadPhonesIcon } from '../../../../components/Icons';
import logo from '../../../../assets/images/logo.svg';

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <div className="header__bg--blue">
        <div className="container__landing">
          <div className="header__top">
            <ul className="header__top-list">
              <li className="header__top-list-item">
                <HeadPhonesIcon />
                <a
                  href="https://aliclik.app/preguntas-frecuentes"
                  target="_blank"
                  rel="noreferrer"
                  className="header__top-link"
                >
                  Centro de ayuda
                </a>
              </li>
              <li className="header__top-list-item">
                <ComunityIcon />
                <a
                  href="https://www.whatsapp.com/channel/0029Van2G5vIXnltIwdPqN28"
                  target="_blank"
                  rel="noreferrer"
                  className="header__top-link"
                >
                  Comunidad Aliclik
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="header__wrapper container__landing">
        <div className="header__wrapper-logo">
          <h1 className="header__logo">
            <a href="https://aliclik-develop.firebaseapp.com/landing">
              <img src={logo} alt="Aliclik" className="header__logo-img" />
            </a>
          </h1>

          <div
            className={`header__sections ${isOpen ? 'header__sections--open' : ''}`}
          >
            <ul className="header__sections-list">
              <li>
                <a href="https://aliclik-develop.firebaseapp.com/landing#hero-section" onClick={toggleMenu}>
                  Quienes somos
                </a>
              </li>
              <li>
                <a href="https://aliclik-develop.firebaseapp.com/landing#benefits" onClick={toggleMenu}>
                  ¿Qué ofrecemos?
                </a>
              </li>
              <li>
                <a href="https://aliclik-develop.firebaseapp.com/landing#testimonials" onClick={toggleMenu}>
                  Testimonios
                </a>
              </li>
              <li>
                <a href="https://aliclik-develop.firebaseapp.com/landing#questions" onClick={toggleMenu}>
                  Preguntas frecuentes
                </a>
              </li>
              <li>
                <a href="https://aliclik-develop.firebaseapp.com/landing#footer" onClick={toggleMenu}>
                  Contacto
                </a>
              </li>
            </ul>
          </div>
        </div>

        <nav className="header__navbar">
          
          <a 
            className="header__navbar-link" 
            href='/register'
          >
            Registro
          </a>
          <a
            className="header__navbar-link header__bg--blue"
            href="/login"
          >
            Ingresar
          </a>
        </nav>

        <button
          className={`menu-toggle ${isOpen ? 'open' : ''}`}
          onClick={toggleMenu}
        >
          <span className="menu-icon"></span>
        </button>
      </div>
    </header>
  );
};
