import React, { FC, useEffect } from 'react';
import {
  DatePicker,
  Form,
  FormInstance,
  Radio,
  Typography,
} from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useAuthStore } from '../../../../../../services/auth/auth.service';
import { useProductStore } from '../../../../../../services/product/product.store';
import { IOrderShopifySchema } from '../../../../../../services/order-shopify/order-shopify.schema';
import dayjs from 'dayjs';

const { Title } = Typography;

interface IOrderCourierFormProps {
  form: FormInstance<IOrderShopifySchema>;
  disabled: boolean;
  required: number;
}

const OrderCourierForm: FC<IOrderCourierFormProps> = ({
  form,
  disabled,
  required,
}) => {
  const departmentId = Form.useWatch(['shipping', 'departmentId'], form);
  const dispatchDate = Form.useWatch(['shipping', 'dispatchDate'], form);
  const couriers: any = Form.useWatch(['shipping', 'dptoJson'], form);
  const addDays = Form.useWatch(['addDays']);
  const hoursWork = Form.useWatch(['hoursWork']);
  const transportId = Form.useWatch(['transportId'], form);
  const { productSearch } = useProductStore();
  const shippingByAgency = Form.useWatch(
    ['shipping', 'shippingByAgency'],
    form,
  );
  const { authUser } = useAuthStore();
  const currencySymbol = authUser.user.company.setting.currency.symbol;

  useEffect(() => {
    if (transportId && couriers && couriers.length > 0) {
      const courier = couriers.find((t: any) => `${t.courierId}` === `${transportId}`);

      if (courier) {
        form.setFieldValue('wareHours', courier);
        form.setFieldValue('hoursWork', courier.hoursWork || 0);
        form.setFieldValue('typePattern', courier.typePattern);
        form.setFieldValue('addDays', courier.addDays || 0);
        form.setFieldValue('warehouseCurrent', courier.name);
        form.setFieldValue('shippingCost', courier.deliveryCost || 0);
        form.setFieldValue('returnCost', courier.returnCost || 0);

      }
    }
  }, [transportId]);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    if (!shippingByAgency) {
      if (authUser.user.role.name === 'ADMIN_STORE' ||
        authUser.user.role.name === 'SELLER') {
        const newHour =
          hoursWork.length > 0 ? parseInt(`${hoursWork.split(':')[0]}`) : 0;
        const newMin =
          hoursWork.length > 0 ? parseInt(`${hoursWork.split(':')[1]}`) : 0;

        const now = dayjs().utcOffset(-5);
        const today = dayjs().startOf('day').add(newHour, 'h').add(newMin, 'm');

        if (now > today) {

          // Lima
          if (`${departmentId}` === '3926') {

            return (
              (current && current < dayjs().add(1, 'd').startOf('day')) ||
              (current.date() === 25 && current.month() === 11)
              || (current.date() === 1 && current.month() === 0)
            );

          } else {

            return (
              (current && current < dayjs().add(1, 'd').startOf('day')) ||
              current.day() === 0 || (current.date() === 25 && current.month() === 11)
              || (current.date() === 1 && current.month() === 0)
            );

          }
          
        }

        if (`${departmentId}` === '3926') {

          return (
            current.isBefore(now.startOf('day').toDate()) ||
            (current.date() === 25 &&
              current.month() === 11) ||
            (current.date() === 1 &&
              current.month() === 0)
          );
  
        } else {
  
          return (
            current.isBefore(now.startOf('day').toDate()) ||
            current.day() === 0 ||
            (current.date() === 25 &&
              current.month() === 11) ||
            (current.date() === 1 &&
              current.month() === 0)
          );
  
        }
        
      } else if (
        authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER'
      ) {

        const now = dayjs().utcOffset(-5);

        return current.isBefore(now.startOf('day').toDate());
        
      } else {
        return current && current < dayjs().startOf('day');
      }
    } else {
      return current && current < dayjs().startOf('day');
    }
  };

  useEffect(() => {
    if (dispatchDate && !shippingByAgency) {
      if (
        authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER'
      ) {
        // free date delivery
      } else if (authUser.user.role.name === 'ADMIN_STORE' ||
        authUser.user.role.name === 'SELLER') {
        const days = addDays ? addDays : 0;
        const currentDay = dayjs(dispatchDate).utcOffset(-5);
        let newScheduleDate;

        if (currentDay.day() === 6) {
          // saturday
          const tempDays = parseInt(`${days}`);

          newScheduleDate = dayjs(dispatchDate).add(
            1 + (tempDays > 0 ? tempDays - 1 : 0),
            'd',
          );
        } else if (currentDay.day() === 5 && parseInt(`${days}`) === 3) {
          newScheduleDate = dayjs(dispatchDate).add(
            0 + parseInt(`${days}`),
            'd',
          );
        } else {
          //others days
          newScheduleDate = dayjs(dispatchDate).add(parseInt(`${days}`), 'd');
        }

        if (newScheduleDate.day() === 0) {
          newScheduleDate = dayjs(dispatchDate).add(
            1 + parseInt(`${days}`),
            'd',
          );
        }

        if (
          (newScheduleDate.date() === 25 && newScheduleDate.month() === 11) ||
          (newScheduleDate.date() === 1 && newScheduleDate.month() === 0)
        ) {
          newScheduleDate = dayjs(newScheduleDate).add(1, 'd');
        }

        form.setFieldValue(['shipping', 'scheduleDate'], newScheduleDate);
      }
    }
  }, [dispatchDate]);

  // 2900 => Arequipa 3292 => Cusco 3788 => La libertad 3926 => Lima

  useEffect(() => {
    if (!shippingByAgency) {
      form.setFieldValue(['shipping', 'departmentId'], undefined);
      form.setFieldValue(['shipping', 'provinceId'], undefined);
      form.setFieldValue(['shipping', 'districtId'], undefined);
      form.setFieldValue(['shipping', 'department'], '');
      form.setFieldValue(['shipping', 'dptoJson'], []);
      form.setFieldValue(['shipping', 'province'], '');
      form.setFieldValue(['shipping', 'district'], '');
      form.setFieldValue(['shipping', 'attachFile'], undefined);
      form.setFieldValue(['shipping', 'agencyName'], undefined);
      form.setFieldValue(['shipping', 'contactDocumenType'], undefined);
      form.setFieldValue(['shipping', 'contactDocumentNumber'], undefined);
      form.setFieldValue(['shipping', 'contactName'], undefined);
      form.setFieldValue(['shipping', 'contactPhone'], undefined);
      form.setFieldValue(['shipping', 'guideNumber'], undefined);
      form.setFieldValue(['shipping', 'keyCode'], undefined);
      form.setFieldValue(['shipping', 'addressPickUp'], undefined);
      form.setFieldValue(['shipping', 'shippingCost'], undefined);
      form.setFieldValue(['shipping', 'scheduleDate'], undefined);
      form.setFieldValue(['addDays'], 0);
    }
  }, [shippingByAgency]);

  return (
    <>
      <Title level={4}>Entrega</Title>

      {couriers && couriers.length > 0 ? (
        <>
          <Form.Item
            label="Courier"
            name={['transportId']}
            rules={[{ required: true, message: '' }]}
          >
            <Radio.Group>
              {couriers.map((e: any) => (
                <>
                  <Radio value={e.courierId}>
                    <img
                      src={e.courierUrlImage}
                      style={{ width: '100px', height: '50px' }}
                    ></img>

                    <>
                      Entrega: {currencySymbol}{e.deliveryCost}
                      {' '}
                      | Retorno: {currencySymbol}{e.returnCost}
                    </>
                  </Radio>
                </>
              ))}
            </Radio.Group>
          </Form.Item>
        </>
      ) : null}

      {!shippingByAgency && productSearch && productSearch.length > 0 ? (
        <>
          <Form.Item
            label="F. despacho"
            name={['shipping', 'dispatchDate']}
            rules={[
              {
                required: required === 1 || required === 2 ? false : true,
                message: 'Seleccionar una fecha de despacho',
              },
            ]}
          >
            <DatePicker
              disabled={disabled}
              inputReadOnly={true}
              showNow={false}
              disabledDate={disabledDate}
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item
            label="F. entrega aprox."
            name={['shipping', 'scheduleDate']}
            rules={[
              {
                required: required === 1 || required === 2 ? false : true,
                message: '',
              },
            ]}
          >
            <DatePicker
              disabled
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </>
      ) : null}

    </>
  );
};

export default OrderCourierForm;
