import React, { FC, useEffect } from 'react';
import {
  Form,
  FormInstance,
  Input,
  Select,
  Typography,
} from 'antd';
import {
  useLocationLevel1,
  useLocationLevel2,
  useLocationLevel3,
} from '../../../../../../services/location/location.service';
import {
  useCountryEdit,
} from '../../../../../../services/pre-order/pre-order.edit';
import { IOrderShopifySchema } from '../../../../../../services/order-shopify/order-shopify.schema';

const { Title } = Typography;

interface IOrderShopifyShippingFormProps {
  form: FormInstance<IOrderShopifySchema>;
  disabled: boolean;
  required: number;
}

const OrderShopifyShippingForm: FC<IOrderShopifyShippingFormProps> = ({
  form,
  disabled,
  required,
}) => {
  const provinceId = Form.useWatch(['shipping', 'provinceId'], form);
  const departmentId = Form.useWatch(['shipping', 'departmentId'], form);
  const districtId = Form.useWatch(['shipping', 'districtId'], form);
  const enableDpto = Form.useWatch(['enableDpto'], form);
  const shippingByAgency = Form.useWatch(
    ['shipping', 'shippingByAgency'],
    form,
  );
  const { requiredByCountry } = useCountryEdit();
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } =
    useLocationLevel1();
  const { getLocationsGeoLevel2, loadingGeo2, locationsGeolevel2 } =
    useLocationLevel2();
  const { getLocationsGeoLevel3, loadingGeo3, locationsGeolevel3 } =
    useLocationLevel3();

  const handleChangeProvince = (ubigeoId: string) => {
    getLocationsGeoLevel3({
      parentId: Number.parseInt(ubigeoId),
    });
    //form.setFieldValue(['shipping', 'districtId'], '')
  };

  const handleChangeDepartment = (ubigeoId: string) => {
    getLocationsGeoLevel2({
      parentId: Number.parseInt(ubigeoId),
    });
    //form.setFieldValue(['shipping', 'provinceId'], '')
  };

  useEffect(() => {
    const dptos = shippingByAgency
      ? locationsGeolevel1
        ? locationsGeolevel1
        : []
      : locationsGeolevel1
        ? locationsGeolevel1.filter((f) => f.flagContraentrega)
        : [];

    if (departmentId && dptos.length > 0) {
      const dpto = dptos.find((d) => `${d.id}` === `${departmentId}`);

      if (dpto) {

        form.setFieldValue(['shipping', 'department'], dpto?.name);
        form.setFieldValue(['shipping', 'dispatchDate'], undefined);
        form.setFieldValue(['shipping', 'scheduleDate'], undefined);
        form.setFieldValue('wareHours', undefined);
        form.setFieldValue('hoursWork', undefined);
        form.setFieldValue('typePattern', undefined);
        form.setFieldValue('addDays', undefined);
        form.setFieldValue('warehouseCurrent', undefined);
        form.setFieldValue('transportId', undefined)
        
        handleChangeDepartment(`${dpto.id}`);
      }
    }
  }, [departmentId, locationsGeolevel1]);

  useEffect(() => {
    const provinces = shippingByAgency
      ? locationsGeolevel2
        ? locationsGeolevel2
        : []
      : locationsGeolevel2
        ? locationsGeolevel2.filter((f) => f.flagContraentrega)
        : [];

    if (provinceId && provinces.length > 0) {
      const prov = provinces.find((d) => `${d.id}` === `${provinceId}`);

      if (prov) {

        form.setFieldValue(['shipping', 'province'], prov?.name);

        handleChangeProvince(`${prov.id}`);
      }
    }
  }, [provinceId, locationsGeolevel2]);

  useEffect(() => {
    const districts = shippingByAgency
      ? locationsGeolevel3
        ? locationsGeolevel3
        : []
      : locationsGeolevel3
        ? locationsGeolevel3.filter((f) => f.flagContraentrega)
        : [];

    if (districtId && districts.length > 0) {
      const dist = districts.find((d) => `${d.id}` === `${districtId}`);

      if (dist) {
        form.setFieldValue(['shipping', 'district'], dist?.name);
      }
    }
  }, [districtId, locationsGeolevel3]);

  useEffect(() => {
    getLocationsGeoLevel1();
  }, []);

  return (
    <>
      <Title level={4}>Entrega</Title>

      <Form.Item
        label="Departamento"
        name={['shipping', 'departmentId']}
        rules={[
          {
            required: required === 1 ? false : true,
            message: '',
          },
        ]}
      >
        <Select
          onChange={handleChangeDepartment}
          showSearch
          optionFilterProp="label"
          disabled={disabled === false ? enableDpto : disabled}
          loading={loadingGeo1}
          filterOption={(input, option) =>
            (option?.label as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={
            !shippingByAgency
              ? locationsGeolevel1
                  ?.filter((f) => f.flagContraentrega)
                  .map((e) => ({
                    label: e.name,
                    value: e.id,
                  }))
              : locationsGeolevel1?.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))
          }
        />
      </Form.Item>

      <Form.Item
        label="Provincia"
        name={['shipping', 'provinceId']}
        rules={[
          {
            required: required === 1 ? false : true,
            message: '',
          },
        ]}
      >
        <Select
          onChange={handleChangeProvince}
          showSearch
          optionFilterProp="label"
          loading={loadingGeo2}
          filterOption={(input, option) =>
            (option?.label as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={
            !shippingByAgency
              ? locationsGeolevel2
                  ?.filter((f) => f.flagContraentrega)
                  .map((e) => ({
                    label: e.name,
                    value: e.id,
                  }))
              : locationsGeolevel2?.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))
          }
          disabled={disabled}
        />
      </Form.Item>

      <Form.Item
        label="Distrito"
        name={['shipping', 'districtId']}
        rules={[
          {
            required: required === 1 ? false : true,
            message: '',
          },
        ]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          loading={loadingGeo3}
          filterOption={(input, option) =>
            (option?.label as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={
            !shippingByAgency
              ? locationsGeolevel3
                  ?.filter((f) => f.flagContraentrega)
                  .map((e) => ({
                    label: e.name,
                    value: e.id,
                  }))
              : locationsGeolevel3?.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))
          }
          disabled={disabled}
        />
      </Form.Item>

      <Form.Item
        label="Dirección 1"
        name={['shipping', 'address1']}
        rules={[
          {
            required: !shippingByAgency
              ? required === 1 || required === 2
                ? false
                : true
              : false,
            message: '',
          },
        ]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item label="Dirección 2" name={['shipping', 'address2']} hidden>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        label="Referencia"
        name={['shipping', 'reference']}
        hidden={shippingByAgency}
        rules={[
          {
            required: !shippingByAgency
              ? requiredByCountry
                ? required === 0
                  ? true
                  : false
                : false
              : false,
            message: '',
          },
        ]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        label="GPS"
        name={['shipping', 'gps']}
        hidden={shippingByAgency}
        rules={[
          {
            required: !shippingByAgency
              ? requiredByCountry
                ? required === 0
                  ? true
                  : false
                : false
              : false,
            message: '',
            pattern: /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/,
          },
        ]}
      >
        <Input placeholder="Latitud,Longitud" disabled={disabled} />
      </Form.Item>

      {!disabled ? (
        <>
          <Form.Item label="GPS" hidden={shippingByAgency}>
            Solicítalo al cliente por WhatsApp o
            <a target="_blank" rel="noreferrer" href="https://bit.ly/3wqggn9">
              {' '}
              ver TUTORIAL
            </a>
          </Form.Item>
        </>
      ) : null}

      <Form.Item hidden name={['shipping', 'department']}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item hidden name={['shipping', 'dptoJson']}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item hidden name={['shipping', 'district']}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item hidden name={['shipping', 'province']}>
        <Input disabled={disabled} />
      </Form.Item>
    </>
  );
};

export default OrderShopifyShippingForm;
