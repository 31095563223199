//import { Alert } from 'antd'
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../../routes/routes.param';
import { IAuthRequest } from '../../../../services/auth/auth.interface';
import { useAuthentication } from '../../../../services/auth/auth.service';
import { SignInForm } from '../../forms';

const SignInContainer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { getAuthentication, loading, authUser } = useAuthentication();
  const handleSubmit = (auth: IAuthRequest) => {
    getAuthentication(auth);
  };

  useEffect(() => {
    const regex = /^ALICLIK-\d+$/;

    if (params.code && params.code.length > 0) {
      if (regex.test(params.code)) {
        localStorage.setItem('codeReference', params.code);
      } else {
        navigate(ROUTES.SIGN_IN.PATH, { replace: true });
      }
    }
  }, [params.code]);

  useEffect(() => {
    if (authUser) {
      if (
        authUser.user.role.name === 'ADMIN_STORE' ||
        authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'SELLER'
      ) {
        if (authUser.user.daysCreated > 15) {
          navigate(ROUTES.ALINEWS.PATH, { replace: true });
        } else {
          navigate(ROUTES.FREQUENT_QUESTION.PATH, { replace: true });
        }
      } else if (authUser.user.role.name === 'STORE') {
        navigate('/order/orders', { replace: true });
      } else {

        if (authUser.user.company.countryCode === 'MEX') {
          navigate(ROUTES.ALINEWS.PATH, { replace: true });
        } else {
          navigate(ROUTES.DASHBOARD.PATH, { replace: true });
        }
      }
    }
  }, [authUser]);

  return <SignInForm onSubmit={handleSubmit} loading={loading} />;
};

export default SignInContainer;
