import React, { useEffect } from 'react';
import { ISkuItemResponse } from '../../../../services/product/product.interface';
import { Drawer } from '../../../../components';
import { useShopifyProducts } from '../../../../modules/shopify-connect/hooks';
import { 
  Button, Checkbox, Flex, Input, List, Spin 
} from 'antd';
import { TShopifyProduct, TSkuShopify } from '../../../../modules/shopify-connect/types';
import { useCompanyStore } from '../../../../services/company-create/company-create.service';
import {
  useCreateSkuShopify,
  useUpdateSkuShopify,
} from '../../../../modules/shopify-connect/hooks/use-sku-shopify';
import { useAuthStore } from '../../../../services/auth/auth.service';
import { useProductStore } from '../../../../services/product/product.store';
import { useShopifyProductsConnected } from '../../../../modules/shopify-connect/hooks/use-shopify-products';

type ConnectSkuShopifyContainerProps = {
  sku: ISkuItemResponse;
  onClose: () => void;
};

export const ConnectSkuShopifyContainer: React.FC<ConnectSkuShopifyContainerProps> = ({
  sku,
  onClose,
}) => {
  const { getProducts, loading, products } = useShopifyProducts();
  const { getProductsConnected, productsConnected } = useShopifyProductsConnected();
  const { companyGlobal } = useCompanyStore();
  const { authUser } = useAuthStore()
  const [filteredProductsSku, setFilteredProductsSku] = React.useState<any[]>();
  const [filteredProducts, setFilteredProducts] = React.useState<TShopifyProduct[]>();
  const [selectedVariantShopify, setSelectedVariantShopify] = React.useState<{
    productId: string;
    variantId: string;
  }[]>();
  const { createSkuShopify, loading: isLoadingCreate, response: responseCreate } = useCreateSkuShopify();
  const { updateSkuShopify, loading: isLoadingUpdate, response: responseUpdate } = useUpdateSkuShopify();
  const { setProductUpdated } = useProductStore();

  const handleSearch = (value: string) => {
    const filtered = products?.filter((product) =>
      product.title.toLowerCase().includes(value.toLowerCase()),
    );

    setFilteredProducts(filtered);
  };

  const handleConnectSkuShopify = () => {
    if (!selectedVariantShopify) {
      return;
    }

    const payloads: TSkuShopify[] = selectedVariantShopify.map(st => {
      const payload: TSkuShopify = {
        skuId: sku.id,
        companyId: authUser.user.company.id,
        shopifyProductId: st.productId,
        shopifyVariantId: st.variantId,
      }

      return payload
    })

    if (sku.skusShopify?.length) {
      updateSkuShopify(sku.id, authUser.user.company.id, payloads);
    } else {
      createSkuShopify(payloads);
    }

  };

  useEffect(() => {
    if (responseCreate || responseUpdate) {
      setProductUpdated({ id: -1 })
      onClose()
    }
  }, [responseCreate, responseUpdate])

  useEffect(() => {

    if (
      companyGlobal &&
      (authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER' ||
        authUser.user.role.name === 'SUPER_MASTER')
    ) {
      getProductsConnected({ companyId: companyGlobal ? companyGlobal.id : authUser.user.company.id, skuId: sku.id })
      getProducts({ companyId: companyGlobal ? companyGlobal.id : authUser.user.company.id });
    } else if (
      authUser.user.role.name === 'ADMIN_STORE' ||
      authUser.user.role.name === 'SELLER'
    ) {
      getProductsConnected({ companyId: authUser.user.company.id, skuId: sku.id })
      getProducts({ companyId: authUser.user.company.id });
    }
    
    setSelectedVariantShopify(sku.skusShopify?.map(t => {
      return {
        productId: t.shopifyProductId,
        variantId: t.shopifyVariantId,
      }
    }))

  }, []);


  useEffect(() => {

    const skus: any[] = [];

    (filteredProducts || products)?.forEach(c => {

      c.variants.forEach(v => {

        skus.push({
          prodId: `${c.id}`,
          prodName: c.title,
          variantName: v.title === 'Default Title' ? c.title : v.title,
          variantId: `${v.id}`,
          checked: selectedVariantShopify?.find(it => it.variantId === `${v.id}`) ? true : false,
        })
      })

    })

    setFilteredProductsSku(skus)
    
  }, [filteredProducts, products])

  return (
    <Drawer
      open
      title={
        <Flex justify="space-between">
          Conectar SKU a Shopify
          <Button
            type="primary"
            htmlType="button"
            loading={isLoadingCreate || isLoadingUpdate}
            disabled={!selectedVariantShopify}
            onClick={handleConnectSkuShopify}
          >
            Conectar
          </Button>
        </Flex>
      }
      onClose={onClose}
      width={600}
    >
      {loading ? (
        <Spin />
      ) : (
        <div>
          <Input.Search
            allowClear
            placeholder="Buscar productos en shopify"
            onSearch={handleSearch}
          />
          { filteredProductsSku && filteredProductsSku.length ? <>
            <List
              header={<div>Productos shopify:</div>}
              dataSource={filteredProductsSku.sort((a, b) => b.checked - a.checked)}
              renderItem={(t) => (
                <List.Item>
                  <Checkbox
                    value={t.variantId}
                    disabled={productsConnected?.length ? 
                      (productsConnected.findIndex(it => it.shopifyVariantId === t.variantId) !== -1) : false}
                    checked={selectedVariantShopify?.find(it => it.variantId === t.variantId) ? true : false}
                    onChange={() => {
                      
                      let auxList = [...(selectedVariantShopify || [])]

                      const index = auxList.find(item => item.variantId === t.variantId)

                      if (index) {
                        auxList = auxList.filter(it => it.variantId !== t.variantId)
                      } else {
                        auxList.push({
                          productId: t.prodId,
                          variantId: t.variantId
                        })
                      }

                      setSelectedVariantShopify(auxList)

                    }}
                  >
                    {t.prodName === t.variantName ? t.variantName : `${t.variantName} (${t.prodName})`}
                  </Checkbox>
                </List.Item>
              )}
            />
            
          </> : null }
          
        </div>
      )}
    </Drawer>
  );
};
