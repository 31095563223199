import React, { FC } from 'react';
import { Button, Empty, Image } from 'antd';
import { IVerifiedSuppliers } from '../../../../../services/order-report-created/order-report-created.interface';
import s from './VerifiedSuppliersGrid.module.css';

import { Swiper, SwiperSlide, } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Navigation, Pagination, Autoplay } from 'swiper/modules';
type TVerifiedSuppliersGridProps = {
  isLoading: boolean;
  products: IVerifiedSuppliers[];
  onSupplierSelect: (supplierId: string) => void;
};

export const VerifiedSuppliersGrid: FC<TVerifiedSuppliersGridProps> = ({ isLoading, products, onSupplierSelect }) => {

  if (!isLoading && !products.length) {
    return <Empty description='No tenemos productos para mostrar' />;
  }

  return (
    <>
      <div className={s.container}>
          <Swiper
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation
            breakpoints={{
              '@0.00': {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              '@0.75': {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              '@1.00': {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              '@1.50': {
                slidesPerView: 5,
                spaceBetween: 10,
              },
            }}
            modules={[Navigation, Pagination, Autoplay]}
          >
            {products.map((p) => (
              <SwiperSlide key={`slide-${p.id}`}>
                <div className={s.ProductCard}>
                  <Image
                    alt={p.name}
                    src={p.urlImage}
                    height={180}
                    width={280}
                    loading='lazy'
                    preview={false}
                    className={s.ProductCardImage}
                  />
                  <div className={s.ProductCardFooter}>
                    <p className={s.proveedor_des}>Proveedor: <span className={s.ProductDescription}> {p.name} </span> </p>
                    <Button 
                      type='primary'
                      onClick={() => onSupplierSelect(`${p.id}`)}
                    >
                      Ver Productos
                    </Button>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

      </div>
    </>
  );
};
