import React, { FC, useEffect } from 'react';
import {
  Form,
  FormInstance,
  Input,
  Select,
  Typography,
} from 'antd';
import {
  useLocationLevel1,
  useLocationLevel2,
  useLocationLevel3,
} from '../../../../../../services/location/location.service';
import { IOrderUpdateSchema } from '../../../../../../services/order-update/order-update.schema';
import { useCountryEdit } from '../../../../../../services/pre-order/pre-order.edit';
import { useAuthStore } from '../../../../../../services/auth/auth.service';

const { Title } = Typography;

interface IOrderShippingPartProps {
  form: FormInstance<IOrderUpdateSchema>;
  companyUser: number;
  disabled: boolean;
  isField: boolean;
  newOrder: boolean;
}

const OrderShippingPart: FC<IOrderShippingPartProps> = ({
  form,
  companyUser,
  disabled,
  isField,
  newOrder
}) => {
  const { authUser } = useAuthStore()
  const provinceId = Form.useWatch(['shipping', 'provinceId'], form);
  const departmentId = Form.useWatch(['shipping', 'departmentId'], form);
  const districtId = Form.useWatch(['shipping', 'districtId'], form);
  const enableDpto = Form.useWatch(['enableDpto'], form);
  const { requiredByCountry } = useCountryEdit();
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } =
    useLocationLevel1();
  const { getLocationsGeoLevel2, loadingGeo2, locationsGeolevel2 } =
    useLocationLevel2();
  const { getLocationsGeoLevel3, loadingGeo3, locationsGeolevel3 } =
    useLocationLevel3();

  const handleChangeProvince = (ubigeoId: string) => {
    getLocationsGeoLevel3({
      parentId: Number.parseInt(ubigeoId),
    });
  };

  const handleChangeDepartment = (ubigeoId: string) => {
    getLocationsGeoLevel2({
      parentId: Number.parseInt(ubigeoId),
    });
  };

  useEffect(() => {
    const dptos = locationsGeolevel1
      ? locationsGeolevel1.filter((f) => f.flagContraentrega)
      : [];

    if (departmentId && dptos.length > 0) {
      const dpto = dptos.find((d) => `${d.id}` === `${departmentId}`);

      if (dpto) {

        form.setFieldValue(['shipping', 'department'], dpto?.name);

        if (newOrder === true) {
          form.setFieldValue(['shipping', 'dispatchDate'], undefined);
          form.setFieldValue(['shipping', 'scheduleDate'], undefined);
          form.setFieldValue('wareHours', undefined);
          form.setFieldValue('hoursWork', undefined);
          form.setFieldValue('typePattern', undefined);
          form.setFieldValue('addDays', undefined);
          form.setFieldValue('warehouseCurrent', undefined);
          form.setFieldValue('transportId', undefined)
        }

        handleChangeDepartment(`${dpto.id}`);
      }
    }
  }, [departmentId, locationsGeolevel1]);

  useEffect(() => {
    const provs = locationsGeolevel2
      ? locationsGeolevel2.filter((f) => f.flagContraentrega)
      : [];

    if (provinceId && provs.length > 0) {
      const prov = provs.find((d) => `${d.id}` === `${provinceId}`);

      if (prov) {
        form.setFieldValue(['shipping', 'province'], prov?.name);
        handleChangeProvince(`${prov.id}`);
      }
    }
  }, [provinceId, locationsGeolevel2]);

  useEffect(() => {
    const districts = locationsGeolevel3
      ? locationsGeolevel3.filter((f) => f.flagContraentrega)
      : [];

    if (districtId && districts.length > 0) {
      const dist = districts.find((d) => `${d.id}` === `${districtId}`);

      if (dist) {
        form.setFieldValue(['shipping', 'district'], dist?.name);
        form.setFieldValue(['shipping', 'postalCode'], dist?.postalCode);
      }
    }
  }, [districtId, locationsGeolevel3]);

  useEffect(() => {
    getLocationsGeoLevel1();
  }, []);

  return (
    <>
      <Title level={4}>Entrega</Title>

      <Form.Item hidden label="ID" name={['shipping', 'id']}>
        <Input />
      </Form.Item>

      <Form.Item hidden name={['shipping', 'postalCode']}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        label={authUser.user.company.countryCode === 'MEX' ? 'Estado' : 'Departamento'}

        name={['shipping', 'departmentId']}
        rules={[{ required: true, message: '' }]}
      >
        <Select
          onChange={handleChangeDepartment}
          showSearch
          optionFilterProp="label"
          disabled={enableDpto}
          loading={loadingGeo1}
          filterOption={(input, option) =>
            (option?.label as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={locationsGeolevel1
            ?.filter((f) => f.flagContraentrega)
            .map((e) => ({
              label: e.name,
              value: e.id,
            }))}
        />
      </Form.Item>

      <Form.Item
        label={authUser.user.company.countryCode === 'MEX' ? 'Municipio' : 'Provincia'}
        name={['shipping', 'provinceId']}
        rules={[{ required: true, message: '' }]}
      >
        <Select
          onChange={handleChangeProvince}
          showSearch
          optionFilterProp="label"
          disabled={disabled}
          loading={loadingGeo2}
          filterOption={(input, option) =>
            (option?.label as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={locationsGeolevel2
            ?.filter((f) => f.flagContraentrega)
            .map((e) => ({
              label: e.name,
              value: e.id,
            }))}
        />
      </Form.Item>

      { authUser.user.company.countryCode === 'MEX' ? <>
      
        <Form.Item
          label={'Código postal / Colonia'}
          name={['shipping', 'districtId']}
          rules={[{ required: true, message: '' }]}
        >
          <Select
            showSearch
            optionFilterProp="label"
            disabled={disabled}
            loading={loadingGeo3}
            filterOption={(input, option) =>
              (option?.label as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            options={locationsGeolevel3
              .filter((f) => f.flagContraentrega)
              ?.map((e) => ({
                label: `${e.postalCode} - ${e.name}`,
                value: e.id,
              }))}
          />
        </Form.Item>
      
      </> : <>
        <Form.Item
          label={'Distrito'}
          name={['shipping', 'districtId']}
          rules={[{ required: true, message: '' }]}
        >
          <Select
            showSearch
            optionFilterProp="label"
            disabled={disabled}
            loading={loadingGeo3}
            filterOption={(input, option) =>
              (option?.label as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            options={locationsGeolevel3
              .filter((f) => f.flagContraentrega)
              ?.map((e) => ({
                label: e.name,
                value: e.id,
              }))}
          />
        </Form.Item>
      </> }

      <Form.Item
        label={authUser.user.company.countryCode === 'MEX' ? 'Dirección / Calle y número' : 'Dirección'}
        name={['shipping', 'address1']}
        rules={[{ required: true, message: '' }]}
      >
        <Input disabled={companyUser == 2 ? isField : disabled} />
      </Form.Item>

      <Form.Item label="Dirección 2" name={['shipping', 'address2']} hidden>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        label="Referencia"
        name={['shipping', 'reference']}
        rules={[
          {
            required: requiredByCountry
              ? companyUser == 2
                ? !isField
                : false
              : false,
            message: '',
          },
        ]}
      >
        <Input disabled={companyUser == 2 ? isField : disabled} />
      </Form.Item>

      <Form.Item
        label="GPS"
        name={['shipping', 'gps']}
        rules={[
          {
            message: '',
            required: requiredByCountry
              ? companyUser == 2
                ? !isField
                : false
              : false,
            pattern: /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/,
          },
        ]}
      >
        <Input
          placeholder="Latitud,Longitud"
          disabled={companyUser == 2 ? isField : disabled}
        />
      </Form.Item>

      {!isField || !disabled ? (
        <>
          <Form.Item label="GPS">
            Solicítalo al cliente por WhatsApp o
            <a target="_blank" rel="noreferrer" href="https://bit.ly/3wqggn9">
              {' '}
              ver TUTORIAL
            </a>
          </Form.Item>
        </>
      ) : null}

      <Form.Item hidden name={['shipping', 'department']}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item hidden name={['shipping', 'dptoJson']}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item hidden name={['shipping', 'district']}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item hidden name={['shipping', 'province']}>
        <Input disabled={disabled} />
      </Form.Item>
    </>
  );
};

export default OrderShippingPart;
