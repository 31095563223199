import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';

interface ModalConfirmDeleteProps {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title?: string;
  content?: string;
}

const ModalConfirmDelete: React.FC<ModalConfirmDeleteProps> = ({
  visible,
  onConfirm,
  onCancel,
  title,
  content,
}) => {
  return (
    <Modal
      title={
        <span>
          <ExclamationCircleFilled style={{ color: 'red', marginRight: 8 }} />
          {title}
        </span>
      }
      visible={visible}
      onOk={onConfirm}
      onCancel={onCancel}
      okText="Sí, eliminar"
      cancelText="No, cancelar"
      okButtonProps={{ danger: true }}
    >
      <p>{content}</p>
    </Modal>
  );
};

export default ModalConfirmDelete;
