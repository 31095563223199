import React from 'react'
import { Form, Input, Typography, InputNumber } from 'antd'

const { Title } = Typography

const OrderCustomerPart = ({ disabled, typeManager }: { disabled: boolean, typeManager?: string }) => {


  return <>
    <Title level={4}>Cliente</Title>
    <Form.Item name={['customer', 'companyId']} hidden={true}>
      <Input />
    </Form.Item>
    <Form.Item
      label={'Nombre completo'}
      name={['customer', 'name']}
      rules={[{ required: typeManager && typeManager === 'OWN' ? false : true, message: 'Obligatorio' }]}
    >
      <Input disabled={disabled}/>
    </Form.Item>
    <Form.Item
      hidden
      label="Apellidos"
      name={['customer', 'lastName']}
    >
      <Input disabled={disabled}/>
    </Form.Item>
    <Form.Item
      label="Móvil"
      name={['customer', 'phone']}
      rules={[{ required: typeManager && typeManager === 'OWN' ? false : true, message: 'Obligatorio' }]}
    >
      <InputNumber
        disabled={disabled}
        controls={false}
        maxLength={11}
        style={{ display: 'inline-block', width: '100%' }}
      />
    </Form.Item>
  </>
}

export default OrderCustomerPart
