import React, { useEffect, useState } from 'react'
import { Button, Form, FormInstance, Modal, Space, Typography, message, Radio, Input } from 'antd'
import { IProductSearchSchema } from '../../../../../../services/product/product.schema'
import { useProductStore } from '../../../../../../services/product/product.store'
import { ProductListSearch } from '../../../../MyPreOrdersView/components'
import { ProductOrderDeliveryForm } from '../../../../OrdersView/forms'
import { IOrderShopifySchema } from '../../../../../../services/order-shopify/order-shopify.schema'
import { ProductAgencySearchForm } from '../../../../OrderAgencyView/forms'

const { Title } = Typography

const OrderShopifyProductForm = ({
  disabled, form
}: { disabled: boolean, form: FormInstance<IOrderShopifySchema> }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { setProductSearch, productSearch, productDelete } = useProductStore()
  const [total, setTotal] = useState<number>(0)
  const shippingByAgency = Form.useWatch(['shipping', 'shippingByAgency'], form)
  const warehouseToday = Form.useWatch(['warehouseCurrent'], form)
  const warehouseHourDays = Form.useWatch(['wareHours'], form)
  const provinceId = Form.useWatch(['shipping', 'provinceId'], form);
  const departmentId = Form.useWatch(['shipping', 'departmentId'], form);
  const districtId = Form.useWatch(['shipping', 'districtId'], form);

  const calculateCouriers = (couriers: any) => {
    form.setFieldValue(['shipping', 'dptoJson'], couriers)
  }

  const warehouseCurrent = (warName: string) => {
    form.setFieldValue('warehouseCurrent', warName)
  }

  const setWarehouse = (war: any) => {
    form.setFieldValue('wareHours', war)
  }

  const handleShowModal = () => {
    setIsModalVisible(true)
  }

  const handleCancelModal = () => {
    setIsModalVisible(false)
  }

  const handleSubmit = (data: IProductSearchSchema) => {
    const prod = productSearch[0]

    if (prod) {
      if (prod.warehouseId === data.warehouseId) {
        setProductSearch(data)
        handleCancelModal()
      } else {
        message.warning(`Debe seleccionar un producto del almacén ${prod.warehouseName}`)
      }
    } else {
      setProductSearch(data)
      handleCancelModal()
    }
    
  }

  const handleDeleteProduct = (item: IProductSearchSchema) => {
    productDelete(item.skuId)
  }

  useEffect(() => {

    if (shippingByAgency === false) {
      if (productSearch.length > 0) {
        form.setFieldValue('enableDpto', true)
      } else {
        form.setFieldValue('enableDpto', false)
        form.setFieldValue(['shipping', 'scheduleDate'], undefined)
        form.setFieldValue(['shipping', 'dispatchDate'], undefined)
      }
    } else {
      if (productSearch.length === 0) {
        form.setFieldValue('enableDpto', false)
      } else {
        form.setFieldValue('enableDpto', true)
      }
    }

    const prodMap = productSearch?.map(p => p.subtotal)

    setTotal(prodMap?.length ? prodMap.reduce((a, b) => a + b) : 0)
  }, [productSearch])

  const hourWarehouse = () => {

    const hourMain = warehouseHourDays && warehouseHourDays.hourMain 
      && warehouseHourDays.hourMain.length > 0 ? warehouseHourDays.hourMain : ''

    const stringHourMain = hourMain.length > 0 ? `<br/>Contra entrega Lima: ${hourMain}h` : ''

    const hourSecundary = warehouseHourDays && warehouseHourDays.hourSecundary 
      && warehouseHourDays.hourSecundary.length > 0 ? warehouseHourDays.hourSecundary : ''

    const stringHourSecundary = hourSecundary.length > 0 ? 
      `<br/>Contra entrega provincia: ${hourSecundary}h` : ''

    const hourAgency = warehouseHourDays && warehouseHourDays.hourAgency 
      && warehouseHourDays.hourAgency.length > 0 ? warehouseHourDays.hourAgency : ''

    const stringHourAgency = hourAgency.length > 0 ? 
      `<br/>Horario de cierre Drop Shalom: ${hourAgency}h` : ''

    return 'Horario de cierre:'.concat(stringHourMain)
      .concat(stringHourSecundary)
      .concat(stringHourAgency)
  }

  return <>
    <Space style={{ marginBottom: '.5rem' }}>
      <Title level={4} style={{ marginBottom: 0 }}>Productos</Title>
      <Button type="link" onClick={handleShowModal} hidden={disabled}>+ Agregar</Button>
      <Title level={5} style={{ marginBottom: 0, fontSize: '12px' }}>
        Almacén: {warehouseToday}<br/>
        <div dangerouslySetInnerHTML={{ __html: hourWarehouse() }} />
        Horario de entrega del pedido de 10AM a 5PM
      </Title>
      <Modal
        title="Buscar Producto"
        visible={isModalVisible}
        onCancel={handleCancelModal}
        footer={null}
        destroyOnClose
      >
        { shippingByAgency ? <>
          <ProductAgencySearchForm
            onSubmit={handleSubmit}
          />
        </> : <>
          <ProductOrderDeliveryForm
            onSubmit={handleSubmit}
            warehouseSelected={setWarehouse}
            couriersSelected={calculateCouriers}
            departmentId={`${departmentId}`}
            provinceId={`${provinceId}`}
            districtId={`${districtId}`}
            warhSelected={warehouseCurrent}
          />

        </>}
        
      </Modal>
    </Space>

    <ProductListSearch
      data={productSearch}
      total={total}
      onDelete={handleDeleteProduct}
    />

    <Form.Item name={['addDays']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['hoursWork']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['warehouseCurrent']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['wareHours']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['typePattern']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['enableDpto']} label="Activar departamento" hidden>
      <Radio.Group
        options={[
          { label: 'No', value: false },
          { label: 'Si', value: true },
        ]}
        optionType="button"
        buttonStyle="solid"
      />
    </Form.Item>

  </>
}

export default OrderShopifyProductForm
