import React from 'react';
import SignUpContainer from '../SignUpContainer/SignUpContainer';
import backgroundLogin from '../../background_login.png';
import s from './SignUpView.module.css'
import logo from '../../logo.svg';

const SignUpView = () => {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${backgroundLogin})` }}
        className={s.WrapperContainer}
      >
        <div className={s.WrapperBoxSmall}>
          <div className="center">
            <img src={logo} style={{ width: '90px', height: '30px' }} />
          </div>

          <SignUpContainer />

          <p
            className="center"
            style={{ fontSize: '12px', color: '#888', marginTop: '1rem' }}
          >
            Copyright {new Date().getFullYear()} © Aliclik Perú.
          </p>
        </div>
      </div>
    </>
  );
};

export default SignUpView;
