import React from 'react';
import { SignInContainer } from './containers';
import backgroundLogin from './background_login.png';
import logo from './logo.svg';
import play from './googleplay.png';
import alilanding from './alilanding.png';
import s from './SignInView.module.css';

const SignInView = () => {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${backgroundLogin})` }}
        className={s.WrapperContainer}
      >
        <div className={s.content__signin}>
          <div className={s.WrapperBoxSmall}>
            <div className="center">
              <img src={logo} className={s.logoImage} />
            </div>

            <SignInContainer />

            <p
              className="center"
              style={{ fontSize: '12px', color: '#888', marginTop: '1rem' }}
            >
              Copyright 2023 © Aliclik Perú.
            </p>
          </div>

          <h1 className={s.text__title}>Potenciamos el desarrollo economico de las tiendas  ecommerce en todo el Perú</h1>
          <a 
            href="https://play.google.com/store/search?q=aliclik&c=apps&hl=es_419" 
            className={s.play__store}>
            <img
              src={play}
              className={s.imagePlay}
             />
          </a>
        </div>

        <div className={s.imageContainer}>
          <img
            className={s.alilandingImage}
            src={ alilanding }
          />
        </div>
      </div>
    </>
  );
};

export default SignInView;
