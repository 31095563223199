import { Button, Select, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../../../../components';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { useCompanyStore } from '../../../../../services/company-create/company-create.service';
import { useUsersPagination } from '../../../../../services/user-list/user-list.service';
import { useUserStore } from '../../../../../services/user/user.service';
import { UsersListTable } from '../../components';
import { useUserCompanyList } from '../../../../../services/order-create/order.edit';
import { useCompanyList } from '../../../../../services/company-list/company-list.service';
// import { TutoComponent } from '../../../../../components/TutoComponent/TutoComponent';

const { Option } = Select;
const { Search } = Input;

const UsersMaintContainer = () => {
  const { openDrawer } = useDrawer();
  const { authUser } = useAuthStore();
  const { getUsers, response, loading, count, page } = useUsersPagination();
  const { userUpdated, setUserSelected } = useUserStore();
  const [defaultActive, setDefaultActive] = useState<string>('ACTIVE');
  const [textSearch, setTextSearch] = useState<string>('');
  const { isFilterCompany } = useUserCompanyList();
  const { setCompanyGlobal } = useCompanyStore();
  const { companies, getCompanies } = useCompanyList();
  const [defaultSelected, setDefaultSelected] = useState<string>('ALL');

  const handleOpenDrawer = () => {
    setUserSelected(null);
    openDrawer('USER_EDIT', 'Agregar usuario');
  };

  const handlePagination = (page: number) => {
    getUsers(
      defaultActive === 'ACTIVE',
      textSearch,
      `${page}`,
      defaultSelected,
    );
  };

  const onSearch = (value: string) => {
    setTextSearch(value);

    getUsers(defaultActive === 'ACTIVE', value, '1', defaultSelected);
  };

  useEffect(() => {
    if (userUpdated) {
      getUsers(defaultActive === 'ACTIVE', textSearch, '1', defaultSelected);
    }
  }, [userUpdated]);

  useEffect(() => {
    if (isFilterCompany === false) {
      getCompanies();

      getUsers(
        defaultActive === 'ACTIVE',
        textSearch,
        '1',
        defaultSelected === 'ALL' ? '' : defaultSelected,
      );
    } else if (isFilterCompany === true) {
      getUsers(
        defaultActive === 'ACTIVE',
        textSearch,
        '1',
        `${authUser.user.company.id}`,
      );

      setDefaultSelected(`${authUser.user.company.id}`);
    }

    if (authUser.user.role.name === 'STORE') {
      getUsers(
        defaultActive === 'ACTIVE',
        textSearch,
        '1',
        `${authUser.user.company.id}`,
      );

      setDefaultSelected(`${authUser.user.company.id}`);
    }
  }, []);

  const handleChangeStatus = (value: string) => {
    setDefaultActive(value);
  };

  useEffect(() => {
    if (isFilterCompany === false) {
      getUsers(defaultActive === 'ACTIVE', textSearch, '1', defaultSelected);
    } else if (isFilterCompany === true) {
      getUsers(
        defaultActive === 'ACTIVE',
        textSearch,
        '1',
        `${authUser.user.company.id}`,
      );
    }
  }, [defaultActive]);

  const handleChange = (companyId: string) => {

    if (companyId === 'ALL') {
      setDefaultSelected(companyId);
      getUsers(defaultActive === 'ACTIVE', textSearch, '1', companyId);
    } else {
      const company = companies.find((f) => `${f.id}` === companyId);

      if (company) {
        setDefaultSelected(companyId);
        setCompanyGlobal(company);
  
        getUsers(defaultActive === 'ACTIVE', textSearch, '1', companyId);
      }
    }

    
  };

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>

          {/* <TutoComponent
            link='https://www.loom.com/share/870f33b190474f5db28646229b9328cc?sid=74a9c32b-daa6-45e2-ac93-f09df62de84f'
          /> */}

          {authUser.user.role.name === 'SUPER_MASTER' ? (
            <>
              {authUser.user.role?.permissions?.user_company_change ? (
                <Select
                  loading={loading}
                  placeholder="Seleccione compañía"
                  style={{ minWidth: '180px' }}
                  onChange={handleChange}
                  showSearch
                  value={defaultSelected}
                  optionFilterProp="label"
                  filterOption={(input, option) =>
                    (option?.label as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  <Option key="ALL" value="ALL" label="TODOS">
                    TODOS
                  </Option>
                  {companies.map((company) => (
                    <Option
                      key={`${company.id}`}
                      value={`${company.id}`}
                      label={company.name}
                    >
                      {company.name}
                    </Option>
                  ))}
                </Select>
              ) : null}
            </>
          ) : null}

          <Button type="primary" onClick={handleOpenDrawer}>
            Agregar usuario
          </Button>

          <Search
            placeholder="Buscar por usuario y teléfono."
            onSearch={onSearch}
            enterButton
            style={{ width: 304 }}
            allowClear
          />
          <div>|</div>
          <Select
            optionFilterProp="label"
            style={{ minWidth: '130px' }}
            onChange={handleChangeStatus}
            value={defaultActive}
            filterOption={(input, option) =>
              (option?.label as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Option key="ACTIVE" value="ACTIVE" label={'ACTIVO'}>
              ACTIVO
            </Option>
            <Option key="INACTIVE" value="INACTIVE" label={'INACTIVO'}>
              INACTIVO
            </Option>
          </Select>
        </div>
      </PageHeader>
      <UsersListTable
        users={response}
        loading={loading}
        handlePagination={handlePagination}
        count={count}
        currentPage={page}
      />
    </>
  );
};

export default UsersMaintContainer;
