/* eslint-disable max-len */
import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { mapToOrderCreateRequest, mapToOrderUpdateRequest } from './order-update.mapper'
import { IOrderUpdateResponse } from './order-update.interface'
import { IOrderUpdateSchema } from './order-update.schema'
import { useOrderStore } from '../order-list/order.store'
import { useCompanyStore } from '../company-create/company-create.service'
import { message } from 'antd'
import { datadogLogs } from '../../hooks/use-datadog'
import { useEffect } from 'react'
import { useUserMaster } from '../order-create/order.edit'

export const useOrderUpdate = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { orderSelected } = useOrderStore()
  const { execute, response, loading } = useAxios<IOrderUpdateResponse>()
  const { isMaster } = useUserMaster()

  const orderUpdate = (order: IOrderUpdateSchema) => {

    if (order.orderDetails.length === 0 && order.callStatus === 'CONFIRMED') {
      message.warning('Agregar por lo menos un producto')

      return
    }

    if (`${order.customer.phone}`.length < 11 && order.callStatus === 'CONFIRMED' && !order.shipping.shippingByAgency) {
      message.warning('Registre un número telefónico válido, agrega el 51 adelanté del número ejem: 51987458626, o verifica si los números están completos')

      return
    }

    if (order.orderDetails.filter(e => e.action !== 'delete').length > 0 && order.callStatus === 'CONFIRMED') {
      if (!order.shipping.shippingByAgency && !isMaster) {
        const total = order.orderDetails.filter(e => e.action !== 'delete').length ? 
          order.orderDetails?.filter(t => t.action !== 'delete')
            .map(p => p.subtotal)
            .reduce((a, b) => a + b)
          : 0

        const dropCost = order.orderDetails.filter(e => e.action !== 'delete').length ? 
          order.orderDetails?.filter(t => t.action !== 'delete')
            .map(p => p.dropPrice * p.quantity)
            .reduce((a, b) => a + b)
          : 0

        const sCost = () => {
          const department = order.shipping.departmentId || 0
      
          if (department === 0) return 0
          if (authUser.user.company.countryCode === 'PER') {
            return parseFloat(String(order.shippingCost))
          } else if (authUser.user.company.countryCode === 'BOL') {
            return parseFloat(String(order.shippingCost))
          } else if (authUser.user.company.countryCode === 'MEX') {
            return parseFloat(String(order.shippingCost))
          } else {
            return order.shippingCost ? parseInt(String(order.shippingCost)) 
              : authUser.user.company.setting.amountDelivery
          }
        }

        if (total < (sCost() + dropCost + 0)) {
          message.warning(`Para agendar su pedido recuerde que el monto a cobrar mínimo es ${(sCost() + dropCost + 1.5).toFixed(2)}, lo cual cubre el costo de envío, mercadería y comisión de plataforma ℹ`)

          return
        }

      }
    }

    const method = orderSelected?.id ? 'PATCH' : 'POST'
    const url = orderSelected?.id ? `/order/${orderSelected?.id}` : '/order'
    let orderRequest = null

    if (orderSelected?.id) {
      orderRequest = mapToOrderUpdateRequest(
        authUser,
        order,
      )

      datadogLogs.logger.info('ORDER_UPDATE', { action: 'ORDER_UPDATE', order: orderRequest,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        },
        orderStatus: orderRequest.status,
        orderId: orderSelected?.id
      })

    } else {
      orderRequest = mapToOrderCreateRequest(
        authUser,
        order,
        companyGlobal
      )

      datadogLogs.logger.info('ORDER_CREATE', { action: 'ORDER_CREATE', order: orderRequest,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        },
        orderStatus: orderRequest.status,
        orderNumber: orderRequest.orderNumber
      })

    }

    execute({
      method,
      url,
      data: orderRequest,
    })
  }

  useEffect(() => {
    if (response) {
      const action = orderSelected?.id ? 'ORDER_UPDATE:POST' : 'ORDER_CREATE:POST'

      datadogLogs.logger.info(action, { action: action,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        },
        orderResponse: response,
        orderId: response?.id
      })

    }
  }, [response])

  return {
    orderUpdate,
    orderUpdated: response,
    loading,
  }
}
