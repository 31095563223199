import { useAxios } from '../../hooks'
import { IVoucherCreateRequest, IVoucherCreateResponse } from './voucher-create.interface'
import { IVoucherCreateSchema } from './voucher-create.schema'

export const useVoucherCreate = () => {
  const { execute, response, loading } = useAxios<IVoucherCreateResponse>()

  const voucherCreate = (voucher: IVoucherCreateSchema) => {
    
    const request: IVoucherCreateRequest = {
      amount: voucher.amount,
      entity: voucher.entity,
      paymentMethod: voucher.paymentMethod,
      liquidationId: voucher.liquidationId,
      voucherUrl: voucher.voucherUrl,
      operationCode: voucher.operationCode,
    }

    const method = 'POST'
    const url = '/liquidation/evidence/create'

    execute({
      method: method,
      url: url,
      data: request,
    })
  }

  return {
    voucherCreate,
    response,
    loading,
  }
}
