import React, { FC, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Select, Typography } from 'antd';
import { ISignUpSchema } from '../../../../services/sign-up/sign-up.schema';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TermsAndConditionModal } from '../../modals/TermsAndConditionModal';
import { CountryPhoneFormItem } from '../../../../modules/country-phone/containers';
import style from '../SignInForm/SignInForm.module.css'
import signupstyle from './SignUpForm.module.css'
import { HowToUseButton } from '../../components/HowToUseButton/HowToUseButton';

interface ISignUpFormProps {
  loading: boolean;
  onSubmit: (data: ISignUpSchema) => void;
}

const SignUpForm: FC<ISignUpFormProps> = ({ loading, onSubmit }) => {
  const [form] = Form.useForm<ISignUpSchema>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const codeLink = localStorage.getItem('codeReference');

  const handleCheckboxChange = (evt: CheckboxChangeEvent) => {
    if (evt.target.checked) {
      setIsModalVisible(true);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
    form.setFieldValue(['acceptTerms'], false);
  };
  
  useEffect(() => {
    if (codeLink && codeLink.length > 0) {
      form.setFieldValue(['referenceCode'], codeLink);
    } else {
      form.setFieldValue(['referenceCode'], null);
    }
  }, [codeLink]);

  return (
    <>
      <Form
        layout="horizontal"
        form={form}
        name="RegisterForm"
        labelWrap
        onFinish={onSubmit}
        initialValues={{
          country: 'PER',
        }}
      >

        <Typography.Title level={3} className={signupstyle['title']} >Crear nueva cuenta</Typography.Title>
        <Typography.Title level={5} className={signupstyle['subtitle']}>
          Completa tus datos para crear tu cuenta
        </Typography.Title>

        <Form.Item label={<span>País</span>} name={['country']}>
          <Select options={[{ label: 'Perú', value: 'PER' }]} />
        </Form.Item>

        <Form.Item
          label={<span>Nombre Ecommerce</span>}
          name={['companyName']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Input 
            className={style['button__container']}
          />
        </Form.Item>

        <Form.Item
          label={<span>Usuario</span>}
          name={['email']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Input 
            className={style['button__container']}
            placeholder="A-JHONNATAN-WBX"
          />
        </Form.Item>

        <Form.Item
          label={<span>Contraseña</span>}
          name={['password']}
          hasFeedback
          rules={[
            { required: true, message: 'Obligatorio' },
            {
              pattern: /(?=.*[A-Z])/,
              message: 'Debe incluir al menos una mayúscula',
            },
            {
              pattern: /(?=.*\d)/,
              message: 'Debe incluir al menos un número',
            },
            {
              pattern: /.{6,}/,
              message: 'Debe tener al menos 6 caracteres',
            },
          ]}
        >
          <Input.Password
            className={style['button__container']}
            placeholder="Crea una contraseña"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item
          label={<span>Correo Electrónico</span>}
          name={['userMail']}
          hasFeedback
          rules={[
            {
              type: 'email',
              message: 'No es un correo electrónico válido',
            },
            { required: true, message: 'Obligatorio' },
          ]}
        >
          <Input 
            placeholder="mi@gmail.com"
            className={style['button__container']}
            />
        </Form.Item>

        <Form.Item
          label={<span>Nombres y Apellidos</span>}
          name={['fullname']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Input 
            className={style['button__container']}
          />
        </Form.Item>

        <CountryPhoneFormItem 
          form={form}
        />

        <Form.Item
          label="Código de su referente"
          name={['referenceCode']}
          hidden
          rules={[{ required: false, message: '' }]}
        >
          <Input 
            className={style['button__container']}
          />
        </Form.Item>

        <div
          
        >
          <Form.Item
            style={{ textAlign : 'center' }}
            name={['acceptTerms']}
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: 'Debes aceptar los términos y condiciones',
              },
            ]}
          >
            <Checkbox 
              onChange={(evt) => handleCheckboxChange(evt)}
              
            >
              Aceptar términos y condiciones
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button 
              className={style['button__login']}
              htmlType="submit" 
              block 
              loading={loading}
            >
              Continuar
            </Button>
          </Form.Item>
        </div>


        <TermsAndConditionModal
          open={isModalVisible}
          onAccept={handleOk}
          onCancel={handleCancel}
        />
      </Form>
      <HowToUseButton/>
    </>
  );
};

export default SignUpForm;
