import React, { FC } from 'react';
import { Button, Empty, Image } from 'antd';
import { TProductCatalogItemResponse } from '../../../../../services/product-global/product-global.interface';
import s from './ProductGrid.module.css';
import { useBreakpoint } from '../../../../../hooks';
import logo from '../ProductGrid/logo.png';

type TProductGridProps = {
  isLoading: boolean;
  products: TProductCatalogItemResponse[];
  onSelected: (product: TProductCatalogItemResponse) => void;
};

export const ProductGrid: FC<TProductGridProps> = ({
  isLoading,
  products,
  onSelected,
}) => {
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 'xs';

  if (!isLoading && !products.length) {
    return <Empty description="No tenemos productos para mostrar" />;
  }
  
  console.log(products);
  
  return (
    <>
      <div className={s.ProductGridContainer}>
        {products.map((p) => (
          <div
            key={p.id}
            className={`${s.ProductCard} ${isMobile ? s.ProductCardMobile : ''}`}
          >
            <Image
              alt={p.name}
              src={p.urlImage}
              loading="lazy"
              className={`${s.ProductCardImage} ${isMobile ? s.ProductCardImageMobile : ''}`}
              onError={(e) => e.currentTarget.src = logo}
            />

            <div
              className={`${s.ProductCardBodyContainer} ${isMobile ? s.ProductCardBodyContainerMobile : ''}`}
            >
              <div className={s.ProductCardBody}>
                <div className={s.ProductProvider}>
                  Proveedor: <span>{p.skus[0].company.name}</span>
                </div>
                  Almacen: <span>{p.skus[0]?.warehouseSkus[0]?.warehouse?.name}</span>
                <div className={s.ProductName}>{p.name}</div>

                <div className={s.ProductPrice}>
                  <div>
                    <span>Proveedor:</span>
                    <div className={s.ProductPrice__price}>
                      {p.skus[0].currency.symbol} {p.skus[0].salePriceDrop.toFixed(2)}
                    </div>
                  </div>

                  <div>
                    <span>Sugerido:</span>
                    <div className={s.ProductPrice__price}>
                      {p.skus[0].currency.symbol}{' '}
                      {p.retailPriceSuggested.toFixed(2)}
                    </div>
                  </div>
                </div>

                <div className={s.ProductStock}>
                  Stock: <b>{p.stockTotal}</b>
                </div>
              </div>

              <div className={s.ProductCardFooter}>
                <Button type="primary" onClick={() => onSelected(p)}>
                  Ver detalles
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
