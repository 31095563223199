import React from 'react';
import './ModalVideo.css';
import { CloseIcon } from '../../../../components/Icons';

type ModalVideoProps = {
  link: string;
  onClose: () => void;
};

export const ModalVideo = ({ link, onClose }: ModalVideoProps) => {
  return (
    <div className="modal-video">
      <div className="modal-video__wrapper">
        <button className="modal-video__close" onClick={onClose}>
          <CloseIcon />
        </button>
        <iframe
          className="modal-video__iframe"
          src={link}
          allow="autoplay"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};
