/* eslint-disable max-len */
import React from 'react';

type PresentationChartIconProps = {
  width?: number;
  height?: number;
  fill?: string;
};

export const PresentationChartIcon = ({
  width = 50,
  height = 50,
  fill = '#000000',
}: PresentationChartIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke={fill}
      className="size-6"
      height={height}
      width={width}
      fill="#ffffff"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605"
      />
    </svg>
  );
};
