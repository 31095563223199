/* eslint-disable max-len */
import React, { FC } from 'react';
import { Table, Tag, Tooltip } from 'antd';
import useMeasureY from '../../../../../hooks/use-measurey';
import { IOrderItemResponse } from '../../../../../services/order-list/order.interface';
import { CALL_STATUS_LABEL } from '../../../../../services/pre-order/pre-order.params';
import {
  TRACKING_STATUS_LABEL,
  TRACKING_STATUS_LIST,
} from '../../../../../services/order-create/order.params';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { useOrderStore } from '../../../../../services/order-list/order.store';
import { ColumnsType } from 'antd/lib/table';
import { useModal } from '../../../../../contexts/ModalContext';
import {
  DollarOutlined,
  DownloadOutlined,
  FileDoneOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { formatDateUTC } from '../../../../../utils/date';
import { PAYMENT_TYPE_LABEL } from '../../../../../services/payment-create/payment.params';
import {
  Document,
  Image,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { useTemplateMsg } from '../../../../../services/templateMsg/template.service';
import { useAuthStore } from '../../../../../services/auth/auth.service';

interface IOrderAgencyTableProps {
  data: IOrderItemResponse[] | undefined;
  loading: boolean;
  handlePagination: (page: number) => void;
  count: number;
  currentPage: number;
}

const OrderAgencyTable: FC<IOrderAgencyTableProps> = ({
  data,
  loading,
  handlePagination,
  count,
  currentPage,
}) => {
  const { windowSize } = useMeasureY();
  const { openDrawer } = useDrawer();
  const { setOrderSelected } = useOrderStore();
  const { openModal } = useModal();
  const {
    getMessageAgencyRegistered,
    getMessageAgencyInDestination,
    getMessageAgencyInAgency,
  } = useTemplateMsg();
  const { authUser } = useAuthStore();

  const handleSelectedOrder = (order: IOrderItemResponse) => {
    setOrderSelected(order);
    openDrawer('ORDER_AGENCY_EDIT');
  };

  const handleOrderPayments = (order: IOrderItemResponse) => {
    setOrderSelected(order);
    openModal('ORDER_AGENCY_PAYMENTS', 'Gestionar Pagos');
  };

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
    },
    row: {
      flexGrow: 1,
      fontSize: 5,
      flexDirection: 'row',
    },
    col1: {
      width: '40%',
    },
    text: {
      width: '60%',
    },
    text1: {
      width: '100%',
    },
    text3: {
      width: '100%',
      fontSize: 8,
    },
    textRigth: {
      textAlign: 'right',
    },
    textRigth1: {
      textAlign: 'right',
      fontSize: 8,
    },
    textMedia: {
      width: '50%',
    },
    textHeader: {
      width: '100%',
      fontSize: 8,
      fontWeight: 'ultrabold',
      paddingBottom: 2,
    },
    section1: {
      borderColor: '#000',
      borderWidth: '0.5px',
      borderRadius: '5px',
      borderStyle: 'solid',
      marginTop: 4,
      marginLeft: 8,
      marginRight: 8,
      padding: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section: {
      marginTop: 8,
      marginLeft: 8,
      marginRight: 8,
      paddingTop: 8,
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section2: {
      marginTop: 2,
      marginLeft: 8,
      marginRight: 8,
      padding: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section3: {
      marginTop: 0,
      marginLeft: 8,
      marginRight: 8,
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    textCenter: {
      fontSize: 10,
      textAlign: 'center',
      fontWeight: 'ultrabold',
    },
  });

  const DocPdf = (order: IOrderItemResponse) => {
    return (
      <Document>
        {order ? (
          <>
            (
            <Page size={[162, 162]} key={order.orderNumber}>
              <View style={styles.section}>
                <View style={styles.row}>
                  <Text style={styles.textHeader}>
                    {order.user?.company?.name}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.text}>
                    Destino: {order.shipping.departmentName} /{' '}
                    {order.shipping.provinceName} /{' '}
                    {order.shipping.districtName}
                  </Text>
                  <View style={styles.col1}>
                    <Text style={styles.textRigth1}>{order.orderNumber}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.section1}>
                <View style={styles.row}>
                  <View style={{ width: '70%' }}>
                    <View style={styles.row}>
                      <Text style={styles.text1}>
                        Cliente: {order.customerHistory.name}{' '}
                        {order.customerHistory.lastName}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.text3}>
                        Documento: {order.shipping.contactDocumentNumber || ''}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.text1}>
                        Cel: {order.customerHistory.phone}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.text1}>
                        Dirección: {order.shipping.address1}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.text1}>
                        Referencia: {order.shipping.reference}
                      </Text>
                    </View>
                  </View>
                  <View style={{ width: '30%' }}>
                    <Image
                      source={'https://aliclikapp-api.herokuapp.com/order-public/generate-qr'.concat(
                        `/${order.orderNumber}`,
                      )}
                    />
                  </View>
                </View>
              </View>
              <View style={styles.section1}>
                <View style={styles.row}>
                  <Text style={styles.text}>Producto(s):</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.text1}>
                    {order.orderDetails?.map((o) => {
                      return `${o.quantity} ${o.sku.product?.name} ${o.sku.skuOptions?.map((op) => op.option?.name).join('')}`.concat(
                        ` - ${o.sku.company.setting.companyPrefix}`,
                      );
                    })}
                  </Text>
                </View>
              </View>
            </Page>
            )
          </>
        ) : null}
      </Document>
    );
  };

  const columnsLevel: ColumnsType<IOrderItemResponse> = [
    {
      title: 'Order',
      dataIndex: 'id',
      width: 100,
      align: 'center',
      render: (_, order) => (
        <>
          <a onClick={() => handleSelectedOrder(order)}>{order.orderNumber}</a>
          <br></br>
          {order.shipping.attachFile ? (
            <>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${order.shipping.attachFile}`}
              >
                <Tooltip placement="bottom" title="Abrir Pdf">
                  <FileDoneOutlined className="PreOrderActionIcons-blue-light" />
                </Tooltip>
              </a>
            </>
          ) : (
            <></>
          )}
          {order.guideSecundary ? (
            <>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${order.guideSecundary}`}
              >
                <Tooltip placement="bottom" title="Abrir Guía">
                  <FileDoneOutlined className="PreOrderActionIcons-blue" />
                </Tooltip>
              </a>
            </>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: 'Tipo Pago',
      dataIndex: 'paymentType',
      width: 100,
      align: 'center',
      render: (_, order) => (
        <>
          {order.paymentType
            ? PAYMENT_TYPE_LABEL[order.paymentType].label
            : '-'}
          <br />
          {order.trackingStatus === TRACKING_STATUS_LIST.REGISTERED ? (
            <>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://api.whatsapp.com/send?phone=${order.customerHistory.phone}&text=${getMessageAgencyRegistered(order)}`}
              >
                <Tooltip placement="bottom" title="WhatsApp">
                  <WhatsAppOutlined className="OrderActionIcons-green" />
                </Tooltip>
              </a>
            </>
          ) : null}
          {order.trackingStatus === TRACKING_STATUS_LIST.LEAVE_IN_AGENCY ? (
            <>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://api.whatsapp.com/send?phone=${order.customerHistory.phone}&text=${getMessageAgencyInAgency(order)}`}
              >
                <Tooltip placement="bottom" title="WhatsApp">
                  <WhatsAppOutlined className="OrderActionIcons-green" />
                </Tooltip>
              </a>
            </>
          ) : null}
          {order.trackingStatus === TRACKING_STATUS_LIST.DESTINATION ? (
            <>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://api.whatsapp.com/send?phone=${order.customerHistory.phone}&text=${getMessageAgencyInDestination(order)}`}
              >
                <Tooltip placement="bottom" title="WhatsApp">
                  <WhatsAppOutlined className="OrderActionIcons-green" />
                </Tooltip>
              </a>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: 'Monto a depositar',
      dataIndex: 'payAgency',
      width: 100,
      align: 'center',
      render: (_, order) => (
        <>
          {order.payAgency &&
          order.payAgency > 0 &&
          order.managementType &&
          order.managementType === 'PARTNER' ? (
            <>
              <b>S/{order.payAgency?.toFixed(0)}</b>
            </>
          ) : null}
          <br></br>
          {order.voucherPayAgency && order.voucherPayAgency.length > 0 ? (
            <>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${order.voucherPayAgency}`}
                style={{ marginLeft: 5 }}
              >
                <Tooltip placement="bottom" title="Ver voucher">
                  <FileDoneOutlined className="PreOrderActionIcons-green" />
                </Tooltip>
              </a>
            </>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: 'Fecha',
      dataIndex: 'creadtedAt',
      width: 80,
      align: 'center',
      render: (_, order) => (
        <>{order.createdAt ? formatDateUTC(order.createdAt) : '-'}</>
      ),
    },
    {
      title: 'Pagos',
      dataIndex: 'delivery',
      width: 90,
      align: 'center',
      render: (_, order) => (
        <>
          <b>
            &#128181; {order.currency?.symbol} {order.total.toFixed(2)}
          </b>
          <br />
          AD:{' '}
          {order.totalTransfer > 0
            ? `${order.totalTransfer.toFixed(2)} `
            : '- '}
          <br />
          SD: {order.currency?.symbol}{' '}
          {order.totalPayment > 0
            ? (order.total - order.totalPayment).toFixed(2)
            : '-'}
          <br />
          <a onClick={() => handleOrderPayments(order)}>
            <Tooltip placement="bottom" title="Agregar pagos">
              <DollarOutlined
                style={{
                  fontSize: '1.1rem',
                  margin: '0 .25rem',
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </a>
          <>
            <PDFDownloadLink
              document={DocPdf(order)}
              fileName={`${order.orderNumber}.pdf`}
            >
              {() => (
                <Tooltip placement="bottom" title="Rótulo">
                  <DownloadOutlined
                    style={{
                      fontSize: '1.1rem',
                      margin: '0 .25rem',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              )}
            </PDFDownloadLink>
          </>
        </>
      ),
    },
    {
      title: 'Trazabilidad',
      width: 100,
      align: 'center',
      render: (
        _,
        {
          callStatus,
          trackingStatus,
          managementType,
          warehouseName,
          warehousePhone,
        },
      ) => (
        <>
          <Tag color={CALL_STATUS_LABEL[callStatus]?.color}>
            {CALL_STATUS_LABEL[callStatus]?.label}
          </Tag>
          <Tag color={TRACKING_STATUS_LABEL[trackingStatus || '']?.color}>
            {TRACKING_STATUS_LABEL[trackingStatus || '']?.label}
          </Tag>
          {managementType === 'PARTNER' ? (
            <>
              <Tag color="#673AB7">{warehouseName}</Tag>
              {warehousePhone &&
              warehousePhone.length > 0 &&
              authUser.user.role.name !== 'STORE' ? (
                <>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://api.whatsapp.com/send?phone=${warehousePhone}`}
                  >
                    <Tooltip placement="bottom" title="WhatsApp almacén">
                      <WhatsAppOutlined className="OrderActionIcons-green" />
                    </Tooltip>
                  </a>
                </>
              ) : null}
            </>
          ) : null}
        </>
      ),
    },
    {
      title: 'Vendedor',
      dataIndex: 'vendor',
      align: 'center',
      width: 80,
      render: (_, { user }) => (
        <>
          {user.fullname}
          <br></br>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://api.whatsapp.com/send?phone=${user.phone}`}
          >
            <Tooltip placement="bottom" title="WhatsApp">
              <WhatsAppOutlined className="OrderActionIcons-green" />
            </Tooltip>
          </a>
        </>
      ),
    },
    {
      title: 'Destino',
      dataIndex: 'address',
      width: 100,
      align: 'center',
      render: (_, { shipping }) => (
        <>
          {shipping?.departmentName}
          <br></br>
          {shipping?.provinceName}
          <br></br>
          {shipping?.districtName}
        </>
      ),
    },
    {
      title: 'Nota',
      width: 140,
      align: 'center',
      render: (_, { note }) => <>{note}</>,
    },
    {
      title: 'Products',
      dataIndex: 'orderDetails',
      width: 180,
      align: 'center',
      render: (_, { orderDetails, productShopifyDetail }) => (
        <>
          {orderDetails && orderDetails.length > 0 ? (
            <>
              {orderDetails?.map((order) => (
                <div key={order.id}>
                  {order.quantity} {order.sku.product?.name}
                  {order.sku.skuOptions
                    ?.filter((e) => e.option?.name !== '_DEFAULT')
                    .map((op) => (
                      <span key={op.id}>
                        <span> / </span>
                        {op.option?.name}
                      </span>
                    ))}
                </div>
              ))}
            </>
          ) : (
            <>{productShopifyDetail || ''}</>
          )}{' '}
        </>
      ),
    },
    {
      title: 'Guía',
      width: 80,
      align: 'center',
      render: (_, { shipping }) => (
        <>
          {shipping.guideNumber ? `${shipping.guideNumber}` : '-'}
          <br></br>
          {shipping.agencyName ? `${shipping.agencyName}` : '-'}
        </>
      ),
    },
    {
      title: 'Doc.',
      dataIndex: 'guide',
      width: 80,
      align: 'center',
      render: (_, { shipping }) => (
        <>
          {shipping.contactDocumenType ? `${shipping.contactDocumenType}` : '-'}
          <br></br>
          {shipping.contactDocumentNumber
            ? `${shipping.contactDocumentNumber}`
            : '-'}
        </>
      ),
    },
    {
      title: 'Cliente',
      dataIndex: 'customerHistory',
      width: 120,
      align: 'center',
      render: (_, { customerHistory }) => (
        <>
          {customerHistory?.name} {customerHistory?.lastName}
          <br></br>
          {customerHistory?.phone}
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columnsLevel}
        dataSource={data}
        pagination={{
          pageSize: 10,
          current: currentPage,
          total: count,
          simple: true,
          onChange(page) {
            handlePagination(page);
          },
        }}
        scroll={{
          y: windowSize.height - 200,
        }}
        size="small"
        loading={loading}
      />
    </>
  );
};

export default OrderAgencyTable;
