import React from 'react';
import './HeroSection.css';
import ali from './../../../../assets/images/ali.png';
import HeroCounter from './HeroCounter';

export const HeroSection = () => {
  return (
    <div className="hero-section" id="hero-section">
      <div className="hero-section__content">
        <div className="hero-section__wrapper-text">
          <h2 className="hero-section__title animate__animated animate__bounceInLeft">
            La plataforma de Ecommerce y Dropshipping N°1 del Perú
          </h2>
          <p className="hero-section__description">
            Potenciamos tu negocio con logística eficiente, miles de productos y
            la <strong>cobertura contraentrega más grande del Perú.</strong>{' '}
            Vende más, crece sin límites y accede a las mejores tarifas del
            mercado
          </p>
          <a className="hero-section__button" href="/login?register=true" target="_blank">
            Regístrate gratis
          </a>
        </div>
        <div className="hero-section__wrapper-images animate__animated animate__bounceInRight">
          <img
            src={ali}
            alt="hero"
            loading="lazy"
            className="images__landing hero-section__image"
          />
        </div>
      </div>
      <div className="container__landing">
        <HeroCounter/>
      </div>
    </div>
  );
};
