import React, { useEffect, useState } from 'react';
import { Button, Input, Select, Space } from 'antd';
import { PageHeader } from '../../../../../components';
import { CompanyChangeContainer } from '../../../../../containers';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { useCompanyStore } from '../../../../../services/company-create/company-create.service';
import {
  useProductsAll,
  useProductsWarehouseAll,
} from '../../../../../services/product/product.service';
import { useProductStore } from '../../../../../services/product/product.store';
import { ProductsListTable } from '../../components';
import { useProductEdit } from '../../../../../services/product/product.edit';
import { useCategories } from '../../../../../services/category/category.service';
import { useWarehouseList } from '../../../../../services/warehouse-list/warehouse-list.service';
// import { TutoComponent } from '../../../../../components/TutoComponent/TutoComponent';

const ProductsMaintContainer = () => {
  const { openDrawer } = useDrawer();
  const { authUser } = useAuthStore();
  const { getProducts, products, loading, count, currentPage } =
    useProductsAll();
  const {
    getProductsToWarehouse,
    productsWar,
    loadingProds,
    countProds,
    currentPageProds,
  } = useProductsWarehouseAll();
  const { getCategories, categories, loadingCategory } = useCategories();
  const { setProductSelected, productUpdated } = useProductStore();
  const { getWarehouses, warehouses } = useWarehouseList();

  const { companyGlobal } = useCompanyStore();
  const { isEditable } = useProductEdit();
  const [search, setSearch] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('ALL');
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<string>('ALL');
  const [showAddProd, setShowAddProd] = useState(true)

  const handleOpenDrawer = () => {
    setProductSelected(null);
    openDrawer('PRODUCT_EDIT', 'Agregar producto');
  };

  const handlePagination = (page: number) => {
    if (authUser.user.role.name === 'STORE') {
      getAllProductsToWarehouseByPage({ page });
    } else {
      getAllProductsByPage({ page });
    }
  };

  const getAllProductsToWarehouseByPage = (params: { page: number }) => {
    getProductsToWarehouse({
      page: params.page,
      search,
      categoryId: selectedCategoryId === 'ALL' ? '' : selectedCategoryId,
      companyId: companyGlobal ? `${companyGlobal.id === 0 ? '' : companyGlobal.id}` : '',
    });
  };

  const getAllProductsByPage = (params: { page: number }) => {
    getProducts({
      companyId: companyGlobal ? (companyGlobal.id === 0 ? null : `${companyGlobal.id}`) : null,
      page: params.page,
      search: search,
      categoryId: selectedCategoryId === 'ALL' ? '' : selectedCategoryId,
      warehouseId: selectedWarehouseId === 'ALL' ? '' : selectedWarehouseId,
    });
  };

  useEffect(() => {
    getCategories();
    getWarehouses('-1');

    if (companyGlobal) {

      if (companyGlobal.id === 0) {
        setShowAddProd(true)
      } else {
        setShowAddProd(false)
      }
      
    } else {

      if (authUser.user.warehouse && authUser.user.warehouse.type && authUser.user.warehouse?.type === 'NORMAL') {
        setShowAddProd(false)
      } else {
        setShowAddProd(true)
      }
    }

    if (
      companyGlobal &&
      authUser.user.role.name === 'SUPER_MASTER'
    ) {
      getAllProductsByPage({ page: 1 });
    } else if (
      authUser.user.role.name === 'ADMIN_STORE' ||
      authUser.user.role.name === 'SELLER'
    ) {
      getProducts({
        companyId: `${authUser.user.company.id}`,
        page: 1,
        search: search,
        categoryId: selectedCategoryId === 'ALL' ? '' : selectedCategoryId,
        warehouseId: selectedWarehouseId === 'ALL' ? '' : selectedWarehouseId,
      });
    } else if (authUser.user.role.name === 'STORE') {
      getAllProductsToWarehouseByPage({ page: 1 });
    }
  }, [companyGlobal]);

  useEffect(() => {
    if (authUser.user.role.name === 'STORE') {
      getAllProductsToWarehouseByPage({ page: 1 });
    } else {
      getAllProductsByPage({ page: 1 });
    }
  }, [search, selectedWarehouseId, selectedCategoryId, productUpdated]);

  return (
    <>
      <PageHeader>
        <Space wrap>

          {/* <TutoComponent
            link='https://www.loom.com/share/0623465d2a1544a89b58f67b42d712d9?sid=c1d44b73-1466-4acd-a684-d8ca4b7e34af'
          /> */}

          {(authUser.user.role?.permissions?.product_company_change || 
          (authUser.user.warehouse && authUser.user.warehouse.type && authUser.user.warehouse?.type === 'PRINCIPAL')) && (
            <CompanyChangeContainer isAll={true} />
          )}

          { showAddProd === true ? <>
          </> : <>
          
            { authUser.user.role.name === 'STORE' ||
              authUser.user.role.name === 'SUPER_MASTER' ? <>
              
                <Button type="primary" onClick={handleOpenDrawer}>
                  Agregar producto
                </Button>

              </> : null
            }
          
          </>}

          <Input.Search
            placeholder="Buscar producto"
            onSearch={setSearch}
            enterButton
            style={{ width: 304 }}
          />

          <Space>
            <span>Categorías: </span>
            <Select
              loading={loadingCategory}
              style={{ minWidth: '150px' }}
              value={selectedCategoryId}
              onChange={setSelectedCategoryId}
              showSearch
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              <Select.Option key="ALL" value="ALL" label={'Todos'}>
                Todos
              </Select.Option>
              {categories.map((c) => {
                return (
                  <Select.Option key={c.id} value={c.id} label={c.name}>
                    {c.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Space>

          {authUser.user.role.name === 'SUPER_MASTER' && (
            <Space>
              <span
                style={{ margin: 0, marginLeft: '12px', alignSelf: 'center' }}
              >
                Almacén:
              </span>
              <Select
                placeholder="Almacenes"
                style={{ minWidth: '150px' }}
                value={selectedWarehouseId}
                onChange={setSelectedWarehouseId}
                showSearch
                filterOption={(input, option) =>
                  (option?.label as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Select.Option key="ALL" value="ALL" label={'Todos'}>
                  Todos
                </Select.Option>
                {warehouses.map((key) => (
                  <Select.Option key={key.id} value={key.id} label={key.name}>
                    {key.name}
                  </Select.Option>
                ))}
              </Select>
            </Space>
          )}
        </Space>
      </PageHeader>

      <ProductsListTable
        products={
          authUser.user.role.name === 'STORE'
            ? productsWar
              ? productsWar
              : []
            : products
              ? products
              : []
        }
        loading={authUser.user.role.name === 'STORE' ? loadingProds : loading}
        isEditable={isEditable}
        handlePagination={handlePagination}
        count={authUser.user.role.name === 'STORE' ? countProds : count}
        currentPage={
          authUser.user.role.name === 'STORE' ? currentPageProds : currentPage
        }
      />
    </>
  );
};

export default ProductsMaintContainer;
