import { PictureOutlined } from '@ant-design/icons'
import { Button, Image } from 'antd'
import React, { useState } from 'react'

interface IImageProps {
  url: string
}

const ImagePreview: React.FC<IImageProps> = ({ url }) => {
  const [isPreviewVisible, setPreviewVisible] = useState(false)

  return (
    <>
      <Image
        style={{ display: 'none' }}
        preview={{
          visible: isPreviewVisible,
          onVisibleChange: (visible, prevVisible) => setPreviewVisible(visible),
        }}
        src={url}
      />
      <Button onClick={() => setPreviewVisible(!isPreviewVisible)}>
        <PictureOutlined />
      </Button>
    </>
  )
}

export default ImagePreview
