import React, { useEffect, useState } from 'react';
import { Spin, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import useMeasureY from '../../../../../hooks/use-measurey';
import { IDispatchGuideResponse } from '../../../../../services/dispatch-guide-list/dispatch-guide-list.interface';
import { useDispatchGuideStore } from '../../../../../services/dispatch-guide-create/dispatch-guide.store';
import { formatDateUTCByCountry } from '../../../../../utils/date';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  OrderedListOutlined,
} from '@ant-design/icons';
import { useModal } from '../../../../../contexts/ModalContext';
import { SummaryProductListTable } from './parts';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { useDispatchGuideDelete } from '../../../../../services/dispatch-guide-delete/dispatch-guide-delete.service';
import { DISPATCH_STATUS_LIST } from '../../../../../services/dispatch-guide-create/dispatch.params';
import {
  Document,
  Image,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import {
  useOrderDetailsByGuide,
  useOrdersByGuide,
} from '../../../../../services/dispatch-guide-list/dispatch-guide-list.service';
import { ORDER_DISPATCH_STATUS_LIST } from '../../../../../services/order-create/order.params';
import ImagePreview from '../ImagePreview/ImagePreview';

interface IDispatchGuideListTableProps {
  dispatchGuides: IDispatchGuideResponse[];
  loading: boolean;
  handlePagination: (page: number) => void;
  count: number;
  currentPage: number;
}

const DispatchGuideListTable: React.FC<IDispatchGuideListTableProps> = ({
  dispatchGuides,
  loading,
  handlePagination,
  count,
  currentPage,
}) => {
  const { windowSize } = useMeasureY();
  const { openDrawer } = useDrawer();
  const { openModal } = useModal();
  const { authUser } = useAuthStore();
  const { deleteDispatchGuide, loadingDelete, dispatchGuideDeleted } =
    useDispatchGuideDelete();
  const {
    setDispatchGuideSelected,
    setSummaryProductEdited,
    setDispatchGuideDeleted,
    dispatchGuideSelected,
  } = useDispatchGuideStore();
  const { getOrdersGuide, orders } = useOrdersByGuide();
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const { getOrderDetails, orderDetails } = useOrderDetailsByGuide();
  const [showOrderDetailsPdf, setShowOrderDetailsPdf] =
    useState<boolean>(false);
  const countryCode = authUser.user.company.countryCode || 'PER'

  const handleSelected = (data: IDispatchGuideResponse) => {
    setDispatchGuideSelected(data);
    openDrawer('DISPATCH_GUIDE_EDIT', 'Editar guía');
  };

  const handleSummaryProducts = (data: IDispatchGuideResponse) => {
    setDispatchGuideSelected(data);
    openModal('DISPATCH_SUMMARY', 'Resumen de productos');
  };

  const handleSummaryProductEdit = (data: IDispatchGuideResponse) => {
    setDispatchGuideSelected(data);
    setSummaryProductEdited([]);
    openModal(
      'SUMMARY_PRODUCTS_EDIT',
      `Editar productos despachados - ${data.guideNumber}`,
    );
  };

  const handleAddOrder = (data: IDispatchGuideResponse) => {
    setDispatchGuideSelected(data);
    openDrawer(
      'ADD_ORDER_TO_DISPATCH',
      `Agregar pedido a la guía ${data.guideNumber}`,
    );
  };

  const handleDeleteGuide = (data: IDispatchGuideResponse) => {
    setDispatchGuideSelected(data);
    deleteDispatchGuide(data.id);
  };

  useEffect(() => {
    if (orders && orders.length > 0) {
      setShowPdf(true);
    }
  }, [orders]);

  useEffect(() => {
    if (orderDetails && orderDetails.length > 0) {
      setShowOrderDetailsPdf(true);
    }
  }, [orderDetails]);

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
    },
    row: {
      flexGrow: 1,
      fontSize: 5,
      flexDirection: 'row',
    },
    col1: {
      width: '40%',
    },
    text: {
      width: '60%',
    },
    text1: {
      width: '100%',
    },
    rowCol: {
      flexDirection: 'row',
      flexGrow: 0,
      marginBottom: 8,
      marginRight: 12,
    },
    rowHeader: {
      flexDirection: 'row',
      flexGrow: 0,
      fontSize: 5,
      marginBottom: 12,
      marginLeft: 8,
      marginRight: 16,
    },
    textCol: {
      width: '25%',
      textAlign: 'center',
      fontSize: 8,
    },
    textRigth: {
      textAlign: 'right',
    },
    textRigth1: {
      textAlign: 'right',
      fontSize: 8,
    },
    textMedia: {
      width: '50%',
    },
    section1: {
      borderColor: '#000',
      borderWidth: '0.5px',
      borderRadius: '5px',
      borderStyle: 'solid',
      marginTop: 2,
      marginLeft: 8,
      marginRight: 8,
      marginBottom: 2,
      padding: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    sectionPdf: {
      marginTop: 16,
      marginLeft: 8,
      marginRight: 8,
      marginBottom: 16,
      paddingTop: 8,
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section: {
      marginTop: 2,
      marginLeft: 8,
      marginRight: 8,
      paddingTop: 8,
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section2: {
      marginTop: 2,
      marginLeft: 8,
      marginRight: 8,
      marginBottom: 0,
      padding: 4,
      fontSize: 6,
      fontWeight: 'light',
    },
    section3: {
      marginTop: 0,
      marginLeft: 8,
      marginRight: 8,
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: 6,
      fontWeight: 'light',
    },
    textCenter: {
      fontSize: 10,
      textAlign: 'center',
      fontWeight: 'ultrabold',
    },
    textCenter1: {
      fontSize: 8,
      width: '100%',
      textAlign: 'center',
      fontWeight: 'ultrabold',
    },
  });

  const handleOrdersGuide = (guide: IDispatchGuideResponse) => {
    setDispatchGuideSelected(guide);
    getOrdersGuide(guide.id);
  };

  const handleOrdersToPrepare = (guide: IDispatchGuideResponse) => {
    setDispatchGuideSelected(guide);
    getOrdersGuide(guide.id, ORDER_DISPATCH_STATUS_LIST.TO_PREPARE);
  };

  const handleOrderDetails = (guide: IDispatchGuideResponse) => {
    setDispatchGuideSelected(guide);
    getOrderDetails(guide.id);
  };

  const DocPdf = () => {
    return (
      <Document>
        {orders && orders.length > 0 ? (
          <>
            {orders.map((order) => (
              <Page size={[162, 162]} key={order.orderNumber}>
                <View style={styles.section}>
                  <View style={styles.row}>
                    <Text style={styles.textCenter1}>{order.province}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text}>{order.orderNumber}</Text>
                    <View style={styles.col1}>
                      <Text style={styles.textRigth}>{order.transporter}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.section1}>
                  <View style={styles.row}>
                    <View style={{ width: '70%' }}>
                      <View style={styles.row}>
                        <Text style={styles.text1}>
                          Almacén: {order.warehouse}
                        </Text>
                      </View>
                      <View style={styles.row}>
                        <Text style={styles.text1}>
                          #Guía: {order.guideNumber}
                        </Text>
                      </View>
                      <View style={styles.row}>
                        <Text style={styles.text}>Producto(s):</Text>
                      </View>
                      <View style={styles.row}>
                        <Text style={styles.text1}>{order.productDetail}</Text>
                      </View>
                    </View>
                    <View style={{ width: '30%' }}>
                      <Image
                        // eslint-disable-next-line max-len
                        source={'https://aliclikapp-api.herokuapp.com/order-public/generate-qr'.concat(
                          `/${order.orderNumber}`,
                        )}
                      />
                    </View>
                  </View>
                </View>
                <View style={styles.section1}>
                  <View style={styles.row}>
                    <Text style={styles.text}>
                      Monto a cobrar: {order.totalText}
                    </Text>
                    <View style={styles.col1}>
                      <Text style={styles.textRigth}>
                        Distrito: {order.district}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text}>
                      NOTA: {order.note}
                      {'\n'}
                      CLIENTE: {order.fullName}
                    </Text>
                  </View>
                </View>
                <View style={styles.section2}>
                  <View style={styles.row}>
                    <Text style={styles.textMedia}>
                      Fecha entrega: {formatDateUTCByCountry(countryCode, order.deliveryDate)}
                    </Text>
                    <Text style={styles.textMedia}>
                      Fecha despacho: {formatDateUTCByCountry(countryCode, order.dispatchDate)}
                    </Text>
                  </View>
                </View>
                <View style={styles.section3}>
                  <View style={styles.row}>
                    <Text style={styles.textMedia}>Reprogramado 1:</Text>
                    <Text style={styles.textMedia}>No contesta 1:</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.textMedia}>Reprogramado 2:</Text>
                    <Text style={styles.textMedia}>No contesta 2:</Text>
                  </View>
                </View>
              </Page>
            ))}
          </>
        ) : null}
      </Document>
    );
  };

  const DocOrderDetailsPdf = () => {
    const pageStyles = StyleSheet.create({
      page: {
        paddingTop: 40,
        paddingBottom: 40,
        paddingLeft: 40,
        paddingRight: 40,
        fontSize: 10,
      },
      header: {
        marginBottom: 20,
        paddingBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#cccccc',
      },
      headerTitle: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 10,
      },
      headerInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
        fontSize: 9,
      },
      tableHeader: {
        flexDirection: 'row',
        backgroundColor: '#f0f0f0',
        paddingVertical: 5,
        marginBottom: 5,
        borderBottomWidth: 1,
        borderBottomColor: '#cccccc',
      },
      tableHeaderText: {
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'center',
      },
      tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 0.5,
        borderBottomColor: '#e0e0e0',
        paddingVertical: 5,
      },
      tableRowText: {
        fontSize: 8,
        textAlign: 'center',
      },
      // para alinear texto a la izquierda
      tableRowTextLeft: {
        fontSize: 8,
        textAlign: 'left',
      }
    });
  
    return (
      <Document>
        <Page size="A4" style={pageStyles.page}>
          <View style={pageStyles.header}>
            <Text style={pageStyles.headerTitle}>Aliclik - Cargo de despacho</Text>
            
            <View style={pageStyles.headerInfo}>
              <Text>
                #Guía: {dispatchGuideSelected?.guideNumber}
              </Text>
              <Text>
                Origen: {dispatchGuideSelected?.provinceOriginName} / {dispatchGuideSelected?.departmentOriginName}
              </Text>
            </View>
            
            <View style={pageStyles.headerInfo}>
              <Text>
                Fecha de creación: {formatDateUTCByCountry(countryCode, dispatchGuideSelected?.createdAt)}
              </Text>
              <Text>
                Destino: {dispatchGuideSelected?.provinceName} / {dispatchGuideSelected?.departmentName}
              </Text>
            </View>
          </View>
  
          <View style={pageStyles.tableHeader}>
            <Text style={{ ...pageStyles.tableHeaderText, width: '15%' }}>#Pedido</Text>
            <Text style={{ ...pageStyles.tableHeaderText, width: '20%' }}>Almacén</Text>
            <Text style={{ ...pageStyles.tableHeaderText, width: '15%' }}>EAN</Text>
            <Text style={{ ...pageStyles.tableHeaderText, width: '35%' }}>Producto</Text>
            <Text style={{ ...pageStyles.tableHeaderText, width: '15%' }}>Cantidad</Text>
          </View>
  
          {orderDetails && orderDetails.length > 0 ? (
            orderDetails.map((order, index) => (
              <View 
                style={pageStyles.tableRow} 
                key={`${order.orderNumber}-${order.ean}-${index}`}
              >
                <Text style={{ ...pageStyles.tableRowText, width: '15%' }}>{order.orderNumber}</Text>
                <Text style={{ ...pageStyles.tableRowText, width: '20%' }}>{order.warehouseName}</Text>
                <Text style={{ ...pageStyles.tableRowText, width: '15%' }}>{order.ean}</Text>
                <Text style={{ ...pageStyles.tableRowTextLeft, width: '35%' }}>
                  {order.productName}
                </Text>
                <Text style={{ ...pageStyles.tableRowText, width: '15%' }}>
                  {order.quantity}
                </Text>
              </View>
            ))
          ) : (
            <View style={pageStyles.tableRow}>
              <Text style={{ ...pageStyles.tableRowText, width: '100%' }}>
                No hay detalles de pedidos disponibles
              </Text>
            </View>
          )}
        </Page>
      </Document>
    );
  };

  useEffect(() => {
    if (dispatchGuideDeleted) {
      setDispatchGuideDeleted(dispatchGuideDeleted);
    }
  }, [dispatchGuideDeleted]);

  const columns: ColumnsType<IDispatchGuideResponse> = [
    {
      title: '#Guía',
      dataIndex: 'guideNumber',
      align: 'center',
      width: 120,
      render: (_, guide) => (
        <>
          <a onClick={() => handleSelected(guide)}>{guide.guideNumber} </a>
          {guide.isValidated ? (
            <>
              <CheckCircleOutlined />
            </>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: 'Pedidos Asignados',
      dataIndex: 'order',
      align: 'center',
      width: 350,
      render: (_, guide) => (
        <>
          {guide.ordersDispatched && guide.ordersDispatched.length > 0 ? (
            <>
              {guide.ordersDispatched.map((o) => {
                if (o.isNew)
                  return (
                    <>
                      <Tag color="#03A9F4">{o.orderNumber}</Tag>
                    </>
                  );

                if (o.isRescheduled)
                  return (
                    <>
                      <Tag color="#ff9800">{o.orderNumber}</Tag>
                    </>
                  );

                return (
                  <>
                    <Tag>{o.orderNumber}</Tag>
                  </>
                );
              })}
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Estado',
      dataIndex: 'dispatchStatus',
      align: 'center',
      render: (_, guide) => (
        <>
          {guide.dispatchStatus}
          {guide.ordersDispatched && guide.ordersDispatched.length > 0 ? (
            <>
              <br></br>#{guide.ordersDispatched.length}
            </>
          ) : null}
        </>
      ),
    },
    {
      title: 'Prov. / Dpto. Origen',
      dataIndex: 'departmentOriginName',
      align: 'center',
      render: (_, guide) => (
        <>
          {guide.provinceOriginName} / {guide.departmentOriginName}
          <br></br>
          {guide.guideCode || '-'}
          <br></br>
          {guide.photoMerchandiseOrigin &&
          guide.photoMerchandiseOrigin.length > 0 ? (
            <>
              <ImagePreview url={guide.photoMerchandiseOrigin}></ImagePreview>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: 'Prov. / Dpto. Destino',
      dataIndex: 'departmentName',
      align: 'center',
      render: (_, guide) => (
        <>
          {guide.provinceName} / {guide.departmentName}
          <br></br>
          {guide.photoMerchandiseDestination &&
          guide.photoMerchandiseDestination.length > 0 ? (
            <>
              <ImagePreview
                url={guide.photoMerchandiseDestination}
              ></ImagePreview>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: 'Motorizado Rem. / Dest.',
      dataIndex: 'motorizedRecipientId',
      align: 'center',
      render: (_, guide) => (
        <>
          {guide.motorizedSender?.name} / {guide.motorizedRecipient?.name}
        </>
      ),
    },
    {
      title: 'Fechas',
      dataIndex: 'createdAt',
      align: 'center',
      render: (_, guide) => (
        <>
          FC: {formatDateUTCByCountry(countryCode, guide?.createdAt)}
          {guide?.dispatchDate ? (
            <>
              <br></br>FLL: {formatDateUTCByCountry(countryCode, guide?.dispatchDate)}
            </>
          ) : null}
        </>
      ),
    },
    {
      title: '',
      width: 180,
      align: 'center',
      render: (_, guide) => (
        <>
          <a onClick={() => handleSummaryProducts(guide)} hidden>
            <Tooltip placement="top" title="Ver resumen">
              <FileDoneOutlined
                style={{
                  fontSize: '1.1rem',
                  margin: '0 .25rem',
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </a>
          <a onClick={() => handleSummaryProductEdit(guide)} hidden>
            <Tooltip placement="bottom" title="Editar productos">
              <OrderedListOutlined
                style={{
                  fontSize: '1.1rem',
                  margin: '0 .25rem',
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </a>
          <a onClick={() => handleAddOrder(guide)} hidden>
            <Tooltip placement="bottom" title="Agregar pedido">
              <FileAddOutlined
                style={{
                  fontSize: '1.1rem',
                  margin: '0 .25rem',
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </a>
          {authUser.user.role.name === 'MASTER' &&
          guide.dispatchStatus === DISPATCH_STATUS_LIST.REGISTERED ? (
            <>
              <Spin spinning={loadingDelete}>
                <a onClick={() => handleDeleteGuide(guide)}>
                  <Tooltip placement="bottom" title="Eliminar guía">
                    <DeleteOutlined
                      style={{
                        fontSize: '1.1rem',
                        margin: '0 .25rem',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </a>
              </Spin>
            </>
          ) : null}

          <a onClick={() => handleOrdersToPrepare(guide)}>
            <Tooltip placement="bottom" title="Pedido por preparar">
              <FileSyncOutlined
                style={{
                  fontSize: '1.1rem',
                  margin: '0 .25rem',
                  cursor: 'pointer',
                  color: '#1e07f3',
                }}
              />
            </Tooltip>
          </a>

          <a onClick={() => handleOrderDetails(guide)}>
            <Tooltip placement="left" title="Cargo de productos despachados">
              <FileSyncOutlined
                style={{
                  fontSize: '1.1rem',
                  margin: '0 .25rem',
                  cursor: 'pointer',
                  color: '#FF4081',
                }}
              />
            </Tooltip>
          </a>

          <a onClick={() => handleOrdersGuide(guide)}>
            <Tooltip placement="left" title="Cargar pedidos">
              <FileSyncOutlined
                style={{
                  fontSize: '1.1rem',
                  margin: '0 .25rem',
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </a>

          {showPdf &&
          dispatchGuideSelected?.guideNumber === guide.guideNumber ? (
            <>
              <PDFDownloadLink
                document={DocPdf()}
                fileName={`${guide.guideNumber}.pdf`}
              >
                {() => (
                  <Tooltip placement="left" title="descargar guía">
                    <DownloadOutlined
                      style={{
                        fontSize: '1.1rem',
                        margin: '0 .25rem',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}
              </PDFDownloadLink>
            </>
          ) : null}

          {showOrderDetailsPdf &&
          dispatchGuideSelected?.guideNumber === guide.guideNumber ? (
            <>
              <PDFDownloadLink
                document={DocOrderDetailsPdf()}
                fileName={`${guide.guideNumber}-cargo.pdf`}
              >
                {() => (
                  <Tooltip placement="left" title="descargar cargo">
                    <DownloadOutlined
                      style={{
                        fontSize: '1.1rem',
                        margin: '0 .25rem',
                        cursor: 'pointer',
                        color: '#FF4081',
                      }}
                    />
                  </Tooltip>
                )}
              </PDFDownloadLink>
            </>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      bordered
      dataSource={dispatchGuides}
      columns={columns}
      loading={loading}
      pagination={{
        pageSize: 10,
        current: currentPage,
        total: count,
        onChange(page) {
          handlePagination(page);
        },
      }}
      scroll={{
        y: windowSize.height - 200,
      }}
      size="small"
      expandable={{
        expandedRowRender: (record) => (
          <SummaryProductListTable record={record.summaryDispatchProduct} />
        ),
        rowExpandable: (record) => !!record.summaryDispatchProduct?.length,
      }}
    />
  );
};

export default DispatchGuideListTable;
