import { IAuthResponse } from '../auth/auth.interface'
import { IOrderShopify } from '../order-shopify/order-shopify.interface'
import { IOrderShopifySchema } from '../order-shopify/order-shopify.schema'
import { IPreOrderItemResponse } from '../pre-order/pre-order.interface'
import { CALL_STATUS_LIST } from '../pre-order/pre-order.params'
import { IPreOrderUpdateSchema } from '../pre-order/pre-order.schema'
import { IProductSearchSchema } from '../product/product.schema'
import { IOrderCreateRequest } from './order.interface'

const mapToOrderCreateRequest = (
  authUser: IAuthResponse,
  order: IPreOrderUpdateSchema,
  products: IProductSearchSchema[],
  preOrder: IPreOrderItemResponse | null,
): IOrderCreateRequest => {

  /*const locations = (order.shipping.province && order.shipping.district) ?
    getLocations(order.shipping.province, order.shipping.district) : undefined*/

  const total = products.length ? products
    .map(p => p.subtotal)
    .reduce((a, b) => a + b)
    : 0

  const productDetail = products
    .map(p => `${p.quantity} ${p.productName}`)
    .join(', ')

  const sCost = () => {
    const department = order.shipping.departmentId || 0

    if (department === 0) return 0
    if (authUser.user.company.countryCode === 'PER') {
      /*if (order.shipping.shippingByAgency) {
        return 8
      } else {
        if (department === 2900 || department === 3788 || department === 3926) {
          return 12
        } else if (department === 4108 || department === 4431 || department === 4567) {
          return 18
        } else {
          return 14
        }
      }*/
      return parseFloat(String(order.shippingCost)) 
    } else if (authUser.user.company.countryCode === 'BOL') {
      //potosi - tarija - oruro - cochabamba
      if (department === 4591 || department === 4589 || department === 4611 || department === 4587) {
        return 20
      } else if (department === 4588) { //la paz
        return 25
      } else if (department === 4590) { //sta cruz
        return 30
      } else {
        return 20 //default
      }
    } else {
      return order.shippingCost ? parseFloat(String(order.shippingCost)) 
        : authUser.user.company.setting.amountDelivery
    }
  }

  return {
    orderNumber: `${authUser.user.company.setting.companyPrefix}${preOrder?.orderNumber}`,
    userId: authUser.user.id,
    total,
    note: order.note || '',
    channel: 'Shopify',
    status: order.orderStatus === CALL_STATUS_LIST.CONFIRMED ? 'PENDING_DELIVERY' : null,
    callStatus: order.orderStatus as string,
    currency: authUser.user.company.setting.currency,
    preOrderHistory: preOrder as IPreOrderItemResponse,
    shippingCost: sCost(),
    isOrderAgency: order.shipping.shippingByAgency,
    createdBy: authUser.user.id,
    productDetail,
    orderDetails: products.map((p) => ({
      price: parseFloat(String(p.price)),
      quantity: p.quantity,
      subtotal: p.subtotal,
      skuId: p.skuId,
      warehouseId: p.warehouseId,
      companyId: p.companyId,
      dropPrice: p.dropPrice,
      storeCentralProductId: p.storeCentralProductId,
    })),
    customer: {
      companyId: authUser.user.company?.id,
      name: order.customer.name,
      lastName: order.customer.lastName,
      phone: order.customer.phone,
    },
    shipping: {
      address1: order.shipping.address1,
      address2: order.shipping.address2,
      reference: order.shipping.reference || '',
      lat: order.shipping.gps?.split(',')[0] || '',
      lng: order.shipping.gps?.split(',')[1] || '',
      countryName: authUser.user.company.setting.country.name,
      countryCode: authUser.user.company.setting.country.code,
      departmentName: order.shipping.department || null,
      departmentCode: `${order.shipping.departmentId}` || null,
      provinceName: order.shipping.province || null,
      provinceCode: `${order.shipping.provinceId}` || null,
      districtName: order.shipping.district || null,
      districtCode: `${order.shipping.districtId}` || null,
      dispatchDate: order.shipping.dispatchDate ? new Date(order.shipping.dispatchDate).toJSON() : null,
      scheduleDate: order.shipping.scheduleDate ? new Date(order.shipping.scheduleDate).toJSON() : null,
      shippingByAgency: order.shipping.shippingByAgency,
      agencyName: order.shipping.agencyName || '',
      agencyAddress: order.shipping.agencyAddress || '',
      contactName: order.shipping.contactName || '',
      contactPhone: order.shipping.contactPhone || '',
      contactDocumenType: order.shipping.contactDocumenType || '',
      contactDocumentNumber: order.shipping.contactDocumentNumber || '',
      guideNumber: order.shipping.guideNumber || '',
      keyCode: order.shipping.keyCode || '',
      attachFile: order.shipping.attachFile || '',
      addressPickUp: order.shipping.addressPickUp || '',
      shippingCost: parseFloat(String(order.shipping.shippingCost)) || 0
    },
    payment: order.payment ? {
      amount: order.payment?.amount,
      paymentDate: order.payment?.paymentDate || new Date(),
      paymentDocument: '',
    } : null
  }
}

const mapToOrderShopifyCreateRequest = (
  authUser: IAuthResponse,
  order: IOrderShopifySchema,
  products: IProductSearchSchema[],
  preOrder: IOrderShopify | null,
): IOrderCreateRequest => {

  const total = products.length ? products
    .map(p => p.subtotal)
    .reduce((a, b) => a + b)
    : 0

  const productDetail = products
    .map(p => `${p.quantity} ${p.productName}`)
    .join(', ')
  
  const prodShopifyDetail = preOrder?.items.map(p => `${p.quantity} ${p.name}`).join(', ')

  const sCost = () => {
    const department = order.shipping.departmentId || 0

    if (department === 0) return 0
    if (authUser.user.company.countryCode === 'PER') {
      return parseFloat(String(order.shippingCost)) 
    } else if (authUser.user.company.countryCode === 'BOL') {
      return parseFloat(String(order.shippingCost))
    } else {
      return authUser.user.company.setting.amountDelivery
    }
  }

  return {
    orderNumber: `${preOrder?.orderNumber}`,
    userId: authUser.user.id,
    total,
    note: order.note || '',
    channel: 'Shopify',
    preOrderHistory: {},
    payment: null,
    status: order.orderStatus === CALL_STATUS_LIST.CONFIRMED ? 'PENDING_DELIVERY' : null,
    callStatus: order.orderStatus as string,
    currency: authUser.user.company.setting.currency,
    shippingCost: sCost(),
    isOrderAgency: order.shipping.shippingByAgency,
    createdBy: authUser.user.id,
    transportId: order.transportId,
    productDetail,
    productShopifyDetail: prodShopifyDetail,
    createdAtShopify: order.createdAtShopify,
    paymentType: order.paymentType,
    orderDetails: products.map((p) => ({
      price: parseFloat(String(p.price)),
      quantity: p.quantity,
      subtotal: p.subtotal,
      skuId: p.skuId,
      warehouseId: p.warehouseId,
      companyId: p.companyId,
      dropPrice: p.dropPrice,
      storeCentralProductId: p.storeCentralProductId,
    })),
    customer: {
      companyId: authUser.user.company?.id,
      name: order.customer.name,
      lastName: order.customer.lastName || '',
      phone: order.customer.phone,
    },
    shipping: {
      address1: order.shipping.address1,
      address2: order.shipping.address2 || '',
      reference: order.shipping.reference || '',
      lat: order.shipping.gps?.split(',')[0] || '',
      lng: order.shipping.gps?.split(',')[1] || '',
      countryName: authUser.user.company.setting.country.name,
      countryCode: authUser.user.company.setting.country.code,
      departmentName: order.shipping.department || null,
      departmentCode: `${order.shipping.departmentId}` || null,
      provinceName: order.shipping.province || null,
      provinceCode: `${order.shipping.provinceId}` || null,
      districtName: order.shipping.district || null,
      districtCode: `${order.shipping.districtId}` || null,
      dispatchDate: order.shipping.dispatchDate ? new Date(order.shipping.dispatchDate).toJSON() : null,
      scheduleDate: order.shipping.scheduleDate ? new Date(order.shipping.scheduleDate).toJSON() : null,
      shippingByAgency: order.shipping.shippingByAgency,
      agencyName: order.shipping.agencyName || '',
      agencyAddress: order.shipping.agencyAddress || '',
      contactName: order.shipping.contactName || '',
      contactPhone: order.shipping.contactPhone || '',
      contactDocumenType: order.shipping.contactDocumenType || '',
      contactDocumentNumber: order.shipping.contactDocumentNumber || '',
      guideNumber: order.shipping.guideNumber || '',
      keyCode: order.shipping.keyCode || '',
      attachFile: order.shipping.attachFile || '',
      addressPickUp: order.shipping.addressPickUp || '',
    },
  }
}

export {
  mapToOrderCreateRequest,
  mapToOrderShopifyCreateRequest,
}
