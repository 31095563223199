/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { Table, TableColumnsType, Typography, Form, Button, Tag } from 'antd'
import { useOrderStore } from '../../../../../services/order-list/order.store'
import { formatDateUTCByCountry, validateUrl } from '../../../../../utils/date'
import PaymentEditForm from '../../forms/PaymentEditForm/PaymentEditForm'
import { IPaymentCreateSchema } from '../../../../../services/payment-create/payment-create.schema'
import { usePaymentCreate } from '../../../../../services/payment-create/payment-create.service'
import { usePaymentList } from '../../../../../services/payment-list/payment-list.service'
import { IPaymentResponse } from '../../../../../services/payment-list/payment-list.interface'
import { DeleteOutlined } from '@ant-design/icons'
import { usePaymentDelete } from '../../../../../services/payment-delete/payment-delete.service'
import { useOrderDeliveredEdit } from '../../../../../services/order-create/order.edit'
import { useAuthStore } from '../../../../../services/auth/auth.service'

const { Title } = Typography

const OrderPaymentContainer = () => {
  const { orderSelected } = useOrderStore()
  const LINK = 'https://www.appsheet.com/template/gettablefileurl?appName=Alidriver-284814394&tableName=Payment&fileName='
  const { paymentCreate, paymentCreated } = usePaymentCreate()
  const { getPayments, payments } = usePaymentList()
  const [form] = Form.useForm<IPaymentCreateSchema>()
  const [showForm, setShowForm] = useState(false)
  const { paymentDelete, paymentDeleted } = usePaymentDelete()
  const { companyUser } = useOrderDeliveredEdit()
  const [disabled, setDisabled] = useState<boolean>(true)
  const { authUser } = useAuthStore()

  useEffect(() => {
    if (orderSelected) {
      if (companyUser === 1) { // PRINCIPAL
        setDisabled(false)
      } else if (companyUser == 2) { //NORMAL
        if (orderSelected.isOrderAgency) {
          setDisabled(false)
        } else if (orderSelected.isWarehouseMain) {
          setDisabled(true)
        } else {
          setDisabled(false)
        }
      } else if (companyUser === 3) { //MASTER
        setDisabled(false)
      }
    }
  }, [])

  const handleSubmit = (data: IPaymentCreateSchema) => {
    paymentCreate(data)
  }

  const handleAddPayment = () => {
    setShowForm(true)
  }

  const handleUpdatePayment = (paymentId: string) => {
    paymentDelete(paymentId)
  }
  
  const columns: TableColumnsType<IPaymentResponse> = [
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Fecha',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      render: (_, payment) => <>{formatDateUTCByCountry(
        authUser.user.company.countryCode || 'PER',
        payment.paymentDate
      )}</>
    },
    {
      title: 'Abono',
      dataIndex: 'isPayMain',
      render: (_, payment) => <>
        <Tag color={ payment.isPayMain ? '#FFB74D' : '#00796B'}>
          { payment.isPayMain ? 'COURIER' : 'ECOMMERCE'}
        </Tag>
      </>
    },
    {
      title: 'Documento',
      dataIndex: 'paymentDocument',
      key: 'paymentDocument',
      render: (text) => 
        text ? (<a href={ validateUrl(text) ? `${LINK}${text}` : `${text}`} target="_blank" rel="noreferrer">Comprobante</a>) : '-'
    },
    {
      title: 'Método',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
    },
    {
      title: 'Entidad',
      dataIndex: 'entity',
      key: 'entity',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Acción',
      render: (_, payment) => 
        <DeleteOutlined onClick={() => handleUpdatePayment(payment.id)} hidden={disabled}/>
    },
  ]

  useEffect(() => {
    if (paymentCreated) {
      form.setFieldsValue({
        paymentDocument: '',
        paymentMethod: '',
        entity: '',
        paymentDate: '',
        amount: undefined,
        orderId: orderSelected?.id
      })
      setShowForm(false)
      getPayments(String(orderSelected?.id))
    }
  }, [paymentCreated])

  useEffect(() => {
    if (paymentDeleted) {
      form.setFieldsValue({
        paymentDocument: '',
        paymentMethod: '',
        entity: '',
        paymentDate: '',
        amount: undefined,
        orderId: orderSelected?.id
      })
      setShowForm(false)
      getPayments(String(orderSelected?.id))
    }
  }, [paymentDeleted])

  useEffect(() => {
    getPayments(String(orderSelected?.id))
  }, [])

  return <div style={{ overflow: 'auto' }}>
    {orderSelected ? (
      <>
        <Button type="primary" onClick={handleAddPayment} size="middle" hidden={disabled ? disabled : showForm}>
          Agregar Pago
        </Button>
        { showForm ? <>
          <PaymentEditForm
            onSubmit={handleSubmit}
            orderId={orderSelected.id}
            isOrderAgency={orderSelected.isOrderAgency}
            amount={orderSelected.totalDrop + orderSelected.shippingCost}
            form={form}
            currencySymbol={orderSelected.currency.symbol}
          />
        </> : <></>}
        <Table
          rowKey="id"
          size='small'
          dataSource={payments}
          columns={columns}
        />
        <div>
          <Title level={5}
            style={{ display: 'inline-block', width: 'calc(50%)' }}
          >Monto Total: {orderSelected.total}</Title>
          <Title level={5}
            style={{ marginBottom: '0', display: 'inline-block', width: 'calc(50%)' }}
          >Monto Pagado: {payments.length > 0 ? payments.map((p: IPaymentResponse) => p.amount).reduce((a: number, b: number) => a + b) : 0}</Title>
        </div>
      </>
    ) : null}
  </div>
}

export default OrderPaymentContainer
