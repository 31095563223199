import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, Form, Input, InputNumber, Select, Upload, message } from 'antd'
import { useLiquidationStore } from '../../../../../services/liquidation-edit/liquidation-edit.store'
import { ENTITY_LABEL, PAYMENT_METHOD_LABEL } from '../../../../../services/payment-create/payment.params'
import { IVoucherCreateSchema } from '../../../../../services/voucher-create/voucher-create.schema'
import { UploadOutlined } from '@ant-design/icons'
import { storage } from '../../../../../libs/firebase'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'

const { Option } = Select

interface IAddVoucherFormProps {
  onSubmit: (data: IVoucherCreateSchema) => void
}

const AddVoucherForm: FC<IAddVoucherFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<IVoucherCreateSchema>()
  const { liquidationSelected } = useLiquidationStore()
  const urlPayment = Form.useWatch(['voucherUrl'], form)
  const paymentMethod = Form.useWatch(['paymentMethod'], form)
  const [percent, setPercent] = useState(0)
  const [file, setFile] = useState<any>()
  const [url, setUrl] = useState('')
  const [requiredField, setRequiredField] = useState(false)

  useEffect(() => {
    if (liquidationSelected) {

      form.setFieldsValue({
        liquidationId: liquidationSelected.id,
      })
    } 
  }, [liquidationSelected])

  useEffect(() => {
    if (paymentMethod === 'E') {
      setRequiredField(false)
    } else {
      setRequiredField(true)
    }
  }, [paymentMethod])

  function handleBeforeUpload(file: any) {
    setFile(file)
    if (!file) {
      message.success('Debe seleccionar un archivo')

      return
    }

    const storageRef = ref(storage, `/files_payment/${file?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setPercent(percent)
      }, (_) => {
        //console.log('error', error)
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrl(url)
        })
      }
    )

    return false
  }

  useEffect(() => {
    form.setFieldValue(['voucherUrl'], url)
  }, [url])

  return (
    <>
      <Form
        layout='horizontal'
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        form={form}
        name='AddVoucherForm'
        labelWrap
        onFinish={onSubmit}
      >
        <Form.Item name={['liquidationId']} hidden>
          <Input />
        </Form.Item>
        
        { liquidationSelected ? <>

          <Form.Item
            label='Monto'
            name={['amount']}
            rules={[{ required: true, message: 'Obligatorio' }]}
          >
            <InputNumber />
          </Form.Item>
         
          <Form.Item
            label='Método pago'
            name={['paymentMethod']}
            rules={[{ required: true, message: 'Seleccione un método de pago' }]}
          >
            <Select placeholder="Método de pago">
                  
              {Object.keys(PAYMENT_METHOD_LABEL).map(key => (
                <Option key={key} value={key} >{PAYMENT_METHOD_LABEL[key].label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label='Entidad'
            name={['entity']}
            rules={[{ required: requiredField, message: 'Seleccione una entidad' }]}
          >
            <Select
              placeholder="Entidad">
              {Object.keys(ENTITY_LABEL).map(key => (
                <Option key={key} value={key} >{ENTITY_LABEL[key].label}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label='Código de operación'
            name={['operationCode']}
            rules={[{ required: true, message: 'Obligatorio' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name={['voucherUrl']}
            label="Seleccione un archivo:"
            rules={[{ required: requiredField, message: 'Adjuntar voucher' }]}
          >
            <Upload 
              name='file'
              beforeUpload={handleBeforeUpload}
              maxCount={1}
              fileList={[]}
            >
              <Button>
                <UploadOutlined /> {file ? file.name : ''} {percent} %
              </Button>
            </Upload>
            { urlPayment?.length !== 0 ? <>
              <a 
                href={url} 
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'underline' }}
              >Visualizar voucher</a>
            </> : null }
          </Form.Item>

          <Divider />
          
          <Button type='primary' htmlType='submit'>
            Guardar
          </Button>

        </> : null}

      </Form>
    </>
  )
}

export default AddVoucherForm
