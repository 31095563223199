import React, { FC, useEffect } from 'react';
import {
  DatePicker,
  Form,
  FormInstance,
  Radio,
  Typography,
} from 'antd';
import { IOrderUpdateSchema } from '../../../../../../services/order-update/order-update.schema';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useAuthStore } from '../../../../../../services/auth/auth.service';
import dayjs, { Dayjs } from 'dayjs';

const { Title } = Typography;

interface IOrderCourierPartProps {
  form: FormInstance<IOrderUpdateSchema>;
  companyUser: number;
  isDate: boolean;
  deliveryDate?: Dayjs;
  dispatchDateCurrent?: Dayjs;
}

const OrderCourierPart: FC<IOrderCourierPartProps> = ({
  form,
  companyUser,
  isDate,
  deliveryDate,
  dispatchDateCurrent,
}) => {
  const departmentId = Form.useWatch(['shipping', 'departmentId'], form);
  const dispatchDate = Form.useWatch(['shipping', 'dispatchDate'], form);
  const couriers = Form.useWatch(['shipping', 'dptoJson'], form);
  const addDays = Form.useWatch(['addDays']);
  const hoursWork = Form.useWatch(['hoursWork']);
  const typePattern = Form.useWatch(['typePattern']);
  const transportId = Form.useWatch(['transportId'], form);
  const orderDetails = Form.useWatch(['orderDetails']);
  const { authUser } = useAuthStore();
  const currencySymbol = authUser.user.company.setting.currency.symbol;

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    if (authUser.user.role.name === 'ADMIN_STORE' ||
      authUser.user.role.name === 'SELLER') {

      const newHour =
        hoursWork.length > 0 ? parseInt(`${hoursWork.split(':')[0]}`) : 0;
      const newMin =
        hoursWork.length > 0 ? parseInt(`${hoursWork.split(':')[1]}`) : 0;

      const now = dayjs().utcOffset(-5);
      const today = dayjs().startOf('day').add(newHour, 'h').add(newMin, 'm');

      if (now > today) {

        // Lima
        if (`${departmentId}` === '3926') {

          return (
            (current && current < dayjs().add(1, 'd').startOf('day')) ||
            (current.date() === 25 && current.month() === 11)
            || (current.date() === 1 && current.month() === 0)
          );

        } else {

          return (
            (current && current < dayjs().add(1, 'd').startOf('day')) ||
            current.day() === 0 || (current.date() === 25 && current.month() === 11)
            || (current.date() === 1 && current.month() === 0)
          );

        }

      }

      //Lima
      if (`${departmentId}` === '3926') {

        return (
          current.isBefore(now.startOf('day').toDate()) ||
          (current.date() === 25 && current.month() === 11)
          || (current.date() === 1 && current.month() === 0)
        );

      } else {

        return (
          current.isBefore(now.startOf('day').toDate()) || current.day() === 0 || 
          (current.date() === 25 && current.month() === 11)
          || (current.date() === 1 && current.month() === 0)
        );

      }
      
    } else if (
      authUser.user.role.name === 'ADMIN' ||
      authUser.user.role.name === 'MASTER'
    ) {

      return false;
      
    } else {
      return current && current < dayjs().startOf('day');
    }
  };

  const flagDeliveryDate = () => {
    if (isDate) {
      if (
        authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER'
      ) {
        return false;
      } else if (companyUser === 2) {
        return true;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const flagSchedule = () => {
    if (isDate) {
      if (
        authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER'
      ) {
        return false;
      } else if (companyUser === 2) {
        return true;
      } else {
        return true;
      }
    } else {
      if (authUser.user.role.name === 'ADMIN') {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (dispatchDate) {
      if (
        authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER'
      ) {
        // free date delivery
      } else if (authUser.user.role?.name === 'ADMIN_STORE' 
        || authUser.user.role?.name === 'SELLER') {
        const days = addDays ? addDays : 0;
        const currentDay = dayjs(dispatchDate).utcOffset(-5);
        let newScheduleDate;

        if (currentDay.day() === 6) {
        // saturday

          const tempDays = parseInt(`${days}`);

          newScheduleDate = dayjs(dispatchDate).add(
            1 + (tempDays > 0 ? tempDays - 1 : 0),
            'd',
          );
        } else if (currentDay.day() === 5 && parseInt(`${days}`) === 3) {
          newScheduleDate = dayjs(dispatchDate).add(
            0 + parseInt(`${days}`),
            'd',
          );
        } else {
          newScheduleDate = dayjs(dispatchDate).add(parseInt(`${days}`), 'd');
        }

        if (newScheduleDate.day() === 0) {
          newScheduleDate = dayjs(dispatchDate).add(
            1 + parseInt(`${days}`),
            'd',
          );
        }

        if (
          (newScheduleDate.date() === 25 && newScheduleDate.month() === 11) ||
          (newScheduleDate.date() === 1 && newScheduleDate.month() === 0)
        ) {
          newScheduleDate = dayjs(newScheduleDate).add(1, 'd');
        }

        if (dispatchDateCurrent) {
          const tempCurrent = dayjs(dispatchDateCurrent.toString());
          const newCurrent = dayjs(dispatchDate.toString());

            if (
              tempCurrent.day() === newCurrent.day() &&
              tempCurrent.month() === newCurrent.month() &&
              tempCurrent.year() === newCurrent.year()
            ) {
              if (deliveryDate) {
                form.setFieldValue(['shipping', 'scheduleDate'], deliveryDate);
              } else {
                form.setFieldValue(['shipping', 'scheduleDate'], newScheduleDate);
              }
            } else {
              form.setFieldValue(['shipping', 'scheduleDate'], newScheduleDate);
            }
        } else {
          form.setFieldValue(['shipping', 'dispatchDate'], dayjs());
          form.setFieldValue(['shipping', 'scheduleDate'], dayjs().add(1, 'day'));
        }
      }
    }
  }, [dispatchDate, addDays, typePattern]);

  useEffect(() => {
    if (transportId && couriers && couriers.length > 0) {

      const courier = couriers.find((t: any) => `${t.courierId}` === `${transportId}`);

      if (courier) {
        form.setFieldValue('wareHours', courier);
        form.setFieldValue('hoursWork', courier.hoursWork || 0);
        form.setFieldValue('typePattern', courier.typePattern);
        form.setFieldValue('addDays', courier.addDays || 0);
        form.setFieldValue('warehouseCurrent', courier.name);
        form.setFieldValue('shippingCost', courier.deliveryCost || 0);
        form.setFieldValue('returnCost', courier.returnCost || 0);
      }
    }
  }, [transportId]);

  return (
    <>
    
      {couriers && couriers.length > 0 ? (
        <>
          <Title level={4}>Courier</Title>
          <Form.Item
            label="Courier"
            name={['transportId']}
            rules={[{ required: true, message: '' }]}
          >
            <Radio.Group>
              {couriers.map((e: any) => (
                <>
                  <Radio value={e.courierId}>
                    <img
                      src={e.courierUrlImage}
                      style={{ width: '100px', height: '50px' }}
                    ></img>

                    <>
                      Entrega: {currencySymbol}{e.deliveryCost}
                      {' '}
                      | Retorno: {currencySymbol}{e.returnCost}
                    </>
                  </Radio>
                </>
              ))}
            </Radio.Group>
          </Form.Item>
        </>
      ) : null}

      {orderDetails &&
      Array.isArray(orderDetails) &&
      orderDetails.filter((e) => e.action !== 'delete').length > 0 && transportId ? (
        <>
          <Form.Item
            label="F. despacho"
            name={['shipping', 'dispatchDate']}
            rules={[
              {
                required: !isDate,
                message: 'Seleccionar una fecha de despacho',
              },
            ]}
          >
            <DatePicker
              disabled={flagSchedule() ? flagSchedule() : isDate}
              inputReadOnly={true}
              showNow={false}
              disabledDate={disabledDate}
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item
            label="F. entrega aprox."
            name={['shipping', 'scheduleDate']}
            rules={[{ required: !isDate, message: '' }]}
          >
            <DatePicker
              disabled={flagDeliveryDate()}
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </>
      ) : null}

    </>
  );
};

export default OrderCourierPart;
