import { useAxios } from '../../../hooks';
import { TShopifyProduct, TSkuShopify } from '../types';

export const useShopifyProductsConnected = () => {
  const { execute, response, loading } = useAxios<TSkuShopify[]>();

  const getProductsConnected = (args: { companyId: number, skuId: number }) => {
    execute({
      method: 'GET',
      url: '/sku-shopify/connected',
      params: {
        companyId: args.companyId,
        skuId: args.skuId,
      },
    });
  };

  return {
    getProductsConnected,
    loadingProduct: loading,
    productsConnected: response,
  };
};

export const useShopifyProducts = () => {
  const { execute, response, loading } = useAxios<TShopifyProduct[]>();

  const getProducts = (args: { companyId: number }) => {
    execute({
      method: 'GET',
      url: '/shopify-api/products',
      params: {
        companyId: args.companyId,
      },
    });
  };

  return {
    getProducts,
    loading,
    products: response,
  };
};
