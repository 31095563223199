import React, { FC } from 'react';
import { Button, Space, Table, TableColumnsType, Tooltip } from 'antd';
import {
  IProductResponse,
  ISkuItemResponse,
} from '../../../../../../services/product/product.interface';
import WarehouseSkusTable from './WarehouseSkusTable';
import { useProductStore } from '../../../../../../services/product/product.store';
import { useDrawer } from '../../../../../../contexts/DrawerContext';
import { ApiOutlined, EditOutlined } from '@ant-design/icons';
import { useAuthStore } from '../../../../../../services/auth/auth.service';
import { useModal } from 'react-modal-hook';
import { ConnectSkuShopifyContainer } from '../../../containers';

interface IProductSkusTableProps {
  record: IProductResponse;
}

const ProductSkusTable: FC<IProductSkusTableProps> = ({ record }) => {
  const { openDrawer } = useDrawer();
  const { setSkuSelected } = useProductStore();
  const { authUser } = useAuthStore();

  const [selectedSkuForConnectToShopify, setSelectedSkuForConnectToShopify] =
    React.useState<ISkuItemResponse>();
  const [openDrawerConnectSku, closeDrawer] = useModal(
    () =>
      selectedSkuForConnectToShopify ? (
        <ConnectSkuShopifyContainer sku={selectedSkuForConnectToShopify} onClose={closeDrawer} />
      ) : (
        <></>
      ),
    [selectedSkuForConnectToShopify],
  );

  const handleSelectedSku = (data: ISkuItemResponse) => {
    setSkuSelected(data);
    openDrawer('SKU_EDIT', 'Editar dimensiones');
  };

  const handleSelectedSkuForConeectToShopify = (data: ISkuItemResponse) => {
    setSelectedSkuForConnectToShopify(data);
    openDrawerConnectSku();
  };

  const columnsLevel3: TableColumnsType<ISkuItemResponse> = [
    {
      title: 'Sku',
      dataIndex: 'sku',
      width: '15%',
      align: 'center',
    },
    {
      title: 'EAN',
      dataIndex: 'ean',
      width: '15%',
      align: 'center',
    },
    {
      title: 'Variantes',
      dataIndex: 'skuOptions',
      render: (_, sku) => (
        <>
          {sku.skuOptions?.map((opt) => (
            <div key={opt.id}>
              {opt.option?.variant?.name}: <b>{opt.option?.name}</b>
            </div>
          ))}
        </>
      ),
    },
    {
      title: 'Precio dropshipping',
      dataIndex: 'salePriceDrop',
      align: 'center',
      width: '15%',
    },
    {
      title: '',
      dataIndex: 'id',
      align: 'center',
      width: '10%',
      render: (_, sku) => (
        <>
          {authUser.user.role.name === 'ADMIN' ||
          authUser.user.role.name === 'MASTER' ||
          authUser.user.role.name === 'STORE' ||
          authUser.user.role.name === 'ADMIN_STORE' ||
          authUser.user.role.name === 'SUPER_MASTER' ? (
            <Space>
              <Tooltip title="Editar sku">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => handleSelectedSku(sku)}
                />
              </Tooltip>

              {authUser.user.role.name === 'ADMIN_STORE' && authUser.user.company.isUseShopify === true ? <>
                <Tooltip title="Enlazar con Shopify">
                  <Button
                    shape="circle"
                    style={{ background: sku.skusShopify?.length ? 'blue' : 'black' }}
                    icon={<ApiOutlined style={{ color: 'white' }}/>}
                    onClick={() => handleSelectedSkuForConeectToShopify(sku)}
                  />
                </Tooltip>
              </> : <></>}
              
            </Space>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columnsLevel3}
        dataSource={record.skus}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => <WarehouseSkusTable record={record.warehouseSkus} />,
          rowExpandable: (record) => !!record.warehouseSkus.length,
        }}
        size="small"
      />
    </>
  );
};

export default ProductSkusTable;
