import React, { FC, useEffect } from 'react';
import {
  DatePicker,
  Form,
  FormInstance,
  Input,
  Select,
  Typography,
} from 'antd';
import { IPreOrderUpdateSchema } from '../../../../../../services/pre-order/pre-order.schema';
import {
  useLocationLevel1,
  useLocationLevel2,
  useLocationLevel3,
} from '../../../../../../services/location/location.service';
import { RangePickerProps } from 'antd/lib/date-picker';
import {
  useCountryEdit,
  usePreOrderEdit,
} from '../../../../../../services/pre-order/pre-order.edit';
import { useAuthStore } from '../../../../../../services/auth/auth.service';
import { useProductStore } from '../../../../../../services/product/product.store';
import dayjs from 'dayjs';

const { Title } = Typography;

interface IPreOrderShippingPartProps {
  form: FormInstance<IPreOrderUpdateSchema>;
  disabled: boolean;
  required: number;
}

const PreOrderShippingPart: FC<IPreOrderShippingPartProps> = ({
  form,
  disabled,
  required,
}) => {
  const provinceId = Form.useWatch(['shipping', 'provinceId'], form);
  const departmentId = Form.useWatch(['shipping', 'departmentId'], form);
  const districtId = Form.useWatch(['shipping', 'districtId'], form);
  const dispatchDate = Form.useWatch(['shipping', 'dispatchDate'], form);
  const enableDpto = Form.useWatch(['enableDpto'], form);
  const addDays = Form.useWatch(['addDays']);
  const hoursWork = Form.useWatch(['hoursWork']);
  const typePattern = Form.useWatch(['typePattern']);
  const { productSearch } = useProductStore();
  const shippingByAgency = Form.useWatch(
    ['shipping', 'shippingByAgency'],
    form,
  );
  const { userCompany } = usePreOrderEdit();
  const { authUser } = useAuthStore();
  const { requiredByCountry } = useCountryEdit();
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } =
    useLocationLevel1();
  const { getLocationsGeoLevel2, loadingGeo2, locationsGeolevel2 } =
    useLocationLevel2();
  const { getLocationsGeoLevel3, loadingGeo3, locationsGeolevel3 } =
    useLocationLevel3();

  const handleChangeProvince = (ubigeoId: string) => {
    getLocationsGeoLevel3({
      parentId: Number.parseInt(ubigeoId),
    });
    //form.setFieldValue(['shipping', 'districtId'], '')
  };

  const handleChangeDepartment = (ubigeoId: string) => {
    getLocationsGeoLevel2({
      parentId: Number.parseInt(ubigeoId),
    });
    //form.setFieldValue(['shipping', 'provinceId'], '')
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    if (!shippingByAgency) {
      if (userCompany === 2) {
        const hour = hoursWork ? hoursWork : 0;

        const pattern = typePattern ? typePattern : 0;

        const now = dayjs().utcOffset(-5);
        const today = dayjs().startOf('day').add(hour, 'h');

        if (pattern === 0) {
          if (now > today) {
            return now.day() === 2 || now.day() === 4 || now.day() === 6
              ? (current && current < dayjs().add(1, 'd').startOf('day')) ||
                  current.day() === 2 ||
                  current.day() === 4 ||
                  current.day() === 6
              : (current && current < dayjs().add(1, 'd').startOf('day')) ||
                  current.day() === 2 ||
                  current.day() === 4 ||
                  current.day() === 6;
          }

          return (
            current.isBefore(now.startOf('day').toDate()) ||
            current.day() === 2 ||
            current.day() === 4 ||
            current.day() === 6
          );
        } else {
          if (now > today) {
            return (
              (current && current < dayjs().add(1, 'd').startOf('day')) ||
              current.day() === 0
            );
          }

          return (
            current.isBefore(now.startOf('day').toDate()) || current.day() === 0
          );
        }
      } else if (
        authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER'
      ) {
        const pattern = typePattern ? typePattern : 0;

        const now = dayjs().utcOffset(-5);

        if (pattern === 0) {
          return (
            current.isBefore(now.add(1, 'd').startOf('day').toDate()) ||
            current.day() === 2 ||
            current.day() === 4 ||
            current.day() === 6
          );
        } else {
          return current.isBefore(now.startOf('day').toDate());
        }
      } else {
        return current && current < dayjs().startOf('day');
      }
    } else {
      if (userCompany === 2 && authUser.user.company.countryCode === 'PER') {
        if (
          departmentId === 2900 ||
          departmentId === 3788 ||
          departmentId === 3926
        ) {
          const now = dayjs().utcOffset(-5);
          const today = dayjs().startOf('day').add(7, 'h');

          if (now > today) {
            return current.isBefore(now.toDate()) || current.day() === 0;
          } else {
            return (
              (current && current < dayjs().startOf('day')) ||
              current.day() === 0
            );
          }
        } else {
          const now = dayjs().utcOffset(-5);
          const today = dayjs().startOf('day').add(16, 'h');

          if (now.day() === 0) {
            return (
              (current && current < dayjs().add(2, 'd').startOf('day')) ||
              current.day() === 0
            );
          }

          if (now > today) {
            return now.day() === 6
              ? (current && current < dayjs().add(3, 'd').startOf('day')) ||
                  current.day() === 0
              : (current && current < dayjs().add(2, 'd').startOf('day')) ||
                  current.day() === 0;
          }

          return current.isBefore(now.toDate()) || current.day() === 0;
        }
      } else {
        return (
          (current && current < dayjs().startOf('day')) || current.day() === 0
        );
      }
    }
  };

  // 2900 => Arequipa 3292 => Cusco 3788 => La libertad 3926 => Lima

  useEffect(() => {
    if (dispatchDate) {
      const dispatchDateInDayjs = dayjs(dispatchDate);

      if (
        authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER'
      ) {
        // free date delivery
      } else if (userCompany === 2) {
        const days = addDays ? addDays : 0;
        const pattern = typePattern ? typePattern : 0;
        const currentDay = dispatchDateInDayjs.utcOffset(-5);
        let newScheduleDate;

        if (pattern === 0) {
          if (currentDay.day() === 5) {
            // friday
            newScheduleDate = dispatchDateInDayjs.add(
              3 + parseInt(`${days}`),
              'd',
            );
          } else {
            //others days
            newScheduleDate = dispatchDateInDayjs.add(parseInt(`${days}`), 'd');
          }

          if (
            (newScheduleDate.date() === 25 && newScheduleDate.month() === 11) ||
            (newScheduleDate.date() === 1 && newScheduleDate.month() === 0)
          ) {
            newScheduleDate = dayjs(newScheduleDate).add(1, 'd');
          }
        } else {
          if (currentDay.day() === 6) {
            // saturday
            const tempDays = parseInt(`${days}`);

            newScheduleDate = dispatchDateInDayjs.add(
              2 + (tempDays > 0 ? tempDays - 1 : 0),
              'd',
            );
          } else {
            //others days
            newScheduleDate = dispatchDateInDayjs.add(parseInt(`${days}`), 'd');
          }

          if (newScheduleDate.day() === 0) {
            newScheduleDate = dispatchDateInDayjs.add(
              1 + parseInt(`${days}`),
              'd',
            );
          }

          if (
            (newScheduleDate.date() === 25 && newScheduleDate.month() === 11) ||
            (newScheduleDate.date() === 1 && newScheduleDate.month() === 0)
          ) {
            newScheduleDate = dayjs(newScheduleDate).add(1, 'd');
          }
        }

        form.setFieldValue(['shipping', 'scheduleDate'], newScheduleDate);
      }
    }
  }, [dispatchDate]);

  useEffect(() => {
    if (!shippingByAgency) {
      form.setFieldValue(['shipping', 'departmentId'], undefined);
      form.setFieldValue(['shipping', 'provinceId'], undefined);
      form.setFieldValue(['shipping', 'districtId'], undefined);
      form.setFieldValue(['shipping', 'department'], '');
      form.setFieldValue(['shipping', 'dptoJson'], []);
      form.setFieldValue(['shipping', 'province'], '');
      form.setFieldValue(['shipping', 'district'], '');
      form.setFieldValue(['shipping', 'attachFile'], undefined);
      form.setFieldValue(['shipping', 'agencyName'], undefined);
      form.setFieldValue(['shipping', 'contactDocumenType'], undefined);
      form.setFieldValue(['shipping', 'contactDocumentNumber'], undefined);
      form.setFieldValue(['shipping', 'contactName'], undefined);
      form.setFieldValue(['shipping', 'contactPhone'], undefined);
      form.setFieldValue(['shipping', 'guideNumber'], undefined);
      form.setFieldValue(['shipping', 'keyCode'], undefined);
      form.setFieldValue(['shipping', 'addressPickUp'], undefined);
      form.setFieldValue(['shipping', 'shippingCost'], undefined);
      form.setFieldValue(['shipping', 'scheduleDate'], undefined);
      form.setFieldValue(['addDays'], 0);
    }
  }, [shippingByAgency]);

  useEffect(() => {
    const dptos = locationsGeolevel1
      ? locationsGeolevel1.filter((f) => f.flagContraentrega)
      : [];

    if (departmentId && dptos.length > 0) {
      const dpto = dptos.find((d) => `${d.id}` === `${departmentId}`);

      if (dpto) {
        form.setFieldValue(['shipping', 'department'], dpto?.name);
        handleChangeDepartment(`${dpto.id}`);
      }
    }
  }, [departmentId, locationsGeolevel1]);

  useEffect(() => {
    const provinces = locationsGeolevel2
      ? locationsGeolevel2.filter((f) => f.flagContraentrega)
      : [];

    if (provinceId && provinces.length > 0) {
      const prov = provinces.find((d) => `${d.id}` === `${provinceId}`);

      if (prov) {
        form.setFieldValue(['shipping', 'province'], prov?.name);
        handleChangeProvince(`${prov.id}`);
      }
    }
  }, [provinceId, locationsGeolevel2]);

  useEffect(() => {
    const districts = locationsGeolevel3
      ? locationsGeolevel3.filter((f) => f.flagContraentrega)
      : [];

    if (districtId && districts.length > 0) {
      const dist = districts.find((d) => `${d.id}` === `${districtId}`);

      if (dist) {
        form.setFieldValue(['shipping', 'district'], dist?.name);
      }
    }
  }, [districtId, locationsGeolevel3]);

  useEffect(() => {
    getLocationsGeoLevel1();
  }, []);

  return (
    <>
      <Title level={4}>Entrega</Title>

      <Form.Item
        label="Departamento"
        name={['shipping', 'departmentId']}
        rules={[
          {
            required: required === 1 ? false : true,
            message: '',
          },
        ]}
      >
        <Select
          onChange={handleChangeDepartment}
          showSearch
          optionFilterProp="label"
          disabled={disabled === false ? enableDpto : disabled}
          loading={loadingGeo1}
          filterOption={(input, option) =>
            (option?.label as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={
            !shippingByAgency
              ? locationsGeolevel1
                  ?.filter((f) => f.flagContraentrega)
                  .map((e) => ({
                    label: e.name,
                    value: e.id,
                  }))
              : locationsGeolevel1?.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))
          }
        />
      </Form.Item>

      <Form.Item
        label="Provincia"
        name={['shipping', 'provinceId']}
        rules={[
          {
            required: required === 1 ? false : true,
            message: '',
          },
        ]}
      >
        <Select
          onChange={handleChangeProvince}
          showSearch
          optionFilterProp="label"
          loading={loadingGeo2}
          filterOption={(input, option) =>
            (option?.label as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={
            !shippingByAgency
              ? locationsGeolevel2
                  ?.filter((f) => f.flagContraentrega)
                  .map((e) => ({
                    label: e.name,
                    value: e.id,
                  }))
              : locationsGeolevel2?.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))
          }
          disabled={disabled}
        />
      </Form.Item>

      <Form.Item
        label="Distrito"
        name={['shipping', 'districtId']}
        rules={[
          {
            required: required === 1 ? false : true,
            message: '',
          },
        ]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          loading={loadingGeo3}
          filterOption={(input, option) =>
            (option?.label as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={
            !shippingByAgency
              ? locationsGeolevel3
                  ?.filter((f) => f.flagContraentrega)
                  .map((e) => ({
                    label: e.name,
                    value: e.id,
                  }))
              : locationsGeolevel3?.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))
          }
          disabled={disabled}
        />
      </Form.Item>

      {!shippingByAgency && productSearch && productSearch.length > 0 ? (
        <>
          <Form.Item
            label="F. despacho"
            name={['shipping', 'dispatchDate']}
            rules={[
              {
                required: required === 1 || required === 2 ? false : true,
                message: 'Seleccionar una fecha de despacho',
              },
            ]}
          >
            <DatePicker
              disabled={disabled}
              inputReadOnly={true}
              showNow={false}
              disabledDate={disabledDate}
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item
            label="F. entrega aprox."
            name={['shipping', 'scheduleDate']}
            rules={[
              {
                required: required === 1 || required === 2 ? false : true,
                message: '',
              },
            ]}
          >
            <DatePicker
              disabled
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </>
      ) : null}

      {shippingByAgency ? (
        <>
          <Form.Item
            label="F. entrega programada"
            name={['shipping', 'scheduleDate']}
            rules={[
              {
                required: required === 1 || required === 2 ? false : true,
                message: 'Seleccionar una fecha de entrega',
              },
            ]}
          >
            <DatePicker
              disabledDate={disabledDate}
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
              inputReadOnly={true}
              showNow={false}
              disabled={disabled}
            />
          </Form.Item>
        </>
      ) : null}

      <Form.Item
        label="Dirección 1"
        name={['shipping', 'address1']}
        rules={[
          {
            required: required === 1 || required === 2 ? false : true,
            message: '',
          },
        ]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item label="Dirección 2" name={['shipping', 'address2']}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        label="Referencia"
        name={['shipping', 'reference']}
        rules={[
          {
            required: requiredByCountry
              ? required === 0
                ? true
                : false
              : false,
            message: '',
          },
        ]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        label="GPS"
        name={['shipping', 'gps']}
        rules={[
          {
            required: requiredByCountry
              ? required === 0
                ? true
                : false
              : false,
            message: '',
            pattern: /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/,
          },
        ]}
      >
        <Input placeholder="Latitud,Longitud" disabled={disabled} />
      </Form.Item>

      {!disabled ? (
        <>
          <Form.Item label="GPS">
            Solicítalo al cliente por WhatsApp o
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.loom.com/share/766a81fd962e4038860cc1bea29f9f0f"
            >
              {' '}
              ver TUTORIAL
            </a>
          </Form.Item>
        </>
      ) : null}

      <Form.Item hidden name={['shipping', 'department']}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item hidden name={['shipping', 'dptoJson']}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item hidden name={['shipping', 'district']}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item hidden name={['shipping', 'province']}>
        <Input disabled={disabled} />
      </Form.Item>
    </>
  );
};

export default PreOrderShippingPart;
