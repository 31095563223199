import React from 'react';
import './ShopifyIntegration.css';
import ShopifyLogo from './shopify-logo';
import { PlayCircleOutlined } from '@ant-design/icons';

export const ShopifyIntegrationHeader = () => {
  return (
    <div className="shopify-container">
      <div className="shopify-card">
        <div className="logo-wrapper">
          <ShopifyLogo />
        </div>
        
        <a 
          href='https://releas.it/codform/Aliclik'
          className='create-account-link'>
          Crea tu cuenta aquí
        </a>

        <h2 className="shopify-title">
          Instala nuestra integración de releasit y ten la cobertura Aliclik lista para vender
        </h2>
        
        <p className="shopify-subtext">
          Ingrese los datos de su tienda para comenzar la integración con Shopify
        </p>
        
        <button 
          className="video-buttons"
          onClick={() => window.open('https://www.loom.com/share/9a42f6ac0cf846fe9668c353e074eee9', '_blank')}
        >
          <div className="button-content">
            <div className="play-icon">
              <PlayCircleOutlined />
            </div>
            <span>¡Mira cómo hacerlo!</span>
          </div>
        </button>
      </div>
    </div>
  );
}
