/* eslint-disable max-len */
import React from 'react';
import './Questions.css';
import useScrollAnimation from '../../hook/useScrollAnimation';

export const Questions = () => {
  const { ref, isVisible } = useScrollAnimation();

  return (
    <div className="questions" id="questions" ref={ref}>
      <div className={`questions__wrapper ${isVisible ? 'appear' : ''}`}>
        <h2 className={`title__landing ${isVisible ? 'appear' : ''} `}>Preguntas Frecuentes</h2>
        <div className="questions-items">
          <div className="questions-wrapper-item">
            <details className="questions-item">
              <summary className="questions-summary">¿Qué es Aliclik y cómo funciona?</summary>
              <p className="questions-text">
                Aliclik es un ecosistema digital que conecta emprendedores con proveedores y soluciones logísticas para escalar su ecommerce sin complicaciones.
              </p>
            </details>
            <details className="questions-item">
              <summary className="questions-summary">
                ¿Cuáles son los requisitos para vender en Aliclik?
              </summary>
              <p className="questions-text">
                Si eres proveedor, necesitas un catálogo de productos, capacidad operativa y cumplir con nuestros estándares de servicio. Si eres emprendedor, solo necesitas registrarte y empezar a vender.
              </p>
            </details>
            <details className="questions-item">
              <summary className="questions-summary">
                ¿Cómo funciona la logística y la entrega de pedidos?
              </summary>
              <p className="questions-text">
                Aliclik gestiona la logística con una red de operadores que permite envíos a todo el Perú con opciones de pago contra entrega y recaudo eficiente.
              </p>
            </details>
          </div>
          <div className="questions-wrapper-item">
            <details className="questions-item">
              <summary className="questions-summary">
                ¿Cuánto cuesta usar Aliclik?
              </summary>
              <p className="questions-text">
                Aliclik es GRATIS para los emprendedores, no cobramos una comisión sobre el valor de la venta o el recaudo. Monetizamos por transacciones y servicios adicionales.
              </p>
            </details>
            <details className="questions-item">
              <summary className="questions-summary">
                ¿Cómo recibo mis pagos si vendo a través de Aliclik?
              </summary>
              <p className="questions-text">
                Los pagos se liquidan de forma periódica según el método de cobro seleccionado, garantizando seguridad y transparencia brindando un informe detallado en cada pago.
              </p>
            </details>
            <details className="questions-item">
              <summary className="questions-summary">
                ¿Qué ventajas tiene vender con Aliclik frente a otras plataformas?
              </summary>
              <p className="questions-text">
                Mayor alcance, logística integrada, facilidad de gestión y acceso a miles de emprendedores listos para vender tus productos.
              </p>
            </details>
          </div>
        </div>
      </div>
    </div>
  );
};
