/* eslint-disable max-len */
import React from 'react'
import '../components/TermsContent.css'
export default function TermsContent() {
  return (
    <>
      <div className="terms-section">
        <div className='terms-section__content'>
          <h2 className='terms-section__title'>Términos y Condiciones</h2>
          <p>Mediante la presente se especifican los Términos y Condiciones Generales de Aliclik, al usted aceptar manifiesta haber leído, entendido y estar de acuerdo con ellos. Los términos y condiciones generales describen el alcance del servicio prestado, las obligaciones y los derechos de usted como usuario y los nuestros como plataforma; por tanto, estos Términos & Condiciones son vinculantes y de obligatorio cumplimiento.</p>

          <div className='terms-section__info'>
            <h3>1. Definiciones</h3>
            <p> <strong> Tienda Ecom : </strong> Micro, pequeñas, medianas y grandes empresas que comercian productos físicos a través de plataformas electrónicas, redes sociales y hacen parte de Aliclik.</p>

            <p> <strong>Proveedor : </strong> Empresa propietaria de los productos suministrados a cada Tienda Ecom.</p>
            
            <p> <strong>Courier o Transportadora : </strong> Empresa de transporte encargada del reparto al cliente final de la Tienda Ecom y recaudo.</p>
            
            <p> <strong>Cliente Aliclik : </strong> Comercios, personas naturales o jurídicas que tienen una cuenta en la plataforma Aliclik, han aceptado sus Términos y Condiciones y utilizan con cierta asiduidad los servicios Aliclik.</p>
            
            <p> <strong>Cuenta Aliclik : </strong> El acceso, al repositorio de información y todo el material contenido por el usuario, relacionado con las funcionalidades de Aliclik.</p>
            
            <p> <strong> Servicio Aliclik : </strong> El conjunto de aplicaciones y funcionalidades desarrolladas y dispuestas por ALICLIK SYSTEM S.A.C</p>
          </div>

          <div className='terms-section__info'>
            <h3>2. Aliclik</h3>
            <p>Es una compañía de tecnología que ofrece servicios vinculados al comercio electrónico, mediante el cual se pretende incentivar, apoyar y dinamizar los procesos inherentes al comercio electrónico.</p>

            <p>A través de la plataforma se facilita a Tiendas Ecom, comercios, emprendedores y comerciantes las herramientas necesarias para que éstos, en nombre propio puedan vender y enviar sus productos, así como cobrar por sus pedidos de forma remota.</p>

            <p>Por lo tanto, Aliclik es un simple intermediario entre Tiendas Ecom, Proveedores, Almacenes, Courier y las plataformas de pago, encargado de gestionar el envío y cobro de los productos negociados. Obrando bajo la figura de mandato sin representación otorgado conforme a lo estipulado en los artículos 1809° y siguientes del Código Civil Peruano.</p>
          </div>

          <div className='terms-section__info'>
            <h3>3. Capacidad de las partes</h3>
            <p>Nuestros servicios sólo están ofrecidos a aquellas personas que tienen capacidad para contratar y obligarse según la legislación vigente en el territorio peruano.</p>

            <p>Por tanto, para utilizar nuestra plataforma y acceder a los servicios ofrecidos por Aliclik, usted certifica ser mayor de 18 años y no tener ningún tipo de limitación para su ejercicio obligacional si es persona natural, y en caso de obligarse como representante legal de una persona jurídica, afirma tener capacidad para obligarse a nombre de tal.</p>
          </div>

          <div className='terms-section__info'>
            <h3>4. Objeto </h3>
            <p>El objeto del presente documento es facilitar al usuario el uso de la plataforma Aliclik, para que este, de la mano de los aliados estratégicos de Aliclik pueda realizar ventas a través de internet. Las Tiendas Ecom son exclusivos responsables de la legalidad y legitimidad de los artículos que ofrecen. Cada Tienda Ecom deberá cumplir con todas las obligaciones regulatorias pertinentes y contar con los registros, habilitaciones, permisos y/o autorizaciones exigidas por la normativa aplicable para la venta de los bienes y servicios ofrecidos.</p>

            <p>Aliclik no asume responsabilidad alguna por la existencia en el sitio de productos o servicios que infrinjan las políticas o cualquier ley o resolución judicial vigente.</p>

            <p>El objeto del acuerdo que usted suscribe al aceptar estos términos y condiciones cobija desde el uso de la tecnología de Aliclik hasta la orden de mandato consistente en la negociación con el courier para la entrega del producto vendido al cliente final y el recaudo de dinero y entrega a usted a través del servicio de “AliWallet”.</p>
          </div>

          <div className='terms-section__info'>
            <h3>5. Consentimiento </h3>
            <p>Al usted aceptar los presentes términos manifiesta entender las condiciones del servicio prestado y su deseo de acceder a ella por voluntad propia sin coacción externa. Usted es libre de utilizar o no los servicios ofrecidos; sin embargo, en caso de optar por hacerlo usted se obliga a respetar las condiciones expuestas en este texto, además de las condiciones de nuestras políticas de tratamiento de datos personales.</p>
          </div>
          
          {/* Responsabilidades */}
          <div className='terms-section__info'>
            <h3>6. Responsabilidades </h3>

            <h4>6.1 De Tiendas Ecom</h4>
            <p>Los vendedores son exclusivos responsables de la legalidad y legitimidad de los artículos que ofrecen, asimismo, deberán cumplir con todas las obligaciones regulatorias pertinentes y contar con los registros, habilitaciones, permisos y/o autorizaciones exigidas por la normativa aplicable para la venta de los bienes y servicios ofrecidos.</p>

            <p>Emitir boleta o factura de venta a su cliente final.</p>

            <h4>6.2 De Proveedores</h4>
            <p>Los proveedores son propietarios y responsables de la legalidad y legitimidad de los artículos que suministran a cada Tienda Ecom, asimismo, deberán cumplir con todas las obligaciones regulatorias pertinentes y contar con los registros, habilitaciones, permisos y/o autorizaciones exigidas por la normativa aplicable para la venta de los bienes y servicios ofrecidos.</p>

            <p>Emitir boleta o factura de venta a la Tienda Ecom</p>

            <h4>6.3 De Almacén o Bodega</h4>
            <p>Empresa que almacena los productos de cada Proveedor.</p>

            <h4>6.4 De Courier</h4>
            <p>Empresa de transporte encargada del reparto y recaudo.</p>

            <h4>6.5 De Aliclik</h4>
            <p>Aliclik no asume responsabilidad alguna por la existencia en el sitio de productos o servicios que infrinjan las políticas o cualquier ley o resolución judicial vigente.</p>
            <p>Sin perjuicio de lo antes señalado, Aliclik sólo será responsable por cualquier defecto en la prestación de su servicio, en la medida en que le sea imputable y con el alcance previsto en las leyes vigentes.</p>

            <h4>6.6 De Cliente Aliclik</h4>
            <p>Suministrar información verídica y verificable a la plataforma.</p>
            <p>Suministrar información verídica y verificable a la plataforma.</p>
            <p>Actuar con respeto del ordenamiento jurídico.</p>
            <p>Tener una clara identificación de los productos ofrecidos, vendidos y enviados a través de la plataforma Aliclik.</p>
            <p>Conservar las formas y el respeto durante el trato con Aliclik y sus aliados.</p>
            <p>Respetar la propiedad intelectual de Aliclik, sus clientes y aliados.</p>
            <p>Emitir factura de venta cuando corresponda.</p>
            <p>Brindar información real respecto al contenido, dimensiones y peso de los paquetes enviados.</p>
          </div>

          <div className='terms-section__info'>
            <h3>7. Declaración</h3>
            <p>Al hacer uso de los servicios de Aliclik, – incluidos Aliclik Envíos, Aliclik Pagos, Aliclik Bodegas, Aliclik referidos o cualquier otro -, usted declara y garantiza lo siguiente:</p>
            <p>Su intención es legítima y no pretende violar o trasgredir cualquier norma jurídica del ordenamiento vigente.</p>
            <p>Cuenta con todos los permisos, autorizaciones, licencias, poderes y derechos requeridos para realizar sus actividades y operaciones, así como cumplir con las obligaciones derivadas.</p>
            <p>Que cuenta con la capacidad técnica, económica y financiera, así como con adecuados recursos humanos para prestar los Servicios.</p>
            <p>Faculta a Aliclik a retirar sus productos de su plataforma, o de suspender el servicio cuando sospeche de actuaciones ilícitas.</p>
          </div>

          <div className='terms-section__info'>
            <h3>8. Registro</h3>
            <p>Para el uso de nuestros servicios, se deberá completar el formulario de registro con los datos que le sean requeridos, comprometiéndose a hacerlo de manera exacta, precisa y verdadera, así como a mantener sus datos siempre actualizados. Los usuarios – Comercio y Clientes Aliclik – del servicio de Aliclik serán los únicos responsables de la certeza de sus datos de registro.</p>
            <p>Sin perjuicio de la información brindada en el formulario, podremos solicitar y/o consultar información adicional para corroborar la identidad de los usuarios.</p>
            <p>Solo se permitirá la creación de una Cuenta a cada persona jurídica o natural; por ende, Aliclik se reserva el derecho de cancelar las distintas cuentas que ostente la misma persona natural o jurídica.</p>
          </div>

          <div className='terms-section__info'>
            <h3>9. Cancelación de cuenta</h3>
            <p> Aliclik se reserva el derecho a cancelar su cuenta si encuentra suplantación, fraude, pluralidad de cuentas en cabeza de un mismo titular o que desde su cuenta se están llevando a cabo acciones contrarias a lo pactados en los términos de servicio o la ley.</p>
            <p>Del mismo modo usted podrá unilateralmente solicitar la cancelación de su cuenta mediante correo electrónico a la dirección administrativo@aliclik.app. En tal situación se suspenderá el servicio y se le liberará el dinero que usted tenga en su billetera; sin embargo, usted autoriza expresamente a Aliclik descontar de su billetera, previo a la cancelación cualquier suma que usted efectivamente le adeude a Aliclik o se encuentre en disputa con alguna entidad o persona por denuncia de fraude.</p>
          </div>

          <div className='terms-section__info'>
            <h3>10. Términos y condiciones de envíos </h3>

            <h4>10.1 Definiciones específicas</h4>

            <ul>
              <li>
                <p> <strong>Orden de pedido o pedido : </strong>Venta realizada por cualquier medio pero reportada a la plataforma Aliclik.</p>
              </li>
              <li>
                <p> <strong>Envío : </strong>Orden de pedido preparada por el comercio con el propósito de ser entregada, sea pagada previamente a Aliclik o con orden de recaudo al recibir.</p>
              </li>
              <li>
                <p> <strong>Mercancías : </strong>Productos contenidos dentro de un paquete que ordenó el comprador al comercio a través del pedido.</p>
              </li>
              <li>
                <p> <strong>Recaudo al recibir : </strong>Pedido con recaudo al recibir o más conocido como contra entrega es la orden de recaudar un dinero que será entregado posteriormente a Aliclik para que sea bonificado a la Tienda Ecom.</p>
              </li>
            </ul>

            <h4>Estado de despacho: Gestionados por el Almacén y Courier</h4>
            <ul>
              <li>
                <p>
                  <strong> Por preparar: </strong> El pedido ha sido registrado por la Tienda Ecom y está listo para prepararse.
                </p>
              </li>
              <li>
                <p>
                  <strong> Preparado: </strong> El almacén donde se encuentran las mercancías ha alistado el paquete siguiente las condiciones correctas de Fullfilment.
                </p>
              </li>
              <li>
                <p>
                  <strong> Observado: </strong> El almacén validó que no se cumplen las condiciones para preparar el paquete (falta de stock, pagos no válidos, mercancías defectuosas, etc)
                </p>
              </li>
              <li>
                <p>
                  <strong> En tránsito: </strong> El paquete ha sido entregado por el almacén al Courier siendo desde este momento responsabilidad del mismo.
                </p>
              </li>
              <li>
                <p>
                  <strong> En agencia: </strong> El Courier está trasladando el paquete a la ciudad destino.
                </p>
              </li>
              <li>
                <p>
                  <strong> Validado: </strong> El paquete ha llegado correctamente a su destino y está listo para la entrega al cliente final.
                </p>
              </li>
              <li>
                <p>
                  <strong> Disponible: </strong> El paquete no ha sido entregado al cliente original pero está libre para poder reutilizarse en algún otro pedido de la misma ciudad.
                </p>
              </li>
              <li>
                <p>
                  <strong> Por devolver: </strong> El paquete no ha podido entregarse ni reutilizarse por lo que se requiere regresar al almacén de origen.
                </p>
              </li>
              <li>
                <p>
                  <strong> Almacén Central: </strong> El paquete ha llegado al centro de acopio para posteriormente distribuirse a los almacenes de origen.
                </p>
              </li>
              <li>
                <p>
                  <strong> Remanente en tránsito: </strong> El paquete ha salido del Almacén Central del Courier en camino al almacén de origen.
                </p>
              </li>
              <li>
                <p>
                  <strong> Dejado en almacén: </strong> El Courier entregó el paquete al almacén de origen siendo desde este momento responsabilidad del mismo.
                </p>
              </li>
              <li>
               <p>
                  <strong> Devuelto: </strong> El almacén de origen validó correctamente el retorno y se procedió con la actualización de stock.
                </p>
              </li>
            </ul>
            <h4>Estados de entrega: Gestionado por el Courier</h4>
            <ul>
              <li>
                <p>
                  <strong> Pendiente de entrega: </strong> El pedido está en proceso de entrega.
                </p>
              </li>
              <li>
                <p>
                  <strong> No despachado: </strong> El pedido no fue despachado por el Almacén por diversos motivos.
                </p>
              </li>
              <li>
                <p>
                  <strong> Sin stock: </strong> El pedido no se despachó por falta de stock.
                </p>
              </li>
              <li>
                <p>
                  <strong> Fuera de cobertura: </strong> El pedido se agendó para una zona fuera de la cobertura a la que llega el Courier.
                </p>
              </li>
              <li>
                <p>
                  <strong> Rechazado: </strong> El Courier llegó a la ubicación del cliente final sin embargo éste se negó a recibir y pagar el pedido.
                </p>
              </li>
              <li>
                <p>
                  <strong> De viaje: </strong> El cliente indica que se encuentra de viaje por lo cual no puede recibir el pedido.
                </p>
              </li>
              <li>
                <p>
                  <strong> Reprogramado: </strong> El cliente final indica específicamente que quiere recibir el pedido en otra fecha.
                </p>
              </li>
              <li>
                <p>
                  <strong> Cancelado: </strong> El cliente final indica que ya no desea el pedido.
                </p>
              </li>
              <li>
                <p>
                  <strong> Anulado: </strong> El pedido no se procede a entregar a solicitud de Tienda Ecom.
                </p>
              </li>
              <li>
                <p>
                  <strong> No responde: </strong> El courier agotó todos los medios disponibles para comunicarse con el cliente final.
                </p>
              </li>
              <li>
                <p>
                  <strong> Entregado: </strong> Entrega y recaudo exitoso del pedido.
                </p>
              </li>
            </ul>
            <p> <strong>Novedad: </strong> Estado de notificación por parte del Courier sobre alguna novedad en la entrega que debe ser resuelta.</p>
            <p> <strong>Solución de novedad: </strong> Información brindada por el comercio o comprador en respuesta a la notificación de novedad del Courier.</p>
            <p> <strong>Envío siniestrado: </strong>  Envío que sufrió de siniestro y que el seguro del transportador cubrirá por el valor que se ha declarado (valor del pedido) y será bonificado a través de Aliclik. Estado de notificación por parte del Courier sobre alguna novedad en la entrega que debe ser resuelta.</p>

            <h4>10.2 Naturaleza del Servicio</h4>
            <p>Al usted realizar una venta a través de nuestra plataforma, Aliclik procederá a través de sus aliados comerciales a acompañarlo durante el proceso de transporte y entrega de la mercancía vendida por usted. Para esto, es necesario que usted suministre a través de nuestra plataforma los datos necesarios para tal fin. Es muy importante para el correcto desarrollo del servicio que usted verifique la dirección de entrega, los datos del comprador y las dimensiones del paquete.</p>
            
            <p>El servicio prestado por Aliclik es el de un mandatario, quien celebra con terceros contratos de transporte para llevar a cabo las entregas de las ventas realizadas a través de nuestra plataforma, por tanto, en ningún momento se debe interpretar que Aliclik es responsable de las mercaderías; sin embargo, seremos intermediarios en la comunicación con las transportadoras o Courier aliadas. Por tanto, usted reconoce que, en las ventas realizadas, Aliclik no hace parte de la cadena de producción, distribución o venta, limitándose a ser un mandatario que a su nombre negocia con terceros para facilitarle condiciones de envío y recaudo.</p>
            
            <p>Una vez usted aporte los datos necesarios, la transportadora o courier por usted seleccionada a través de la plataforma procederá a recoger la mercancía en la fecha y hora indicada, así mismo le generará un número de guía que usted debe conservar para conocer el estado del envío y poder sustentar cualquier tipo de reclamación ante la transportadora. Aliclik no se hace responsable por el retardo o demora de las transportadoras.</p>

            <h4>10.3 Datos necesarios para el envío</h4>
            <p>Para poder recoger, transportar y entregar las mercancías por usted vendidas, Aliclik entregará a la transportadora seleccionada las direcciones de recojo y entrega por usted proporcionadas; por lo tanto, usted es enteramente responsable de los datos que suministra al momento del envío, debe tener en cuenta que cualquier error suyo en los nombres o en las direcciones de recojo y entrega que genere costos, deberá ser asumido por usted, autorizándonos conforme a nuestros “Términos y condiciones de pago” de descontar de su billetera el valor respectivo de tal error.</p>
            <p>Los datos que usted nos suministre serán administrados y tratados con base en nuestra “Política de Tratamiento de Datos Personales” la cual usted declara haber leído y aceptado al aceptar nuestros términos y condiciones.</p>
            <h4>10.4 Forma del servicio</h4>
            <p>Al usted cargar una orden de envío en nuestra plataforma, está indicándonos que debemos celebrar un contrato de transporte en nuestro nombre, como mandatario sin representación; para tal fin, se le solicitará ingrese la dirección de recojo, la dirección de entrega y las medidas y peso del envío, para con base en ello realizar el proceso de facturación por envío.</p>
            <p>Con el número de guía, y el número de pedido suministrado, usted y sus clientes podrán hacer seguimiento al estado del pedido y solicitar apoyo a través de nuestros canales de comunicación respectivos, quienes servirán como puente entre la transportadora seleccionada para el envío y usted.</p>
            <p>En ningún momento emana relación de subordinación, exclusividad, no competencia, suministro, depósito o cualquiera similar diferente al mandato entre usted y Aliclik, por lo que en cualquier momento y sin previo aviso es posible dar por terminada la relación comercial sin lugar a indemnizaciones, pues nos reservamos el derecho de revocar el acceso a nuestros servicios o variar términos y condiciones.</p>
            <p>Cada orden de envío que usted suministre a la plataforma inicia una relación contractual nueva, la cual termina una vez se entregue el pedido y se le desembolse el dinero en su billetera. Durante este periodo, no es posible para ninguna de las partes culminar la relación.</p>
            <p>Es posible que usted contrate el servicio de Aliclik Envíos sin recaudo, es decir; sin que Aliclik sea su colaborador para la recepción del pago del precio por el producto vendido; sin embargo, usted reconoce que acepta los “Términos y condiciones de pago”. Del mismo modo, cuando usted ordene un envío sin que Aliclik realice ningún recaudo, mediante la modalidad de “pago por consignación” o cualquier otra donde no se utilice alguna de nuestras pasarelas de pago o pago a contraentrega, en su billetera se congelará el valor del envío hasta tanto este se complete y sea descontado o bien, sea cancelado por su parte si aún no se ha recogido.</p>
            <p>Tenga en cuenta que, si su producto es de valor cuantioso, como bisutería, oro, plata o relojes de alto costo, debe llevarlos usted mismo hacia la sede del transportador elegido, indicando claramente el valor del producto.</p>

            <h4>10.5 Valor del envio</h4>
            <p>El valor del envío variará según el peso volumétrico, forma de pago, distancia y transportadora escogida. Usted suministrará los datos del producto a enviar y con base en ellos se generará la tarifa de envío y un número de guía. En caso de que los datos suministrados no correspondan a la realidad del producto y la transportadora elegida genere sobrecostos o cobros adicionales por el producto enviado, usted se obliga inequívocamente a cancelar dicho valor y autoriza a Aliclik para congelar y descontar el saldo correspondiente al sobrecosto en su billetera virtual. En todos los casos el pago del valor del envío corre por su cuenta.</p>

            <h4>10.6 Obligaciones Especiales</h4>
            <p>Al usted hacer uso de Aliclik envíos, se obliga a respetar el ordenamiento jurídico peruano y nuestras políticas internas. Por tanto, se abstendrá de comerciar, ofrecer, promocionar y sobre todo enviar productos prohibidos por la legislación peruana, incluyendo más no limitando: especies endémicas, fauna y flora reguladas, objetos gravemente inflamables, objetos explosivos, medicinas reguladas, armas de fuego, armas de fogueo sin el respectivo salvoconducto, sustancias psicotrópicas, etc.</p>
            <p>Del mismo modo, en caso de ofrecer productos importados usted se obliga a tener en regla todos los documentos, manuscritos y certificados exigidos por la legislación peruana para su venta y transporte. En caso de decomiso o sanción usted reconoce que tanto Aliclik como sus transportadoras aliadas son simples intermediarios.</p>

            <h4>10.7 Limite de responsabilidad</h4>
            <p>Aliclik no será responsable si usted viola de alguna manera el ordenamiento jurídico o si en su actuación vulnera los derechos de un tercero. Del mismo modo, usted reconoce que Aliclik es un simple intermediario y por tanto no será solidariamente responsable en caso de demanda o reclamación en su contra. Por tanto, usted comprende y acepta expresamente que Aliclik no será responsable de ningún daño directo, indirecto, incidental, especial o consecuencial, incluidos, entre otros, daños por lucro cesante, buena voluntad, uso, datos u otras pérdidas intangibles resultantes del uso o incapacidad para utilizar el servicio.</p>
          </div>

          <div className='terms-section__info'>
            <h3>11. Términos y Condiciones de pago </h3>
            <h4>11.1 Consideraciones previa</h4>
            <ul>
              <li>
                <p> Aliclik no está sometida a la inspección y vigilancia de la Superintendencia de Banca, Seguros y AFP. No realiza actividad financiera ni presta ningún servicio financiero o bursátiles. Tampoco otorga autorización a terceros para que utilicen los Servicios para tales fines. No capta recursos, ni recibe depósitos bancarios del público. </p>
              </li>
              <li>
                <p>Los recursos de los comercios que Aliclik recauda, los recibe a título de mandatario o intermediario entre usted y sus compradores, por tanto, su función consiste en recaudar el pago realizado a usted como vendedor y posteriormente depositarlo en su cuenta respectiva luego de haber realizado las deducciones respectivas por concepto de pago.</p>
              </li>
              <li>
                <p>El mandato que otorga el Tienda Ecom a Aliclik como plataforma, excluye facultades  para cumplir las obligaciones tributarias o cambiarias del Comercio. La Tienda Ecom es el único responsable de conocer y cumplir la legislación aplicable.</p>
              </li>
              <li>
                <p>Aliclik no garantiza la autenticidad o legalidad de las transacciones que se procesan a través de su Plataforma. El riesgo de fraude por suplantación de identidad de un pagador, o el de ilegalidad de una transacción son enteramente asumidos por el Comercio.</p>
              </li>
            </ul>

            <h4>11.2 Operación del servivio de pagos </h4>
            <ul>
              <li>
                <p>Al usted vender cualquier producto apoyado por nuestra plataforma, o al usted autorizar a la plataforma recaudar el pago por determinado producto está celebrando un contrato de mandato remunerado con Aliclik, consensual y sin representación quien a su nombre recibirá el pago y se lo mostrará como disponible en su billetera.</p>
              </li>
              <li>
                <p>Inmediatamente se realice un pago virtual a su comercio el Gateway nos informará la recepción del dinero en la cuenta bancaria de Aliclik autorizada para tal fin, luego de esto procederemos a colocarlo a su disposición una vez el comprador reciba el producto en la dirección indicada.</p>
              </li>
              <li>
                <p>Al aceptar estos términos y condiciones usted autoriza a Aliclik para de antemano realizar los cobros respectivos por cada transacción, mostrando su billetera el respectivo balance.</p>
              </li>
              <li>
                <p>En caso de que la entidad bancaria reporte cualquier tipo de alerta de fraude, usted autoriza expresamente a Aliclik para entregar el monto solicitado por la entidad bancaria mientras se allegan los soportes requeridos. En caso de que usted no logre acreditar la legalidad de la transacción ante la entidad bancaria, Aliclik no se encuentra obligado a pagar ningún valor.</p>
              </li>
              <li>
                <p>Aliclik descontará de su cuenta el valor del servicio y envíos según corresponda. Usted podrá verificar los valores discriminados en el estado de cuenta que podrá generar a través de la plataforma.</p>
              </li>
              <li>
                <p>En caso de que el pago se realice a contra entrega (cash on delivery), Aliclik pondrá a disposición este valor en su billetera inmediatamente el aliado de transporte consigne el dinero a la cuenta Aliclik.</p>
              </li>
              <li>
                <p>En cualquier momento, antes de colocar a disposición el dinero recaudado por Aliclik en su billetera, la plataforma le puede solicitar verificación de identidad o de titliaridad de la cuenta, esto es por la seguridad de todos.</p>
              </li>
            </ul>

            <h4>11.3 Obligaciones de la tienda Ecom</h4>
            <ul>
              <li>
                <p>Autorizar el descuento de las respectivas sumas generadas a favor de Aliclik.</p>
              </li>
              <li>
                <p>Abstenerse de acceder al código de la plataforma Aliclik, o utilizar ingeniería de reversa o mecanismos similares violando así la propiedad intelectual.</p>
              </li>
              <li>
                <p>Abstenerse de utilizar la plataforma para fines ilícitos.</p>
              </li>
              <li>
                <p>Aportar los documentos que respalden las transacciones en caso de ser requeridos por Aliclik o por alguna entidad bancaria en caso de sospecha de fraude.</p>
              </li>
              <li>
                <p>Coadyuvar a esclarecer cualquier investigación en contra del comercio por parte de las entidades respectivas.</p>
              </li>
              <li>
                <p>Proporcionar la adecuada información para facturación a la plataforma.</p>
              </li>
              <li>
                <p>Abstenerse de utilizar cuentas bancarias de terceros sin su autorización.</p>
              </li>
              <li>
                <p>Proporcionar los documentos de identificación solicitados por la plataforma para la realización de pagos y la autorización de operación.</p>
              </li>
            </ul>

            <h4>11.4 Obligaciones de Aliclik</h4>
            <ul>
              <li>
                <p>Recaudar los pagos realizados a las Tiendas ECOM y depositarlos en la cuenta bancaria por este indicada, dejando claridad de los valores recaudados, los montos descontados y los saldos pendientes a través de la plataforma.</p>
              </li>
              <li>
                <p>Respetar y no divulgar la información privilegiada que por el servicio prestado se llegare a obtener.</p>
              </li>
              <li>
                <p>Apoyar y trabajar de la mano con los comercios en caso de investigación de fraude por parte de entidades bancarias, siempre y cuando el comercio allegue los respectivos soportes de la licitud de la transacción o transacciones objeto de investigación.</p>
              </li>
              <li>
                <p>No congelar o bloquear arbitrariamente el saldo o las billeteras de los comercios sin una debida información o comunicación.</p>
              </li>
              <li>
                <p>Establecer canales de comunicación que lleven confianza al comercio y al cliente respecto al servicio prestado.</p>
              </li>
              <li>
                <p>Aliclik no garantiza una continua e ininterrumpida disponibilidad de sus servicios, pero si se compromete a realizar todas las labores tendientes a ofrecer estabilidad en la medida de lo posible.</p>
              </li>
              <li>
                <p>Aliclik no se compromete ni se obliga a determinados tiempos de procesamiento de transacciones.</p>
              </li>
              <li>
                <p>Aliclik no asume ni asumirá posibles riesgos de fraude debido a suplantación de identidad. Si se compromete a ayudar al comercio respecto al trámite frente a las entidades bancarias correspondientes siempre y cuando el comercio allegue los soportes solicitados en los tiempos respectivos.</p>
              </li>
            </ul>

            <h4>11.5 Condiciones especiales del servicio de pagos</h4>
            <p>El comercio declara que al usar este servicio:</p>
            <ul>
              <li>
                <p>Conoce y autoriza que, de forma transitoria y en su calidad de mandatario, Aliclik reciba los Ingresos del comercio en la cuenta de depósito que Aliclik tiene en la Entidad Adquirente.</p>
              </li>
              <li>
                <p>Conoce y autoriza a Aliclik Pagos para debitar de su Ingresos, todos los descuentos a los que haya lugar conforme se estipulan en los términos.</p>
              </li>
              <li>
                <p>Conoce y acepta que, si Aliclik rechaza la solicitud de desembolso, congela saldo o descuenta saldo de su cuenta, usted tiene la carga de acreditar el haber actuado sin violación de nuestros Términos o el ordenamiento jurídico.</p>
              </li>
              <li>
                <p>Conoce y otorga mandato irrevocable a Aliclik para que, por cuenta y orden del Comercio, Aliclik solicite a las plataformas de pagos bloquear los Ingresos desembolsados a la cuenta de depósito del Comercio cuando se inicie una investigación por un contracargo, un fraude o un enriquecimiento sin justa causa.</p>
              </li>
              <li>
                <p>Autoriza expresamente a Aliclik a congelar y descontar de su cuenta si observa movimientos sospechosos, que puedan dar pie a fraude o indebida destinación de la plataforma.</p>
              </li>
              <li>
                <p>Autoriza expresamente a Aliclik a congelar y descontar los saldos en su cuenta que sean objeto de reclamación por la entidad bancaria, poniéndolos a disposición de esta hasta tanto no se levante la alerta de fraude.</p>
              </li>
              <li>
                <p>Descuentos. – Cada vez que se procese una transacción, a los Ingresos brutos que esta transacción represente, se le realizarán los respectivos descuentos; por lo tanto, al Comercio solo se le desembolsará los Ingresos netos.</p>
              </li>
              <li>
                <p>Autoriza expresamente a Aliclik a congelar y descontar los saldos en su cuenta que se generen por sobre costos o devoluciones generados debido a los envíos realizados a través de la plataforma.</p>
              </li>
            </ul>

            <h4>11.6 Tipo de procesamiento de transacciones</h4>
            <p>Cada comercio tiene asignados automáticamente un Tope Máximo por transacción y un Tope Máximo Diario (calculado como el total transado en las últimas veinticuatro (24) horas. Estos topes tienen como finalidad establecer los límites en dinero que deberán tener las transacciones realizadas en la tienda que se pueden recibir en un día como medida de seguridad, esto se irá expandiendo automáticamente de acuerdo con el comportamiento de aprobación o rechazo de transacciones</p>

            <h4>11.7 Limite de responsabilidad</h4>
            <p>Aliclik no se hace responsable por los perjuicios que terceros le puedan ocasionar durante el uso de nuestros servicios. Del mismo modo, Aliclik no es responsable si usted utiliza nuestros servicios para transgredir el ordenamiento jurídico peruano.</p>
            <p>Estas limitaciones se aplicarán incluso si se ha informado a Aliclik de la posibilidad de tales daños.</p>
            <p>Al aceptar estos términos, usted acepta indemnizar y mantenernos exentos de responsabilidad respecto de cualquier reclamo, denuncia o demanda, incluidos los honorarios razonables de los abogados, que surjan de su uso y, según corresponda, a nuestra matriz, subsidiarias, socios comerciales, funcionarios, directores, agentes, empleados y proveedores de Aliclik, de un Servicio de Terceros o su relación con un Proveedor de Terceros.</p>

            <h4>11.8 Terceros en el servicio de pagos</h4>
            <ul>
              <li>
                <p>Además de estos Términos y condiciones, usted también acepta estar sujeto a los términos adicionales específicos del servicio aplicables a los servicios que compre o que sean proporcionados por los socios de Aliclik u otros terceros.</p>
              </li>
              <li>
                <p>No proporcionamos ninguna garantía con respecto a los servicios de terceros. Usted reconoce que Aliclik no tiene control sobre los Servicios de terceros y que no será responsable ante nadie por dichos Servicios de terceros, ni de la disponibilidad de los Servicios de terceros en los sitios web de Aliclik.</p>
              </li>
              <li>
                <p>Aliclik no garantiza la disponibilidad de los Servicios de Terceros y usted reconoce que Aliclik puede deshabilitar el acceso a los Servicios de Terceros en cualquier momento a su entera discreción y sin previo aviso.</p>
              </li>
              <li>
                <p>Aliclik no es responsable ni se hace responsable ante nadie por la interrupción o suspensión del acceso o la desactivación de cualquier Servicio de terceros.</p>
              </li>
              <li>
                <p>Aliclik recomienda encarecidamente que busque asesoramiento especializado antes de utilizar o confiar en los Servicios de terceros, para asegurarse de que satisfagan sus necesidades. En particular, las calculadoras de impuestos deben usarse solo como referencia y no como un sustituto del asesoramiento fiscal independiente al evaluar las tasas de impuestos correctas que debe cobrar a sus clientes.</p>
              </li>
              <li>
                <p>Si instala o habilita un Servicio de Terceros para su uso con los Servicios, nos concede permiso para permitir que el Proveedor de Terceros correspondiente acceda a sus datos y tome las medidas que sean necesarias para la interoperación del Servicio de Terceros con los Servicios, y cualquier intercambio de datos u otra interacción entre usted y el Proveedor Tercero es únicamente entre usted y dicho Proveedor Tercero.</p>
              </li>
              <li>
                <p>Aliclik no es responsable de ninguna divulgación, modificación o eliminación de sus datos u otros materiales, ni de las pérdidas o daños correspondientes que pueda sufrir, como resultado del acceso de un Servicio de terceros o un Proveedor tercero a sus datos u otros materiales. En ninguna circunstancia Aliclik será responsable por cualquier daño directo, indirecto, incidental, especial, punitivo, extraordinario o de otro tipo que resulte de cualquier Servicio de Terceros o su relación contractual con cualquier Proveedor Tercero, incluido cualquier Mentor. Estas limitaciones se aplicarán incluso si se ha informado a Aliclik de la posibilidad de tales daños. Las limitaciones anteriores se aplicarán en la máxima medida permitida por la ley aplicable.</p>
              </li>
            </ul>
          </div>

          {/* 12 Forma de operacion */}
          <div className='terms-section__info'>
            <h3>12. Términos y condiciones de proveedores</h3>
            <ul>
              <li>
                <p>Los Proveedores ofrecerán sus productos a través de la plataforma suministrada por Aliclik, donde las tiendas ECOM podrán adquirirlas por sí mismo, o venderlas a terceros al precio que deseen, indicando el punto de entrega y forma de pago de estos productos.</p>
              </li>
              <li>
                <p>Los Proveedores se obligan a dar una información verídica sobre los productos, estado, cantidad, condiciones y demás delimitaciones de las mercancías a ofrecer. Cualquier incompatibilidad entre la oferta realizada y el producto suministrado hará responsable el proveedor, dejando a Aliclik indemne como simple intermediario de las consecuencias civiles o penales que de esto se corresponda.</p>
              </li>
              <li>
                <p>El Proveedor se obliga a enviar la cantidad y productos que el Comercio le indique dentro de los tiempos estipulados.</p>
              </li>
              <li>
                <p>La Tienda ECOM acepta y reconoce que Aliclik es un simple intermediario y que no es responsable de la calidad o cantidad de los productos suministrados por Los Proveedores, en todo caso, Aliclik servirá como intermediario en caso de que surja una situación que lo amerite.</p>
              </li>
              <li>
                <p>Aliclik se reserva el derecho de cancelar el acceso al servicio Proveedores de forma unilateral si encuentra o sospecha violación a los términos generales.</p>
              </li>
            </ul>

            <h4>12.6 Forma de Operación</h4>
            <ul>
              <li>
                <p>El Proveedor se obliga a enviar a la dirección que la Tienda ECOM indique, la cantidad de productos que este le referencie con base en la información que el Proveedor ha suministrado con anterioridad a la Plataforma.</p>
              </li>
              <li>
                <p>Los Comercios tendrán acceso a través de la plataforma Aliclik a los catálogos de precios y productos ofrecidos por los Proveedores, teniendo la facultad de ofrecerlos para su venta a terceros al margen de precio que considere conveniente.</p>
              </li>
              <li>
                <p>El Proveedor emitirá factura a nombre del Comercio discriminando claramente la razón del gravamen. El servicio prestado por Aliclik se facturará en forma independiente.</p>
              </li>
              <li>
                <p>El precio de los productos enviados por El Proveedor será el informado a la plataforma al momento de que el Comercio de la orden de envío al Proveedor, por tanto, en caso de duda respecto al precio, se mirará el precio reportado al momento de la transacción.</p>
              </li>
              <li>
                <p>El Proveedor se obliga a garantizar a la Tienda ECOM o a quien este haya vendido los productos ordenados, el perfecto estado de estos, dando los auxilios y derechos que concede la legislación civil y comercial,. Por tanto, de antemano el Proveedor se obliga inequívocamente a responder por las garantías y el estado de los productos ofrecidos, dejando a Aliclik y sus comercios indemnes en caso de reclamación por temas de garantías.</p>
              </li>
              <li>
                <p>El Proveedor se obliga a despachar el producto vendido dentro de las siguientes 48 horas hábiles luego de la recepción de la orden de envío; si el pedido fue despachado después de este tiempo y se genera devolución quedará obligado a pagar el flete.</p>
              </li>
            </ul>
          </div>
          
          {/* Privacidad de datos */}
          <div className='terms-section__info'>
            <h3>13. Privacidad de datos</h3>
            <p>En Aliclik empleamos de forma responsable la información personal, protegiendo la privacidad de los Usuarios – Comercios y Clientes Aliclik – que nos brindaron sus datos. Asimismo, tomamos las medidas necesarias para garantizar la seguridad de dicha información. </p>
          </div>

          {/* 14 Propiedad intelectual */}
          <div className='terms-section__info'>
            <h3>14. Propiedad intelectual</h3>
            <p>Aliclik es propietaria de todos los derechos de propiedad intelectual sobre sus sitios, todo su contenido, servicios, productos, marcas, nombres comerciales, logos, diseños, imágenes, frases publicitarias, derechos de autor, dominios, programas de computación, códigos, desarrollos, software, bases de datos, información, tecnología, patentes y modelos de utilidad, diseños y modelos industriales, secretos comerciales, entre otros (“Propiedad Intelectual”) y se encuentran protegidos por leyes nacionales e internacionales.</p>
            <p>Aunque Aliclik otorga permiso para usar sus productos y servicios conforme a lo previsto en los Términos y Condiciones, esto no implica una autorización para usar su Propiedad Intelectual, excepto consentimiento previo y expreso de Aliclik y/o sus sociedades vinculadas.</p>
            <p>En cualquier caso, los Comercios que usen dichos productos y servicios no podrán utilizar la Propiedad Intelectual de Aliclik de una manera que cause confusión en el público y deberán llevar a cabo su actividad comercial bajo una marca o nombre comercial propio y distintivo, que no resulte confundible con la marca Aliclik y sus productos.</p>
            <p>Está prohibido usar nuestros productos o servicios para fines ilegales, realizar cualquier tipo de ingeniería inversa u obras derivadas, utilizar herramientas de búsqueda o de extracción de datos y contenidos de nuestra plataforma para su reutilización y/o crear bases de datos propias que incluyan en todo o en parte nuestro contenido sin nuestra expresa autorización. Está también prohibido el uso indebido, sin autorización y/o contrario a la normativa vigente y/o que genere confusión o implique uso denigratorio y/o que le cause perjuicio, daños o pérdidas a Aliclik.</p>
            <p>La utilización de los productos y servicios de Aliclik tampoco implica la autorización para usar propiedad intelectual de terceros que pueda estar involucrada, cuyo uso quedará bajo exclusiva responsabilidad del usuario.</p>
            <p>En caso de que uno de los Usuarios o cualquier publicación infrinja la Propiedad Intelectual de Aliclik, se nos faculta a poder remover dicha publicación total o parcialmente, sancionar al usuario conforme a lo previsto en estos Términos y Condiciones y ejercer las acciones extrajudiciales y/o judiciales correspondientes.</p>
          </div>

          {/* Sanciones */}
          <div className='terms-section__info'>
            <h3>15. Sanciones</h3>
            <p>En caso de que los Usuarios incumplieran una ley o los Términos y Condiciones, podremos: advertir, suspender, restringir o inhabilitar temporal o definitivamente su cuenta, sin perjuicio de la indemnización acorde al perjuicio.</p>
          </div>
          
          {/* Indemnidad */}
          <div className='terms-section__info'>
            <h3>16. Indemnidad</h3>
            <p>Los Usuarios mantendrán indemne a Aliclik, así como a quienes la dirigen, suceden, administran, representan y/o trabajan en ellas, por cualquier reclamo administrativo o judicial iniciado por otros Usuarios, terceros o por cualquier Organismo, relacionado con sus actividades.</p>

            <p>En virtud de esa responsabilidad, podrán realizar compensaciones, retenciones u otras medidas necesarias para la reparación de pérdidas, daños y perjuicios, cualquiera sea su naturaleza.</p>
          </div>

          {/*Nulidad parcial  */}
          <div className='terms-section__info'>
            <h3>17. Nulidad parcial</h3>
            <p>Si alguna disposición, o una parte de la misma, contemplada en estos Términos del servicio, por cualquier motivo, se considerase inválida, ilegal o inejecutable en cualquier aspecto, dicha nulidad, ilegalidad o inaplicabilidad no afectará a ninguna otra disposición (ni a la parte no afectada de la disposición) de los Términos del Servicio, y los Términos del servicio se interpretarán como si dicha disposición no válida, ilegal o no exigible, o parte de la disposición, nunca hubiera estado incluida en los Términos del Servicio.</p>
          </div>

          {/*  */}
          <div className='terms-section__info'>
            <h3>18. Jurisdicción y ley aplicable</h3>
            <p>Estos Términos y Condiciones se rigen por las leyes de la República del Perú. Toda controversia derivada de su aplicación, interpretación, ejecución o validez será sometida y resuelta por los tribunales de Lima, salvo disposición específica de normas de orden público.</p>
            <p>Para todos los efectos relacionados con estos Términos y Condiciones y con el uso del sitio, Aliclik Perú S.A.C. con RUC 20611293365, establece como domicilio la Calle German Schreiber Gulsmanco N° 276, distrito de San Isidro, provincia y departamento de Lima, Perú.</p>
          </div>

          <div className='terms-section__info'>
            <h3>19. Reversión del pago/modificación del pago</h3>
            <p>Es muy importante que tengas presente que, al acceder y usar Aliclik, estás aceptando estos Términos y Condiciones de manera íntegra y sin reserva la reversión o modificación en la billetera dispuesta para tu comercio.</p>
            <p>Por lo anterior, al hacer uso de la plataforma y del servicio de pagos, aceptas que Aliclik pueda hacer reversión o modificación de pagos en el evento en que se realice pago a la billetera de tu comercio con ocasión del informe emitido por la transportadora en donde se indica un estado logístico que no corresponda con el estado real del envío, generándose un enriquecimiento sin causa alguna que así lo justifique.</p>
            <p>Lo anterior implica que conforme a la trazabilidad emitida por la transportadora y debidamente contrastada por Aliclik con la guía de envío, si este se encuentre en estado “entregado” y realmente ello no corresponde a su estado logístico real, se procederá por el área correspondiente a realizar las validaciones y de ser el caso, a reversar y/o modificar el pago, según corresponda.</p>
            <p>Con fundamento en el supuesto anterior, autorizas modificar dentro de los treinta (30) días hábiles siguientes a la notificación del estado logístico realizado por la transportadora a Aliclik el saldo de tu billetera sin necesidad de requerir validaciones o autorizaciones distintas a la aceptación de los presentes Términos y Condiciones. Para tales efectos, Aliclik se obliga a soportar documentalmente con la guía de envío la validación realizada, la cual podrá ser solicitada por el cliente dentro de los treinta (30) días hábiles siguientes a la reversión o modificación de la billetera.</p>
            <p>Sumado a lo anterior, con la aceptación de los presentes Términos y Condiciones renuncias a cualquier reclamación extrajudicial o judicial producto de la reversión y/o modificación de saldos en la billetera realizada en el plazo aquí definido.</p>
          </div>
        </div>
      </div>
    </>
  )
}
