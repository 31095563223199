import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Divider, Spin, Typography } from 'antd';
import { downloadExcel } from 'react-export-table-to-excel';
import { useDropShalomReport } from '../../../../services/order-report-created/order-report-created.service';
import dayjs from 'dayjs';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const DropShalomReport = () => {
  const dateFormat = 'YYYY-MM-DD';
  const { getDropShalomReport, reports, loading } = useDropShalomReport();
  const [selectedDatesOrder, setSelectedDatesOrder] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);

  const headerOrder = [
    'NRO. PEDIDO',
    'ESTADO',
    'LINK VOUCHER',
    'PAGO AGENCIA',
    'ALMACÉN',
    'TIENDA',
    'TELÉFONO',
    'DROPSHALOM',
    'AGENCIA',
    'DEPARTAMENTO',
    'PROVINCIA',
    'DISTRITO',
    'PRODUCTO',
    'VARIANTE',
    'PRECIO DROP TOTAL',
    'CANTIDAD',
    'PRECIO DROP',
    'NOMBRE CLIENTE',
    'NÚMERO CLIENTE',
    'FECHA ENVÍO',
    'TIPO DOCUMENTO',
    'NRO. DOCUMENTO',
    'PROVEEDOR',
  ];

  useEffect(() => {
    if (reports && reports.length > 0) {
      downloadExcel({
        fileName: `dropShalom-report-${selectedDatesOrder[0].format('YYYY-MM-DD')}-to-
          ${selectedDatesOrder[1].format('YYYY-MM-DD')}`,
        sheet: 'react-export-table-to-excel',
        tablePayload: {
          header: headerOrder,
          // accept two different data structures
          body: reports.map((o) => {
            return {
              orderNumber: o.orderNumber,
              status: o.status,
              voucherAgency: o.voucherAgency,
              amountPayAgency: o.amountPayAgency,
              warehouse: o.warehouse,
              company: o.company,
              phone: o.phone,
              dropShalom: o.dropShalom,
              agency: o.agency,
              department: o.department,
              province: o.province,
              district: o.district,
              product: o.product,
              variant: o.variant,
              dropPriceTotal: o.dropPriceTotal,
              quantity: o.quantity,
              dropPrice: o.dropPrice,
              customer: o.customer,
              customerPhone: o.customerPhone,
              deliveryDate: o.deliveryDate,
              contactDocumenType: o.contactDocumenType,
              contactDocumentNumber: o.contactDocumentNumber,
              supplier: o.supplier,
            };
          }),
        },
      });
    }
  }, [reports]);

  const handleCalendarChangeOrder = (dates: any, dateStrings: any) => {
    setSelectedDatesOrder(dates);
  };

  const handleDownloadExcelOrders = () => {
    getDropShalomReport(
      selectedDatesOrder[0].format('YYYY-MM-DD'),
      selectedDatesOrder[1].format('YYYY-MM-DD'),
    );
  };

  return (
    <>
      <Spin spinning={loading}>
        <Divider />

        <Title
          level={5}
          style={{
            marginBottom: '0',
            alignContent: 'center',
            padding: '.0rem 1.5rem',
          }}
        >
          Reporte de pedidos - Envios por agencia
        </Title>

        <div
          style={{
            padding: '.85rem 1.5rem',
            display: 'flex',
            gap: '1rem',
            alignItems: 'center',
          }}
        >
          <Title level={5} style={{ marginBottom: '0' }}>
            Seleccionar una fecha
          </Title>

          <RangePicker
            format={dateFormat}
            defaultValue={[dayjs(new Date()), dayjs(new Date())]}
            onCalendarChange={handleCalendarChangeOrder}
            clearIcon={false}
            disabledDate={(current) => {
              if (!selectedDatesOrder || selectedDatesOrder.length === 0) {
                return false; //si no hay fechas seleccionadas no se bloquea
              }
              //obtener la primera fecha seleccionada
              const [start] = selectedDatesOrder;

              if (!start) return false;

              //Restringir la selección de más de 15 días -- si esta fuera de rango se deshabilita
              return current.isBefore(start.subtract(15, 'days')) || current.isAfter(start.add(15, 'days'));
            }}
          />

          <Button
            type="primary"
            onClick={handleDownloadExcelOrders}
            loading={loading}
          >
            Descargar reporte
          </Button>
        </div>
      </Spin>
    </>
  );
};

export default DropShalomReport;
