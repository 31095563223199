import { useAxios } from '../../../hooks';
import { TSkuShopify } from '../types';

export const useCreateSkuShopify = () => {
  const { execute, loading, response } = useAxios();

  const createSkuShopify = (data: TSkuShopify[]) => {
    return execute({
      method: 'POST',
      url: '/sku-shopify/multiple',
      data,
    });
  };

  return {
    createSkuShopify,
    loading,
    response,
  };
};

export const useUpdateSkuShopify = () => {
  const { execute, loading, response } = useAxios();

  const updateSkuShopify = (id: number, companyId: number, data: TSkuShopify[]) => {
    return execute({
      method: 'PATCH',
      url: `/sku-shopify/multiple/${id}/${companyId}`,
      data,
    });
  };

  return {
    updateSkuShopify,
    loading,
    response
  };
};
