import React, { useEffect } from 'react';
import { PageHeader } from '../../components';
import { CompanyChangeContainer } from '../../containers';
import { useAuthStore } from '../../services/auth/auth.service';
import { ShopifyConnectContainer } from './containers/shopify-connect-container';
import { ShopifyIntegrationHeader } from './components/shopify-integration-header';
import { Alert, Flex, Spin } from 'antd';
import {
  useCompanyDetail,
  useCompanyStore,
} from '../../services/company-create/company-create.service';

export const ShopifyConnectView = () => {
  const { authUser } = useAuthStore();
  const { getCompany, loading, companyDetail } = useCompanyDetail();
  const { companyGlobal } = useCompanyStore();

  const hasShopifyIntegration = companyDetail?.accessTokenShopify && companyDetail?.storeShopify;

  /**
   * TODO: This part needs to be refactored
   * The role module should be responsible for this logic
   */
  useEffect(() => {
    if (authUser.user.role.name === 'ADMIN_STORE') {
      getCompany(authUser.user.company.id);
    }
  }, []);

  useEffect(() => {
    if (companyGlobal) {
      getCompany(companyGlobal.id);
    }
  }, [companyGlobal]);

  return (
    <>
      <PageHeader>
        {authUser.user.role?.name === 'SUPER_MASTER' ? (
          <CompanyChangeContainer isAll={false} />
        ) : null}
      </PageHeader>

      <Flex
        vertical
        gap={20}
        style={{
          maxWidth: '560px',
          width: '100%',
          margin: '0 auto',
          padding: '20px 20px',
        }}
      >
        <ShopifyIntegrationHeader />

        {loading ? (
          <Spin />
        ) : companyDetail ? (
          <>
            {hasShopifyIntegration && (
              <Alert
                message="Tu tienda ya está conectada a Shopify. Si deseas cambiar la conexión, por favor, ingresa los nuevos datos"
                type="success"
                showIcon
              />
            )}
            <ShopifyConnectContainer company={companyDetail} />
          </>
        ) : null}
      </Flex>
    </>
  );
};
