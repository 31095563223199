import React, { FC } from 'react';
import {
  Avatar,
  Button,
  Flex,
  Modal,
  Progress,
  ProgressProps,
  Space,
  theme,
  Typography,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useModal } from 'react-modal-hook';
import { IAuthUserResponse } from '../../../../services/auth/auth.interface';

const twoColors: ProgressProps['strokeColor'] = {
  '0%': '#2A26FF',
  '100%': '#2A26FF',
};

type TUserProfileProps = {
  user: IAuthUserResponse;
};

export const UserProfile: FC<TUserProfileProps> = ({ user }) => {
  const { token } = theme.useToken();

  const [openModal, closeModal] = useModal(() => (
    <Modal
      open
      onCancel={closeModal}
      footer={<Button onClick={closeModal}>Cerrar</Button>}
      width={400}
    >
      <img
        src="./../../../../assets/niveles-aliclik-min.png"
        alt="Niveles Aliclik"
        style={{ width: '100%' }}
      />
    </Modal>
  ));

  const totalOrders = user.company.totalConfirmedOrders;
  const maxOrders = user.company.level?.range || 0;

  const getPercent = (): number => {
    return (totalOrders / (maxOrders || 1)) * 100;
  };

  const renderContent = () => {
    /**
     * Profile without level or range not valid
     * @returns JSX.Element
     */
    const withoutLevel = !user.company.level || totalOrders >= maxOrders;

    if (withoutLevel) {
      return (
        <Flex align="center" justify="space-between" style={{ fontSize: 12 }}>
          <div>
            <b>{user.company.name}</b>{' '}
            <Typography.Text type="secondary">
              (ID:{user.company.id})
            </Typography.Text>
          </div>

          <span style={{ textAlign: 'right' }}>
            <b>{totalOrders}</b> pedidos
          </span>
        </Flex>
      );
    }

    return (
      <>
        <Flex align="center" justify="space-between" style={{ fontSize: 12 }}>
          <div>
            <b>{user.company.name}</b>{' '}
            <Typography.Text type="secondary">
              (ID:{user.company.id})
            </Typography.Text>
          </div>
          <QuestionCircleOutlined
            style={{ fontSize: 14 }}
            onClick={() => openModal()}
          />
        </Flex>

        <Flex justify="space-between" style={{ fontSize: 11.5 }}>
          <span>Nivel {user.company.level?.name}</span>
          <span style={{ textAlign: 'right' }}>
            <b>
              {totalOrders}/{maxOrders}
            </b>{' '}
            pedidos
          </span>
        </Flex>

        <Progress
          percent={getPercent()}
          strokeColor={twoColors}
          showInfo={false}
          size="small"
        />
      </>
    );
  };

  return (
    <div>
      <Space
        style={{
          fontSize: '12px',
          marginLeft: '25px',
          marginBottom: '6px',
        }}
      >
        <Avatar
          style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}
          src={user.company.level?.urlImage}
          size={40}
        >
          {user.fullname.charAt(0)}
        </Avatar>

        <div>
          <div>{user.fullname}</div>
          <div style={{ color: token.colorTextTertiary }}>
            {user.role?.description}
          </div>
        </div>
      </Space>

      <div
        style={{
          background: token.colorBgLayout,
          padding: '.5rem .75rem',
          margin: '.5rem 1rem',
          borderRadius: token.borderRadius / 2,
        }}
      >
        {renderContent()}
      </div>
    </div>
  );
};
