
import React from 'react'
import { Header } from '../NewLanding/components'
import TermsContent from './components/TermsContent'
export const TermConditionView = () => {
  return (
    <div>
      <Header/>
      <TermsContent/>
    </div>
  )
}
