import React, { FC, useEffect } from 'react'
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
} from 'antd'
import {
  ISkuUpdateSchema,
} from '../../../../../services/product-create/product-create.schema'
import { useProductStore } from '../../../../../services/product/product.store'
import { useAuthStore } from '../../../../../services/auth/auth.service'

interface ISkuEditFormProps {
  onSubmit: (data: ISkuUpdateSchema) => void
}

const { Option } = Select

const SkuEditForm: FC<ISkuEditFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<ISkuUpdateSchema>()
  const { skuSelected } = useProductStore()
  const heightProduct = Form.useWatch('heightProd', form)
  const widthProduct = Form.useWatch('widthProd', form)
  const lengthProduct = Form.useWatch('lengthProd', form)
  const { authUser } = useAuthStore()

  useEffect(() => {
    if (skuSelected) {
      form.setFieldsValue({
        id: skuSelected.id,
        heightProd: skuSelected.height,
        widthProd: skuSelected.width,
        lengthProd: skuSelected.length,
        volumeProd: skuSelected.volume,
        salePriceDrop: skuSelected.salePriceDrop,
        stock: 0,
        stockStore: 0,
        companyId: skuSelected.companyId,
        warehouseId: skuSelected.warehouseSkus && skuSelected.warehouseSkus.length > 0 ? 
          skuSelected.warehouseSkus[0].warehouse.id : -1
      })
    }
  }, [skuSelected])

  useEffect(() => {
    if (heightProduct && widthProduct && lengthProduct) {
      form.setFieldsValue({
        volumeProd: heightProduct * widthProduct * lengthProduct
      })
    } else {
      form.setFieldsValue({
        volumeProd: 0
      })
    }
  }, [heightProduct, widthProduct, lengthProduct])

  return (
    <>
      <Form
        layout='horizontal'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 17 }}
        form={form}
        name='UserForm'
        labelWrap
        onFinish={onSubmit}
      >
        <Form.Item name={['id']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['companyId']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['warehouseId']} hidden>
          <Input />
        </Form.Item>

        <Form.Item label={<span style={{ fontSize: '13px' }}>
          Alto en cm
        </span>} name={['heightProd']} rules={[{ required: true, message: 'Obligatorio' }]}>
          <InputNumber />
        </Form.Item>

        <Form.Item label={<span style={{ fontSize: '13px' }}>
          Ancho en cm
        </span>} name={['widthProd']}
        rules={[{ required: true, message: 'Obligatorio' }]}>
          <InputNumber />
        </Form.Item>

        <Form.Item label={<span style={{ fontSize: '13px' }}>
          Largo en cm
        </span>} name={['lengthProd']}
        rules={[{ required: true, message: 'Obligatorio' }]}>
          <InputNumber />
        </Form.Item>

        <Form.Item label={<span style={{ fontSize: '13px' }}>
          Volumen
        </span>} name={['volumeProd']}
        rules={[{ required: true, message: 'Obligatorio' }]}>
          <InputNumber disabled={true}/>
        </Form.Item>

        <Form.Item label={<span style={{ fontSize: '13px' }}>
          P. Drop
        </span>} name={['salePriceDrop']}
        rules={[{ required: true, message: 'Obligatorio' }]}>
          <InputNumber />
        </Form.Item>

        { (authUser.user.role.name === 'STORE' || authUser.user.role.name === 'ADMIN' 
          || authUser.user.role.name === 'MASTER' || authUser.user.role.name === 'SUPER_MASTER') && 
          skuSelected?.warehouseSkus && skuSelected.warehouseSkus.length > 0 ? <>
        
            <h4> Regularizar Stock </h4>

            <Form.Item
              label='Almacén'
              name={['warehouseId']}
              rules={[{ required: false, message: 'Obligatorio' }]}
            >
              <Select placeholder='' >
                {skuSelected.warehouseSkus?.map((r: any) => (
                  <Option key={r.warehouse.id} value={r.warehouse.id}>
                    {r.warehouse.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label={<span style={{ fontSize: '13px' }}>
              Stock Disponible
            </span>} name={['stock']}
            rules={[{ required: false }]}>
              <InputNumber />
            </Form.Item>

            <Form.Item label={<span style={{ fontSize: '13px' }}>
              Stock Bodega
            </span>} name={['stockStore']}
            rules={[{ required: false }]}>
              <InputNumber />
            </Form.Item>

          </> : <></> }

        <Button type='primary' htmlType='submit'>
          Guardar
        </Button>

      </Form>
    </>
  )
}

export default SkuEditForm
