/* eslint-disable max-len */
import { useAxios } from '../../hooks';
import { useAuthStore } from '../auth/auth.service';
import { IOrderFilter, IOrderResumen } from './../order-list/order.interface';
import {
  IOrderBaseResponse,
  IOrderCallBaseResponse,
  IOrdersResponse,
  IOrderTracking,
} from './order-call.interface';
import { useEffect, useState } from 'react';
import { ORDER_STATUS_LIST } from '../order-create/order.params';
import { CALL_STATUS_LIST } from '../pre-order/pre-order.params';
import dayjs from 'dayjs';

export const useOrdersCall = () => {
  const { authUser } = useAuthStore();
  const { execute, response, loading } = useAxios<IOrderCallBaseResponse>();
  const [resumen, setResumen] = useState<IOrderResumen>();

  const getOrdersCallAll = (filter?: IOrderFilter) => {
    const url =
      authUser.user.role.name === 'STORE'
        ? '/order/call/store/all'
        : '/order/call/all';

    execute({
      method: 'GET',
      url: url,
      params: {
        callStatus: filter?.callStatusList?.join(','),
        status: filter?.statusList?.join(','),
        dispatchStatus:
          filter?.dispatchStatus === 'ALL' ? '' : filter?.dispatchStatus,
        companyId:
          filter?.companyId === 0
            ? ''
            : filter?.companyId || authUser.user.company.id,
        endDate: filter?.endDate,
        startDate: filter?.startDate,
        filterDate: filter?.filterDate,
        countryCode: authUser.user.company.countryCode,
        page: filter?.page ? filter.page : '',
        search: filter?.search ? filter.search : '',
        warehouseId:
          filter?.warehouseId === 'ALL'
            ? ''
            : filter?.warehouseId || authUser.user.warehouse?.id,
        departmentName:
          filter?.departmentName === 'ALL' ? '' : filter?.departmentName,
        userId: filter?.userId === 'ALL' ? '' : filter?.userId,
        parentId: authUser.user.company.parentId,
        conveyorId: filter?.conveyorId === 'ALL' ? '' : filter?.conveyorId,
        filterRecycle:
          filter?.filterRecycle === 'ALL' ? '' : filter?.filterRecycle,
      },
    });
  };

  useEffect(() => {
    if (response && response.result.length > 0) {
      const resumen: IOrderResumen = {
        totalOrder: response.count,
        totalAmount: 0,
        amount: 0, //response.totalAmount._sum.total || 0,
        totalCash: 0,
        totalPayment: response.totalPay,
        totalOrderDelivered: response.totalOrdersDelivered,
        currency: response.result[0]?.currency,
      };

      setResumen(resumen);
    } else {
      setResumen(undefined);
    }
  }, [response]);

  return {
    getOrdersCallAll,
    response,
    loadingOrder: loading,
    resumen,
    count: response ? response.count : 0,
    page: response ? response.page : 1,
  };
};

export const useOrderCallList = () => {
  const { authUser } = useAuthStore();
  const { execute, response, loading } = useAxios<IOrderBaseResponse>();
  const [ordersCalls, setOrdersCalls] = useState<IOrdersResponse[]>();
  const [ordersOriginal, setOrdersOriginal] = useState<IOrdersResponse[]>();
  const [resumen, setResumen] = useState<IOrderResumen>();

  const getOrdersCalls = (filter?: IOrderFilter) => {
    execute({
      method: 'GET',
      url: '/order/call',
      params: {
        callStatus: filter?.callStatus
          ? filter?.callStatus === 'ALL'
            ? ''
            : filter?.callStatus
          : CALL_STATUS_LIST.CONFIRMED,
        companyId:
          filter?.companyId === 0
            ? ''
            : filter?.companyId || authUser.user.company.id,
        createdAtFrom: dayjs().endOf('day').toJSON(),
        createdAtTo: dayjs().subtract(30, 'days').startOf('day').toJSON(),
        isOrderAgency: filter?.isOrderAgency || 0,
        countryCode: authUser.user.company.countryCode,
        page: filter?.page ? filter.page : '',
        search: filter?.search ? filter.search : '',
        warehouseId: authUser.user.warehouse ? authUser.user.warehouse.id : '',
      },
    });
  };

  const orderFilter = (filter: { [key: string]: string } | null) => {
    const orderTemp = filter
      ? mapToOrderFilter(
          response ? (response.result as IOrdersResponse[]) : [],
          filter,
        )
      : response;
    const orderProcessed = mapToOrderTable(
      orderTemp as IOrdersResponse[],
      response ? response?.count : 0,
      response
        ? response?.totalAmount._sum.total
          ? response.totalAmount._sum.total
          : 0
        : 0,
    );

    setOrdersCalls(orderProcessed.orders);
    setResumen(orderProcessed.resumen);
  };

  useEffect(() => {
    if (response) {
      const orderProcessed = mapToOrderTable(
        response.result,
        response ? response.count : 0,
        response
          ? response?.totalAmount._sum.total
            ? response.totalAmount._sum.total
            : 0
          : 0,
      );

      setOrdersOriginal(orderProcessed.orders);
      setOrdersCalls(orderProcessed.orders);
      setResumen(orderProcessed.resumen);
    }
  }, [response]);

  return {
    getOrdersCalls,
    loading,
    ordersCalls,
    resumen,
    orderFilter,
    provinceList: ordersOriginal?.length ? getProvinceList(ordersOriginal) : [],
    count: response ? response.count : 0,
    page: response ? response.page : 1,
  };
};

const mapToOrderTable = (
  orders: IOrdersResponse[],
  count: number,
  amount: number,
): {
  orders: IOrdersResponse[];
  resumen: IOrderResumen;
} => {
  let totalAmount = 0;
  let totalOrder = 0;
  let totalPayment = 0;
  let totalCash = 0;

  const ordersMapped = orders
    .map((orderLocation) => {
      const totalOrder = orderLocation.orders.length;
      const totalCompleted = orderLocation.orders.filter(
        (e) => e.status !== ORDER_STATUS_LIST.PENDING_DELIVERY,
      ).length;
      const totalDelivered = orderLocation.orders.filter(
        (e) => e.status === ORDER_STATUS_LIST.DELIVERED,
      ).length;
      const totalRefused = orderLocation.orders.filter(
        (e) => e.status === ORDER_STATUS_LIST.REFUSED,
      ).length;
      const totalRescheduled = orderLocation.orders.filter(
        (e) => e.status === ORDER_STATUS_LIST.RESCHEDULED,
      ).length;
      const ratioCompleted = Math.round((totalCompleted / totalOrder) * 100);
      const ratioDelivered = Math.round((totalDelivered / totalOrder) * 100);
      const ratioRefused = Math.round((totalRefused / totalOrder) * 100);
      const ratioRescheduled = Math.round(
        (totalRescheduled / totalOrder) * 100,
      );
      const totalPayment =
        orderLocation.orders.map((e) => e.totalPayment).length > 0
          ? orderLocation.orders
              .map((e) => e.totalPayment)
              .reduce((a, b) => a + b)
          : 0;
      const totalCash =
        orderLocation.orders.map((e) => e.totalCash).length > 0
          ? orderLocation.orders.map((e) => e.totalCash).reduce((a, b) => a + b)
          : 0;

      // Mapea campos de segundo nivel
      return {
        ...orderLocation,
        totalAmount: orderLocation.orders?.length
          ? orderLocation.orders.map((e) => e.total).reduce((a, b) => a + b)
          : 0,
        currency: orderLocation.orders[0]?.currency,
        totalOrder,
        totalCompleted,
        totalDelivered,
        totalRefused,
        totalRescheduled,
        totalCash,
        totalPayment,
        ratioCompleted,
        ratioDelivered,
        ratioRefused,
        ratioRescheduled,
      };
    })
    .map((orderLocation) => {
      // Mapea campos de primer nivel
      totalAmount = totalAmount + orderLocation.totalAmount;
      totalOrder = totalOrder + orderLocation.totalOrder;
      totalPayment = totalPayment + orderLocation.totalPayment;
      totalCash = totalCash + orderLocation.totalCash;

      return {
        ...orderLocation,
      };
    })
    .map((orderLocation) => {
      // Mapea campos de primer nivel
      return {
        ...orderLocation,
        ratioAmount: Math.round((orderLocation.totalAmount / amount) * 100),
      };
    })
    .filter((e) => e.totalOrder);

  const resumen: IOrderResumen = {
    totalOrder: count,
    totalAmount,
    amount,
    totalPayment,
    totalCash,
    currency: ordersMapped[0]?.currency,
  };

  return {
    orders: ordersMapped,
    resumen,
  };
};

const mapToOrderFilter = (
  orders: IOrdersResponse[],
  filter: { [key: string]: string },
): IOrdersResponse[] => {
  return orders.map((orderLocation) => {
    if (filter.reset) {
      return orderLocation;
    }

    if (filter.all) {
      return {
        ...orderLocation,
        orders: orderLocation.orders.filter(
          (e) =>
            e.orderNumber
              .toLowerCase()
              .includes(filter.all?.toLowerCase() || '') ||
            `${e.customerHistory.phone}`
              .toLowerCase()
              .includes(filter.all?.toLowerCase() || '') ||
            e.shipping?.address1
              ?.toLowerCase()
              .includes(filter.all?.toLowerCase() || '') ||
            e.shipping?.provinceName
              ?.toLowerCase()
              .includes(filter.all?.toLowerCase() || '') ||
            e.warehouseName
              ?.toLowerCase()
              .includes(filter.all?.toLowerCase() || ''),
        ),
      };
    }

    return {
      ...orderLocation,
      orders: orderLocation.orders.filter(
        (e) =>
          e.orderNumber
            .toLowerCase()
            .includes(filter.orderNumber?.toLowerCase() || '') &&
          `${e.customerHistory.phone}`
            .toLowerCase()
            .includes(filter.phone?.toLowerCase() || '') &&
          e.shipping?.address1
            ?.toLowerCase()
            .includes(filter.address?.toLowerCase() || '') &&
          e.shipping?.provinceName
            ?.toLowerCase()
            .includes(filter.province?.toLowerCase() || '') &&
          e.warehouseName
            ?.toLowerCase()
            .includes(filter.warehouseName?.toLowerCase() || ''),
      ),
    };
  });
};

const getProvinceList = (orders: IOrdersResponse[]) => {
  const provinceList: string[] = [];

  orders.map((orderLocation) => {
    orderLocation.orders.map((e) => {
      provinceList.push(e.shipping.provinceName);
    });
  });

  const unique = provinceList
    .filter((e) => e !== 'null')
    .filter((elem, index, self) => index === self.indexOf(elem))
    .sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }

      return 0;
    });

  return unique;
};

export const useOrderDispatchTracking = () => {

  const { execute, response, loading } = useAxios<IOrderTracking[]>();

  const getOrdersTracking = (orderId: number) => {
    execute({
      method: 'GET',
      url: `order/dispatch-tracking/${orderId}`,
    });
  };

  return {
    getOrdersTracking,
    loading,
    response: response || [],
  };
};
