import React from 'react';
import { useDrawer } from '../../../../contexts/DrawerContext';
import { EmailVerificationForm } from '../../forms';
import { useSignUpCreate } from '../../../../services/sign-up/sign-up.service';
import { useSignupStore } from '../../store/signup.store';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

export const EmailVerificationContainer = () => {
  const { closeDrawer } = useDrawer();
  const navigate = useNavigate();
  const { signUpCreate, loading } = useSignUpCreate();
  const { createNewAccountRequest } = useSignupStore();

  const handleVerificationCode = async (data: { codeAuth: string }) => {
    if (!createNewAccountRequest) {
      message.info('No se ha encontrado información de la cuenta');
      closeDrawer();

      return;
    }

    const response = await signUpCreate({
      codeAuth: data.codeAuth,
      signUpSchema: createNewAccountRequest,
    });
  
    if (response) {
      localStorage.removeItem('referenceCode');
      closeDrawer();
      navigate('/login');
    } else {
      console.log('Error al crear la cuenta')
    }
    
  };

  return (
    <EmailVerificationForm
      loading={loading}
      onVerifyCode={handleVerificationCode}
    />
  );
};
