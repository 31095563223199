import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, Form, Select, Radio, InputNumber } from 'antd'
import { IFieldData } from '../../../../../config/data.interface'
import { useProductStore } from '../../../../../services/product/product.store'
import {
  ORDER_STATUS_LIST_CONFIRMED,
  ORDER_STATUS_LIST_NOT_AVAILABLE,
  ORDER_STATUS_LIST_WORKING,
  CALL_STATUS_LIST,
} from '../../../../../services/pre-order/pre-order.params'
import TextArea from 'antd/lib/input/TextArea'
import { usePreOrderEdit } from '../../../../../services/pre-order/pre-order.edit'
import { IOrderShopify } from '../../../../../services/order-shopify/order-shopify.interface'
import { IOrderShopifySchema } from '../../../../../services/order-shopify/order-shopify.schema'
import {
  OrderShopifyAgencyForm,
  OrderShopifyCustomerForm,
  OrderShopifyProductForm,
  OrderShopifyShippingForm
} from './parts'
import OrderCourierForm from './parts/OrderCourierForm'

const { Option, OptGroup } = Select

interface IOrderShopifyFormProps {
  initialValues: IOrderShopify | null;
  onSubmit: (data: IOrderShopifySchema) => void;
}

const OrderShopifyForm: FC<IOrderShopifyFormProps> = ({ initialValues, onSubmit }) => {
  const [form] = Form.useForm<IOrderShopifySchema>()
  const [defaultValues, setDefaultValues] = useState<IFieldData[]>()
  const { setProductEmpty } = useProductStore()
  const { userCompany } = usePreOrderEdit()
  const [disabled, setDisabled] = useState<boolean>(false)
  const [required, setRequired] = useState<number>(0)
  const status = Form.useWatch('orderStatus', form)
  const provinceId = Form.useWatch(['shipping', 'provinceId'], form)
  const departmentId = Form.useWatch(['shipping', 'departmentId'], form)
  const districtId = Form.useWatch(['shipping', 'districtId'], form)
  const enableDpto = Form.useWatch(['enableDpto'], form)
 
  useEffect(() => {
    if (initialValues) {

      let phone = ''

      if (initialValues.shipping.phone.length > 0) {
        phone = initialValues.shipping.phone.replace('+', '')
      }

      form.setFieldsValue({
        stage: initialValues.stage,
        orderStatus: initialValues.status || 'CONFIRMED',
        note: initialValues.note,
        createdAtShopify: initialValues.createdAt,
        customer: {
          name: `${initialValues.customer.firstName || ''} ${initialValues.customer.lastName || ''}`,
          phone: phone,
        },
        shipping: {
          address1: initialValues.shipping.address,
          address2: initialValues.shipping.reference,
          shippingByAgency: false
        },
        shippingCost: 0,
      })
    }
  }, [initialValues])

  useEffect(() => {
    if (userCompany === 2) {
      setDisabled(false)
    } else if (userCompany === 1) {
      setDisabled(true)
    } else if (userCompany === 3) {
      setDisabled(false)
    }
    setProductEmpty()
    setDefaultValues([
      { name: ['shipping', 'shippingByAgency'], value: false },
    ])
  }, [])

  useEffect(() => {
    if (status === CALL_STATUS_LIST.FOLLOW || status === CALL_STATUS_LIST.CALL_LATER) {
      setRequired(1)
      setDisabled(false)
    } else if (status === CALL_STATUS_LIST.CONFIRMED) {
      if (userCompany === 2) {
        setRequired(0)
        setDisabled(false)
      } else if (userCompany === 1) {
        setDisabled(true)
      } else if (userCompany === 3) {
        setDisabled(false)
      }
    } else {
      setRequired(2)
      setDisabled(false)
    }
  }, [status])

  return <>
    <Form
      layout="horizontal"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 17 }}
      form={form}
      name="order"
      labelWrap
      fields={defaultValues}
      onFinish={onSubmit}
    >

      <Form.Item name="orderStatus" label="Estado" rules={[{ required: true, message: '' }]}>
        <Select
          placeholder="Seleccione una opción"
          allowClear
        >
          {ORDER_STATUS_LIST_CONFIRMED.map(status => (
            <Option key={status.value} value={status.value}>{status.label}</Option>
          ))}
          <OptGroup label="WORKING">
            {ORDER_STATUS_LIST_WORKING.map(status => (
              <Option key={status.value} value={status.value}>{status.label}</Option>
            ))}
          </OptGroup>
          <OptGroup label="ORDEN">
            {ORDER_STATUS_LIST_NOT_AVAILABLE.map(status => (
              <Option key={status.value} value={status.value}>{status.label}</Option>
            ))}
          </OptGroup>
        </Select>
      </Form.Item>

      <Form.Item label="Nota" name="note">
        <TextArea rows={2} disabled={disabled}/>
      </Form.Item>

      <Form.Item name={['shipping', 'shippingByAgency']} 
        label="Tipo de pedido"
        hidden={true}
      >
        <Radio.Group
          options={[
            { label: 'Contraentrega', value: false },
            { label: 'Agencia', value: true },
          ]}
          optionType="button"
          buttonStyle="solid"
          disabled={disabled === false ? enableDpto : disabled}
        />
      </Form.Item>

      <Form.Item name='shippingCost' hidden>
        <InputNumber />
      </Form.Item>

      <Form.Item name='returnCost' hidden>
        <InputNumber />
      </Form.Item>

      <Form.Item name='createdAtShopify' hidden>
        <InputNumber />
      </Form.Item>

      <Divider />

      <OrderShopifyCustomerForm
        disabled={disabled}
      />

      <Divider />

      <OrderShopifyShippingForm 
        form={form}
        disabled={disabled}
        required={required} />

      { departmentId && provinceId && districtId ? <>

        <Divider />
        <OrderShopifyProductForm
          form={form}
          disabled={disabled}
        />

      </> : null}

      <OrderCourierForm 
        form={form}
        disabled={disabled}
        required={required}
      />

      <OrderShopifyAgencyForm form={form} disabled={disabled} />

      <Form.Item hidden={disabled}>
        <Button type="primary" htmlType="submit" size="large">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  </>
}

export default OrderShopifyForm
