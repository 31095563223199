import { TGenericResponse } from '../../../commons/types';
import { useAxios } from '../../../hooks';
import { ICompanyUpdateRequest } from '../types';

export const useUpdateCompany = () => {
  const { execute, response, loading } = useAxios<TGenericResponse>();

  const updateCompany = async (data: ICompanyUpdateRequest) => {
    return execute({
      method: 'PATCH',
      url: `/maintenance/company/${data.id}`,
      data: data,
    });
  };

  return {
    updateCompany,
    loading,
    company: response,
  };
};
