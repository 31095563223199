import React, { FC } from 'react';
import { Alert, Form, Input, Spin } from 'antd';
import { IShopifyConnectSchema, ShopifyIntegrationForm } from '../forms/shopify-integration-form';
import { useValidateStoreAccess } from '../hooks/use-validate-store-access';
import { ICompanyItemResponse } from '../../../services/company-list/company-list.interface';
import { useUpdateCompany } from '../../company/hooks';

type ShopifyConnectContainerProps = {
  company: ICompanyItemResponse;
};
export const ShopifyConnectContainer: FC<ShopifyConnectContainerProps> = ({ company }) => {
  const {
    validateStoreAccess,
    shop,
    loading: isLoadingValidateStoreAccess,
  } = useValidateStoreAccess();
  const { updateCompany, loading: isLoadingUpdateCompany } = useUpdateCompany();

  const handleTestConection = (values: IShopifyConnectSchema) => {
    validateStoreAccess({
      storeName: values.storeShopify,
      accessToken: values.accessTokenShopify,
    });
  };

  const handleSubmit = (values: IShopifyConnectSchema) => {
    updateCompany({
      id: company.id,
      typeImport: values.typeImport,
      storeShopify: values.storeShopify,
      accessTokenShopify: values.accessTokenShopify,
    });
  };

  const isLoading = isLoadingValidateStoreAccess || isLoadingUpdateCompany;

  return (
    <>
      {shop && (
        <Alert
          message={`Tu tienda ${shop.name} (${shop.domain}) tiene la configuración correcta. Puedes guardar los datos`}
          type="info"
          showIcon
        />
      )}

      <Spin spinning={isLoading}>
        <Form.Item
          label="Shopify Webhook URL"
          layout={'vertical'}
          help="Usa esta URL para recibir notificaciones de Shopify"
        >
          <Input readOnly value={company.shopifyWebhookUrl} />
        </Form.Item>
        <br />

        <ShopifyIntegrationForm
          defaultValues={company}
          onTestConection={handleTestConection}
          onSubmit={handleSubmit}
        />
      </Spin>
    </>
  );
};
