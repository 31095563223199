import React, { FC, useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

type PerflayIntegrationFormProps = {
  defaultValues: any;
};

export const PerflayIntegrationForm: FC<PerflayIntegrationFormProps> = ({ defaultValues }) => {
  const [form] = Form.useForm();

  const handleCopy = (token: string) => {
    navigator.clipboard.writeText(token);
    message.success('Token copiado al portapapeles');
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [defaultValues]);

  return (
    <Form layout="vertical" form={form}>
      <Form.Item name="tokenIntegration" label="Token de integración">
        <Input
          readOnly
          addonAfter={
            <Button
              type="text"
              icon={<CopyOutlined />}
              size="small"
              onClick={() => handleCopy(form.getFieldValue('tokenIntegration'))}
            />
          }
        />
      </Form.Item>
    </Form>
  );
};
