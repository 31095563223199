/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { Button, Popconfirm, Table, TableColumnsType } from 'antd'
import { useOrderStore } from '../../../../../services/order-list/order.store'
import { IOrderDeliveryResponse } from '../../../../../services/order-list/order.interface'
import { formatDateUTCByCountry, validateUrlDelivery } from '../../../../../utils/date'
import { ImagePreview } from '../../../../DispatchModule/DispatchGuideMainView/components'
import { DeleteOutlined } from '@ant-design/icons'
import { 
  useOrderDeliveryDelete,
  useOrdersDelivery
} from '../../../../../services/order-delivery/order-delivery.service'
import { useAuthStore } from '../../../../../services/auth/auth.service'

const OrderDeliveryContainer = () => {
  const { orderSelected } = useOrderStore()
  const { authUser } = useAuthStore()
  const { obtainOrderList, response, loading } = useOrdersDelivery()
  const { orderDeliveryDeleted, orderDeliveryDelete, loadingDelete } = useOrderDeliveryDelete()
  const LINK = 'https://www.appsheet.com/template/gettablefileurl?appName=Alidriver-284814394&tableName=OrderDelivery&fileName='

  useEffect(() => {
    if (orderSelected) {
      obtainOrderList(orderSelected.id)
    }
  }, [orderSelected, orderDeliveryDeleted])

  const showConfirm = (od: any) => {
    orderDeliveryDelete(od.id)
  }

  const columns: TableColumnsType<IOrderDeliveryResponse> = [
    {
      title: 'Fecha',
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      render: (_, d) => <>{formatDateUTCByCountry(
        authUser.user.company.countryCode || 'PER',
        d.deliveryDate
      )}</>
    },
    {
      title: 'Estado entrega',
      dataIndex: 'deliveryStatus',
      key: 'deliveryStatus', align: 'center',
    },
    {
      title: 'Último estado antes Reprogramar',
      dataIndex: 'lastRescheduledStatus', align: 'center',
      key: 'lastRescheduledStatus',
    },
    {
      title: 'Motorizado',
      dataIndex: 'user',
      key: 'user',
      render: (_, delivery) => <>
        {(delivery.deliveryStatus === 'PENDING_DELIVERY' && delivery.isAssigned === false) ? 
          'No asignado' : delivery.user?.email}</>
    },
    {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (_, d) => <>{d.comment}</>
    },
    {
      title: 'Evidencia Chat',
      dataIndex: 'evidenceChat',
      key: 'evidenceChat',
      align: 'center',
      render: (_, delivery) => <>
        {delivery.evidenceChat && delivery.evidenceChat.length > 0 ?
          <ImagePreview 
            url={validateUrlDelivery(delivery.evidenceChat) ? `${LINK}${delivery.evidenceChat}` : `${delivery.evidenceChat}`}
          ></ImagePreview>
          : null}
      </>
    },
    {
      title: 'Evidencia llamada',
      dataIndex: 'evidenceCall',
      key: 'evidenceCall',
      align: 'center',
      render: (_, delivery) => <>
        {delivery.evidenceCall && delivery.evidenceCall.length > 0 ?
          <ImagePreview 
            url={validateUrlDelivery(delivery.evidenceCall) ? 
              `${LINK}${delivery.evidenceCall}` : `${delivery.evidenceCall}`}
          ></ImagePreview>
          : null}
      </>
    },
    {
      title: 'Evidencia llamada por chat',
      dataIndex: 'evidenceCallChat',
      key: 'evidenceCallChat',
      align: 'center',
      render: (_, delivery) => <>
        {delivery.evidenceCallChat && delivery.evidenceCallChat.length > 0 ?
          <ImagePreview 
            url={validateUrlDelivery(delivery.evidenceCallChat) ? 
              `${LINK}${delivery.evidenceCallChat}` : `${delivery.evidenceCallChat}`}
          ></ImagePreview>
          : null}
      </>
    },
    {
      title: 'Evidencia entrega',
      dataIndex: 'evidenceDelivery',
      key: 'evidenceDelivery',
      align: 'center',
      render: (_, delivery) => <>
        {delivery.evidenceDelivery && delivery.evidenceDelivery.length > 0 ?
          <ImagePreview 
            url={validateUrlDelivery(delivery.evidenceDelivery) ? 
              `${LINK}${delivery.evidenceDelivery}` : `${delivery.evidenceDelivery}`}
          ></ImagePreview>
          : null}
      </>
    },
    {
      title: 'Evidencia soporte',
      dataIndex: 'evidenceSupport',
      key: 'evidenceSupport',
      align: 'center',
      render: (_, delivery) => <>
        {delivery.evidenceSupport && delivery.evidenceSupport.length > 0 ?
          <ImagePreview 
            url={validateUrlDelivery(delivery.evidenceSupport) ? 
              `${LINK}${delivery.evidenceSupport}` : `${delivery.evidenceSupport}`}
          ></ImagePreview>
          : null}
      </>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, delivery) => <>
        { authUser.user.role.name === 'SUPER_MASTER' && delivery.deliveryStatus !== 'DELIVERED' ? <>
          <Popconfirm
            title={'Eliminar registro de historial del pedido'}
            description={'¿Desea eliminar el registro del historial del pedido?'}
            okText="SI"
            onConfirm={() => showConfirm(delivery)}
            cancelText="NO"
          >
            <Button danger><DeleteOutlined /></Button>
          </Popconfirm>
        </> : null }
        
      </>
    }
  ]

  return <div style={{ overflow: 'auto' }}>
    {orderSelected ? (
      <>
        <Table
          loading={loading || loadingDelete}
          rowKey="id"
          size='small'
          dataSource={response}
          columns={columns}
        />
      </>
    ) : null}
  </div>
}

export default OrderDeliveryContainer
