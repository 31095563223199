import React, { useEffect } from 'react';
import { PageHeader } from '../../components';
import { CompanyChangeContainer } from '../../containers';
import { useAuthStore } from '../../services/auth/auth.service';
import PerflayIntegrationHeader from './components/perflay-integration-header'
import { Flex, Spin } from 'antd';
import {
  useCompanyDetail,
  useCompanyStore,
} from '../../services/company-create/company-create.service';
import { PerflayConnectContainer } from './containers/perflay-connect-container';

export const PerflayConnectView = () => {

  const { authUser } = useAuthStore();
    const { getCompany, loading, companyDetail } = useCompanyDetail();
    const { companyGlobal } = useCompanyStore();
  
    useEffect(() => {
      if (authUser.user.role.name === 'ADMIN_STORE') {
        getCompany(authUser.user.company.id);
      }
    }, []);
  
    useEffect(() => {
      if (companyGlobal) {
        getCompany(companyGlobal.id);
      }
    }, [companyGlobal]);
    
  return (
    <>
      <PageHeader>
        {authUser.user.role?.name === 'SUPER_MASTER' ? (
          <CompanyChangeContainer isAll={false} />
        ) : null}
      </PageHeader>

      <Flex
        vertical
        gap={20}
        style={{
          maxWidth: '560px',
          width: '100%',
          margin: '0 auto',
          padding: '20px 20px',
        }}
      >
        <PerflayIntegrationHeader />

        {loading ? (
          <Spin />
        ) : companyDetail ? (
          <>
            <PerflayConnectContainer company={companyDetail}/>
          </>
        ) : null}
      </Flex>
    </>
  )
}
