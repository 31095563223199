// PerflayIntegration.jsx
import React from 'react';
import './PerflayIntegration.css';
import perflay from './perflay.png'

export default function PerflayIntegration() {
  return (
    <div className='perflay-container'>
      <div className='perflay-card'>
        {/* Logo */}
          <img src={perflay} alt='Perflay Logo' className='perflay-logo' />
        
        {/* Title */}
        <h2 className='perflay-title'>
          Integra tu tienda a Perflay para sincronizar tus pedidos
        </h2>
        
        {/* Video Button */}
        <button 
          className='video-button'
          onClick={() => window.open('https://www.loom.com/share/81e2ccbae595454a8a309374d514cceb', '_blank')}
        >
          <div className='button-content'>
            <div className='play-icon'>
              <svg viewBox='0 0 24 24' width='16' height='16'>
                <path fill='currentColor' d='M8 5v14l11-7z' />
              </svg>
            </div>
            <span>¡Mira cómo hacerlo!</span>
          </div>
        </button>
        
        {/* Create Account Link */}
        <a 
          href='https://perflay.com/register/175529'
          className='create-account-link'>
          Crea tu cuenta aquí
        </a>
        
        {/* Subtext */}
        <p className='perflay-subtext'>
          y disfruta de beneficios exclusivos por ser parte de la comunidad Aliclik
        </p>
      </div>
    </div>
  );
}
