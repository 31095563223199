import React, { FC } from 'react';
import { Button, Form, Input } from 'antd';
import { IAuthRequest } from '../../../../services/auth/auth.interface';
import { useDrawer } from '../../../../contexts/DrawerContext';
import { HowToUseButton } from '../../components/HowToUseButton/HowToUseButton';
import style from '../SignInForm/SignInForm.module.css'
import { useNavigate } from 'react-router-dom';

interface ISignInFormProps {
  loading: boolean;
  onSubmit: (auth: IAuthRequest) => void;
}

const SignInForm: FC<ISignInFormProps> = ({ loading, onSubmit }) => {
  const { openDrawer } = useDrawer();

  const navigate = useNavigate()

  const handleResetPassword = () => {
    openDrawer('RESET_PASS', '');
  };

  return (
    <>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label={<span>Usuario</span>}
          name="email"
          rules={[{ required: true, message: 'Ingrese su usuario' }]}
        >
          <Input 
            placeholder="Ingrese su usuario"
            className={style['button__container']}
          />
        </Form.Item>

        <Form.Item
          label={<span>Contraseña</span>}
          name="password"
          rules={[{ required: true, message: 'Ingrese una contraseña' }]}
        >
          <Input.Password 
            placeholder="Ingrese su contraseña" 
            className={style['button__container']}
          />
        </Form.Item>

        <Form.Item>
          <Button 
            onClick={handleResetPassword} 
            type="link"
            className={style['forget-pass']}
          >
            ¿Has olvidado tu contraseña?
          </Button>
        </Form.Item>

        <Form.Item>
          <Button 
            type="primary" 
            htmlType="submit" 
            loading={loading} 
            block
            className={style['button__login']}
          >
            Iniciar sesión
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => navigate('/register')}
            className={style['button__account']}
            block
          >
            ¿No tienes cuenta? Crear aquí
          </Button>
        </Form.Item>
      </Form>

      <HowToUseButton />
    </>
  );
};

export default SignInForm;
