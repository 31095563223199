import React from 'react';

type CloseIconProps = {
  width?: number;
  height?: number;
  fill?: string;
};

export const CloseIcon = ({
  width = 20,
  height = 20,
  fill = '#ffffff',
}: CloseIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke={fill}
      className="size-6"
      height={height}
      width={width}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18 18 6M6 6l12 12"
      />
    </svg>
  );
};
