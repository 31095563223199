import React from 'react'
import { DashboardContainer } from './containers'
//import { InformationModal } from '../../components/Information-Modal/InformationModal';

const DashboardView = () => {
  //const [showModal, setShowModal] = useState(true);
  
  return (
    <>
      <DashboardContainer />
      {/*<InformationModal
        isOpen={showModal} 
        isClose={() => setShowModal(false)} 
      />*/}
    </>
  )
}

export default DashboardView
